import AboutUsContent from '../../organisms/AboutUsContent/AboutUsContent'
import NavBar from 'components/organisms/NavBar/NavBar'

export default function AboutUs (): JSX.Element {
  return (
    <>
    <NavBar className='home_navbar relative flex flex-row justify-between items-center p-5 align-center -mb-5 sm:-mb-2 z-10' />
    <div className='flex flex-col justify-start gap-3 w-full rounded-3xl bg-white md:basis-9/12 lg:basis-7/12 xl:basis-8/12'>
      <AboutUsContent />
    </div>
    </>
  )
}
