interface Props {
  src: string
  className: string
  alt?: string
}

export default function Image ({ src, className, alt }: Props) {
  return (
    <img className={className} src={src} alt={alt} />
  )
}
