/* eslint-disable @typescript-eslint/no-misused-promises */
import React, { useState } from 'react'
import Header from '../../atoms/Heading/Heading'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { setCurrentUser } from '../../../app/quiz/quizSlice'
import DeleteConfirmationModal from '../DeleteConfirmationModal/DeleteConfirmationModal'
import { users } from '../../../app/services/Api'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

export default function AccountSettings () {
  const dispatch = useAppDispatch()
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false)
  const currentUser = useAppSelector(state => state.quizReducer.currentUser)
  const navigate = useNavigate()

  const handleDeleteClick = () => {
    setDeleteModalOpen(true)
  }

  const handleCancelDelete = () => {
    setDeleteModalOpen(false)
  }

  const handleConfirmDelete = async () => {
    await users.deleteUser(currentUser?.id ?? 0, true).then(response => {
      dispatch(setCurrentUser(null))
      navigate('/')
      toast.success('Account was deleted successfully!')
    })
      .catch(err => {
        console.log(err)
      })
    setDeleteModalOpen(false)
  }

  return (
    <div className='flex flex-col justify-start items-start md:justify-start md:items-start w-full gap-3 pb-6'>
      <Header className='font-medium  text-xl md:text-3xl leading-7 text-black block mb-5 mt-7 md:4' text='Account settings' />
      <div className='flex flex-row items-center gap-10 mb-2'>
        <label className='font-light text-sm w-20'>
          Password
        </label>
        <input id='password' placeholder="******" type='password' className='rounded-md text-sm border border-transparent p-2 w-32 h-6' disabled />
      </div>
      <button onClick={handleDeleteClick} type="submit" className="w-32 h-10 mr-96 mt-4 text-red-500 text-xs text-center font-light border border-red-100 rounded-md hover:bg-red-100 ">
        Delete Account
      </button>
      <DeleteConfirmationModal
        isOpen={isDeleteModalOpen}
        onClose={handleCancelDelete}
        onConfirm={handleConfirmDelete}
      />
      <p className='rounded-md border-2  w-4/5 md:w-3/5 p-2 md:p-4' style={{ fontSize: window.innerWidth < 768 ? '0.5rem' : '0.8rem' }}>By deleting your profile, you acknowledge that all of your quiz, plans and personal data will
        be removed from our platform. This data cannot be restored. To delete your payment details, please contact
        <a href="mailto:dataprotection@payproglobal.com" className="text-blue-500 hover:text-blue-700"> dataprotection@payproglobal.com </a>
        or via the website <a href="https://payproglobal.com/data-protection/" className="text-blue-500 hover:text-blue-700">form </a>
        to get more detailed information.</p>
    </div>
  )
}
