import AboutUsPageInfo from '../../molecules/AboutUsPageInfo/AboutUsPageInfo'

interface Props {
  className?: string
}

export default function AboutUsContent ({ className }: Props): JSX.Element {
  return (
      <AboutUsPageInfo/>
  )
}
