import { type IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

interface Props {
  icon: IconProp
  style?: React.CSSProperties | undefined
  className?: string
  handleOnClick?: () => void
}

export default function Icon ({ icon, style, className, handleOnClick }: Props) {
  return (
    <FontAwesomeIcon
      icon={icon}
      style={style}
      className={className}
      onClick={handleOnClick}
    />
  )
}
