/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import React, { useEffect, useState } from 'react'
import QuizEmailPage from '../../organisms/QuizEmailPage/QuizEmailPage'
import QuizGoalsPage from '../../organisms/QuizGoalsPage/QuizGoalsPage'
import QuizFirstStep from '../../organisms/QuizFirstStep/QuizFirstStep'
import QuizThirdStep from '../../organisms/QuizThirdStep/QuizThirdStep'
import QuizSecondStep from '../../organisms/QuizSecondStep/QuizSecondStep'
import BackButton from '../../atoms/BackButton/BackButton'
import QuizGenderPage from '../../organisms/QuizGenderPage/QuizGenderPage'
import QuizProfessionPage from '../../organisms/QuizProfessionPage/QuizProfessionPage'
import QuizVerificationPage from '../../organisms/QuizVerificationPage/QuizVerificationPage'
import BrandaResult from '../../organisms/BrandaResult/BrandaResult'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { setCurrentUser, setIsLoading } from '../../../app/quiz/quizSlice'
import CircularWithValueLabel from '../../organisms/CircularProgress/CircularProgress'
import BrandaResultPage2 from '../../organisms/BrandaResultPage2/BrandaResultPage2'
import QuizBirthdatePage from '../../organisms/QuizBirthdatePage/QuizBirthdatePage'
import BrandaThankYouPage from '../../organisms/BrandaThankYouPage/BrandaThankYouPage'
import QuizPasswordPage from '../../organisms/QuizPasswordPage/QuizPasswordPage'
import QuizExpertise from 'components/organisms/QuizExpertisePage/QuizExpertise'
import VideoPlayer from '../../molecules/Player/VideoPlayer'
import { users } from '../../../app/services/Api'
import { useNavigate } from 'react-router-dom'
const videoUrl1 = 'QuizW.mp4'
const mobileVideoUrl1 = 'AppQuizmobile.mp4'
const videoUrl2 = 'KeywordsWsub.mp4'
const mobileVideoUrl2 = 'KeywordsM.mp4'
const transcriptContent1 = `
  <div style="font-size:12px;">
    <h2 style="font-size: 20px; margin-bottom: 10px;">Your First step!</h2>
<p>
Hi! <br>
Branda here, your personal Branding coach. Thanks for signing up! <br> 
I'm so excited you’re here with us! <br>
Now, let's get to work!
</p>
    <br>
<p>
The first step to a winning brand is discovering your Branda. Your personal brand identity. <br>
For this, I will need your help! <br>
You see, your Brand Persona is based on your unique personality and goals! This is why I will need to get to know you better first... <br> 
Please answer our short research-based Branda quiz. It will take a few minutes... Make sure to answer all the questions. And don't worry! This is a one-time process! <br> 
 I will walk you through all the way! Whenever you need my guidance, simply click on the question mark icon, and I'll be there, explaining what to do. <br>
</p>
<br>
<p>
After you finish the quiz, I will send your Branda straight to your email, so you can learn more about your brand identity, share it with friends and save it for later! <br>
Ready? Let's start branding you up!
</p>
</div>
`
const transcriptContent2 = `
  <div>
    <h2 style="font-size: 24px; margin-bottom: 10px;">Choosing Keywords</h2>
    <p style="font-size:12px;">
        This part is pretty important, so I figured I'd jump in and make sure you do it perfectly.  
      <br/> <br/> 
      Please write five keywords that specify your expertise and the main themes you would like to discuss in your social media posts. 
      For example, <br/> 
      let's say you’re a Life Coach. <br/> 
      Well, there are many life coaches out there!<br/> <br/> 
      So, in this part, I would pick the keywords that describe both my professional expertise and my personal discussion topics in the most accurate way. Let's take me, for example.<br/> <br/> 
      As you already know, MY profession is a Personal Branding coach, and I'm highly experienced with the following topics:<br/>
      Online Persona building, AI ,and Content Writing.  These are my professional keywords. However, I would also add one or more general topics that I'd like to discuss, such as Self-empowerment and motivation.  But, this is me...<br/>
      Now it's time you think about yours. <br/>
      Remember!<br/>
      There are no wrong keywords, but some are better than others, so try to identify what best describes your professional expertise. <br/>
      You can write each keyword separately in the field below and press Enter, or write a couple of them together, separated with commas, and only then press Enter. <br/><br/>
      Whatever you choose, make sure all keywords pop out below before clicking Next.<br/><br/>
      Good Luck!<br/>
      You've got this!
    </p>
  </div>
`
export default function Quiz (): JSX.Element {
  const videosByPage: Record<string, { web: Array<{ videoUrl: string, transcriptUrl: string }>, mobile: Array<{ videoUrl: string, transcriptUrl: string }> }> = {
    pageFour: {
      web: [
        { videoUrl: videoUrl1, transcriptUrl: transcriptContent1 }
      ],
      mobile: [
        { videoUrl: mobileVideoUrl1, transcriptUrl: transcriptContent1 }
      ]
    },
    pageEight: {
      web: [
        { videoUrl: videoUrl2, transcriptUrl: transcriptContent2 }
      ],
      mobile: [
        { videoUrl: mobileVideoUrl2, transcriptUrl: transcriptContent2 }
      ]
    }
  }

  const [currentPage, setCurrentPage] = useState('pageOne')
  const [isModalOpen, setModalOpen] = useState<boolean>(true)
  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth < 768)
  const [showTranscript, setShowTranscript] = useState<boolean>(false)
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0)
  const [backPage, setBackPage] = useState('')
  const isLoading = useAppSelector((state) => state.quizReducer.isLoading)
  const currentUser = useAppSelector(state => state.quizReducer.currentUser)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const getUser = async () => {
    await users.getCurrentUser(true).then((response) => {
      if (!response.response) {
        dispatch(setCurrentUser(response))
      } else {
        dispatch(setCurrentUser(null))
      }
    })
      .catch((err) => {
        console.log(err)
      })
  }

  useEffect(() => {
    const handleResize = (): void => {
      setIsMobile(window.innerWidth < 768)
    }
    window.addEventListener('resize', handleResize)

    void getUser()

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const handleCloseModal = (): void => {
    setModalOpen(false)
  }

  const handleModalOpen = (): void => {
    setModalOpen(true)
  }

  const toggleTranscript = (): void => {
    setShowTranscript(!showTranscript)
  }

  const currentVideoSet = videosByPage[currentPage]
  const currentVideos = currentVideoSet ? currentVideoSet[isMobile ? 'mobile' : 'web'] : []
  const currentVideo = currentVideos[currentVideoIndex]

  const handleNextVideo = (): void => {
    if (Array.isArray(currentVideos) && currentVideoIndex < currentVideos.length - 1) {
      setCurrentVideoIndex(currentVideoIndex + 1) // Move to the next video
    }
  }

  const handlePreviousVideo = (): void => {
    if (Array.isArray(currentVideos) && currentVideoIndex > 0) {
      setCurrentVideoIndex(currentVideoIndex - 1) // Move to the previous video
    }
  }

  useEffect(() => {
    if (currentUser?.quiz_step?.step_name === 'Goals') {
      setCurrentPage('pageFour')
    } else if (currentUser?.quiz_step?.step_name === 'Gender') {
      setCurrentPage('pageFive')
    } else if (currentUser?.quiz_step?.step_name === 'Birthdate') {
      setCurrentPage('pageSix')
    } else if (currentUser?.quiz_step?.step_name === 'Profession') {
      setCurrentPage('pageSeven')
    } else if (currentUser?.quiz_step?.step_name === 'Keywords') {
      setCurrentPage('pageEight')
    } else if (currentUser?.quiz_step?.step_name === 'Step One') {
      setCurrentPage('pageStep1')
    } else if (currentUser?.quiz_step?.step_name === 'Step Two') {
      setCurrentPage('pageStep2')
    } else if (currentUser?.quiz_step?.step_name === 'Step Three') {
      setCurrentPage('pageStep3')
    } else if (currentUser?.quiz_step?.step_name === 'Page Result') {
      setCurrentPage('pageBrandaResult2')
    } else if (currentUser?.quiz_step?.step_name === 'Page Thank You') {
      setCurrentPage('pageBrandaThankYou')
    }
  }, [currentUser])

  useEffect(() => {
    void getUser()

    if (currentPage === 'pageFour') {
      setBackPage('pageThree')
    } else if (currentPage === 'pageFive') {
      setBackPage('pageFour')
    } else if (currentPage === 'pageSix') {
      setBackPage('pageFive')
    } else if (currentPage === 'pageSeven') {
      setBackPage('pageSix')
    } else if (currentPage === 'pageEight') {
      setBackPage('pageSeven')
    } else if (currentPage === 'pageStep1') {
      setBackPage('pageSix')
    } else if (currentPage === 'pageStep2') {
      setBackPage('pageStep1')
    } else if (currentPage === 'pageStep3') {
      setBackPage('pageStep2')
    }

    if (!currentUser && backPage) {
      navigate('/sign-in')
    }

    if (currentPage) {
      setModalOpen(currentPage === 'pageFour')
    }
  }, [currentPage])

  const handleShowPage = (page: string): void => {
    setCurrentPage(page)
    window.scrollTo(0, 0)
  }

  const handleBackPage = (page: string): void => {
    setCurrentPage(page)
    window.scrollTo(0, 0)
  }

  let button
  if (
    currentPage === 'pageOne' ||
    currentPage === 'pageTwo' ||
    currentPage === 'pageThree' ||
    currentPage === 'pageFour' ||
    currentPage === 'pageBrandaResult' ||
    currentPage === 'pageBrandaResult2' ||
    currentPage === 'pageBrandaThankYou' ||
    currentPage === 'pageStep1' ||
    currentPage === 'pageStep2' ||
    currentPage === 'pageStep3'
  ) {
    button = null
  } else {
    button = (
      <BackButton
        backPage={backPage}
        handleBackPage={handleBackPage}
        text="Back"
        className="quiz_back_button z-10 block absolute"
      />
    )
  }

  useEffect(() => {
    if (currentPage === 'pageBrandaResult') {
      setTimeout(() => {
        dispatch(setIsLoading(false))
      }, 9000)
    }
  }, [dispatch, currentPage])

  let pageBrandaResult
  if (!(isLoading) && currentPage === 'pageBrandaResult') {
    pageBrandaResult = (
      <BrandaResult
        handleShowPage={handleShowPage}
        className={
          currentPage === 'pageBrandaResult'
            ? 'branda_result visible visible_1 flex flex-col justify-center items-center gap-2 bg-white rounded-3xl w-full py-20 left-0 md:mx-auto md:mt-0 mb-24'
            : 'branda_result hidden'
        }
      />
    )
  } else if (isLoading && currentPage === 'pageBrandaResult') {
    pageBrandaResult = (
      <div className='circular_page_eight bg-white flex justify-center items-center rounded-3xl relative basis-11/12 md:basis-11/12 lg:basis-9/12 py-28'>
        <CircularWithValueLabel />
      </div>
    )
  }

  return (
    <>
      {button != null && (
        <div className="relative">
          {button}
        </div>
      )}

      {isModalOpen && currentVideo && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-5 rounded-lg max-w-2xl w-full h-[87%] md:h-[unset]">
          <div className='flex justify-end' >
            <button className="absolute text-white text-xl" onClick={handleCloseModal}style={{ marginTop: '-22px' }}>
              &times;
            </button>
          </div>
          {!showTranscript
            ? (
              <>
                {currentVideo && <VideoPlayer filename={currentVideo.videoUrl} />}
                <div className='flex justify-end'>
                  {Array.isArray(currentVideos) && currentVideoIndex > 0 && (
                    <button className="px-4 py-2" onClick={handlePreviousVideo}>
                      <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-chevron-left" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#ffffff" fill="none" strokeLinecap="round" strokeLinejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <path d="M15 6l-6 6l6 6" />
                      </svg>
                    </button>
                  )}
                  {Array.isArray(currentVideos) && currentVideoIndex < currentVideos.length - 1 && (
                    <button className="px-4 py-2" onClick={handleNextVideo}>
                      <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-chevron-right" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#ffffff" fill="none" strokeLinecap="round" strokeLinejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <path d="M9 6l6 6l-6 6" />
                      </svg>
                    </button>
                  )}
                </div>
              </>
              )
            : (
              <div>
                <div className="transcript-content bg-[#ffffffb8] rounded p-2" style={{ height: '90%', overflowY: 'auto' }}>
                  <div dangerouslySetInnerHTML={{ __html: currentVideo.transcriptUrl }} />
                </div>
                <div className='flex justify-end'>
                    {Array.isArray(currentVideos) && currentVideoIndex > 0 && (
                      <button className="px-4 py-2" onClick={handlePreviousVideo}>
                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-chevron-left" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#ffffff" fill="none" strokeLinecap="round" strokeLinejoin="round">
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <path d="M15 6l-6 6l6 6" />
                        </svg>
                      </button>
                    )}
                    {Array.isArray(currentVideos) && currentVideoIndex < currentVideos.length - 1 && (
                      <button className="px-4 py-2" onClick={handleNextVideo}>
                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-chevron-right" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#ffffff" fill="none" strokeLinecap="round" strokeLinejoin="round">
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <path d="M9 6l6 6l-6 6" />
                        </svg>
                      </button>
                    )}
                </div>
              </div>
              )}
              <div className="flex justify-between mt-4">
              <button
                className="px-4 py-2 bg-black rounded text-white w-28"
                onClick={toggleTranscript}
              >
                {showTranscript ? 'Video' : 'Transcript'}
              </button>
              <button
                className="px-4 py-2 bg-gray-200 rounded w-28"
                onClick={handleCloseModal}
              >
                Skip
              </button>
            </div>
          </div>
        </div>
      )}

      <QuizEmailPage
        handleShowPage={handleShowPage}
        className={
          currentPage === 'pageOne'
            ? 'quiz_email_page visible bg-white rounded-3xl relative w-full basis-11/12 md:basis-11/12 lg:basis-9/12 py-20 mb-10 md:mb-28'
            : 'quiz_email_page hidden'
        }
      />

      <QuizVerificationPage
        handleShowPage={handleShowPage}
        className={
          currentPage === 'pageTwo'
            ? 'quiz_verification_page visible relative flex flex-col justify-center items-center gap-2 bg-white rounded-3xl w-full py-20 basis-11/12 md:basis-11/12 lg:basis-9/12 mb-40 md:mb-48'
            : 'quiz_verification_page hidden'
        }
      />

      <QuizPasswordPage
        handleShowPage={handleShowPage}
        className={
          currentPage === 'pageThree'
            ? 'quiz_password_page visible relative flex flex-col justify-center items-center gap-2 bg-white rounded-3xl w-full py-20 basis-11/12 md:basis-11/12 lg:basis-9/12 mb-40 md:mb-48'
            : 'quiz_password_page hidden'
        }
      />

      <QuizGoalsPage
        handleShowPage={handleShowPage}
        className={
          currentPage === 'pageFour'
            ? 'quiz_goals_page visible relative flex flex-col justify-center items-center bg-white rounded-3xl w-full basis-11/12 md:basis-11/12 lg:basis-9/12 p-4 pb-20  mb-28'
            : 'quiz_goals_page hidden'
        }
        handleModalOpen={handleModalOpen}
      />

      <QuizGenderPage
        handleShowPage={handleShowPage}
        className={
          currentPage === 'pageFive'
            ? 'quiz_gender_page visible flex flex-col justify-center items-center relative bg-white rounded-3xl w-full basis-11/12 md:basis-11/12 lg:basis-9/12 py-20 mb-28'
            : 'quiz_gender_page hidden'
        }
      />

      <QuizBirthdatePage
        handleShowPage={handleShowPage}
        className={
          currentPage === 'pageSix'
            ? 'quiz_birthdate_page visible relative flex flex-col justify-center items-center gap-2 bg-white rounded-3xl w-full basis-11/12 md:basis-11/12 lg:basis-9/12 py-20 mb-28 md:mb-28'
            : 'quiz_birthdate_page hidden'
        }
      />

      <QuizProfessionPage
        handleShowPage={handleShowPage}
        className={
          currentPage === 'pageSeven'
            ? 'quiz_profession_page visible flex flex-col justify-center items-center gap-2 bg-white rounded-3xl w-full basis-11/12 md:basis-11/12 lg:basis-9/12 py-20 mb-24'
            : 'quiz_profession_page hidden'
        }
      />

      <QuizExpertise
        handleShowPage={handleShowPage}
        className={
          currentPage === 'pageEight'
            ? 'quiz_expertise_page visible flex flex-col justify-center items-center relative bg-white rounded-3xl w-full basis-11/12 md:basis-11/12 lg:basis-9/12 py-20 mb-28'
            : 'quiz_expertise_page hidden'
        }
        handleModalOpen={handleModalOpen}
      />

      <QuizFirstStep
        handleShowPage={handleShowPage}
        className={
          currentPage === 'pageStep1'
            ? 'quiz_first_step visible bg-white rounded-3xl relative w-full basis-11/12 md:basis-11/12 lg:basis-9/12 py-20 mb-24'
            : 'quiz_first_step hidden'
        }
      />
      <QuizSecondStep
        handleShowPage={handleShowPage}
        className={
          currentPage === 'pageStep2'
            ? 'quiz_second_step visible bg-white rounded-3xl relative w-full basis-11/12 md:basis-11/12 lg:basis-9/12 pt-10 mb-24'
            : 'quiz_second_step hidden'
        }
      />
      <QuizThirdStep
        handleShowPage={handleShowPage}
        className={
          currentPage === 'pageStep3'
            ? 'quiz_third_step visible relative bg-white rounded-3xl w-full basis-11/12 md:basis-11/12 lg:basis-9/12 pt-20 pb-10 mb-24'
            : 'quiz_third_step hidden'
        }
      />

      {pageBrandaResult}

      <BrandaResultPage2
        handleShowPage={handleShowPage}
        className={
          currentPage === 'pageBrandaResult2'
            ? 'branda_result_page_2 visible visible_1 flex flex-col justify-center items-center gap-2 bg-white rounded-3xl w-full pb-20 pt-10 left-0 md:mx-auto md:mt-0 mb-24'
            : 'branda_result_page_2 hidden'
        }
      />
      <BrandaThankYouPage
        handleShowPage={handleShowPage}
        className={
          currentPage === 'pageBrandaThankYou'
            ? 'branda_thank_you_page visible visible_1 bg-white flex flex-col justify-center items-center gap-2 bg-white rounded-3xl w-full py-20 left-0 md:mx-auto md:mt-0 mb-24'
            : 'branda_thank_you_page hidden'
        }
      />
    </>
  )
}
