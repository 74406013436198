/* eslint-disable */
import { type ApiClient } from '../ApiClient'

export class PreSignUpUsers {
  private readonly api: ApiClient

  constructor (api: ApiClient) {
    this.api = api
  }

  async sendEmailVerification (
    email: string,
    first_name: string,
    last_name: string,
    country: string
  ): Promise<any> {
    return await this.api.postRaw<object>('pre_sign_up_users/register/', {
      email,
      first_name,
      last_name,
      country
    })
  }

  async resendVerificationCode (email: string): Promise<any> {
    return await this.api.post<object>('pre_sign_up_users/re_confirm_code/', { email })
  }

  async validateVerificationCode (
    email: string,
    confirmation_code: string
  ): Promise<any> {
    return await this.api.post<object>('pre_sign_up_users/confirm_code/', {
      email,
      confirmation_code
    })
  }

  async notifyUserForBranda (
    email: string,
    branda_name: string,
    prime_value: string,
    mantra: string,
    life_goal: string,
    brand_id: string,
    goal_crushing_tactics: string,
    image_source: string
  ): Promise<any> {
    return await this.api.post<object>('pre_sign_up_users/notify_branda_score/', {
      email,
      branda_name,
      prime_value,
      mantra,
      life_goal,
      brand_id,
      goal_crushing_tactics,
      image_source
    })
  }
  
  async deleteFromWaitingList (email: string): Promise<any> {
    await this.api.delete<object>('pre_sign_up_users/delete_by_email/', { email })
  }
}
