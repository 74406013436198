/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-no-undef */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/no-confusing-void-expression */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import { useEffect, useRef, useState } from 'react'
import { TextareaAutosize } from '@mui/base/TextareaAutosize'
import { type Tasks, type Task } from 'app/quiz/quizSlice'
import instagram from '../../../img/instagram.png'
import facebook from '../../../img/facebook.png'
import linkedin from '../../../img/linkedin_logo.png'
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  type SelectChangeEvent,
  Chip
} from '@mui/material'
import { LANGUAGES } from '../../atoms/CountryDropdown/languages'
import { useAppSelector } from 'app/hooks'
import { users } from 'app/services/Api'
import { toast } from 'react-toastify'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons'

interface Props {
  className?: string
  task?: Task
}

export default function ToDo ({ className, task }: Props) {
  const [message, setMessage] = useState('')
  const [currentTask, setCurrentTask] = useState<Tasks[]>([])
  const currentUser = useAppSelector(state => state.quizReducer.currentUser)
  const [selectedOption, setSelectedOption] = useState('American English dialect')
  const inputRef = useRef(null)
  const [isLoading, setIsLoading] = useState(false)
  const [hasRequested, setHasRequested] = useState(false)
  const [requestFailed, setRequestFailed] = useState(false)
  const [isTextFromRight, setIsTextFromRight] = useState('')
  const [inputValue, setInputValue] = useState('')
  const [isInputEmpty, setIsInputEmpty] = useState(false)
  const [personalization, setPersonalization] = useState<string>('')
  const [wordCountError, setWordCountError] = useState(false)
  const [isPersonalizationEmpty, setIsPersonalizationEmpty] = useState(true)

  useEffect(() => {
    const fetchData = async () => {
      try {
        let totalTasks: Tasks[] = []
        const response = await users.getUserTasks(true)
        totalTasks = response

        const filteredTask = totalTasks.filter(taskObj => {
          return taskObj.task.id === task?.id
        })

        if (filteredTask && filteredTask.length > 0) {
          setCurrentTask(filteredTask)
          setSelectedOption(filteredTask[0].language ?? 'American English dialect')

          if (filteredTask[0].prediction) {
            setMessage(filteredTask[0].prediction)
          }

          if (filteredTask[0].personalization) {
            setPersonalization(filteredTask[0].personalization)
          }

          if (filteredTask[0].language === 'Hebrew') {
            setIsTextFromRight('rtl')
          }
        }
      } catch (err) {
        console.log(err)
      }
    }
    fetchData()
  }, [currentUser, task])

  const handleTextareaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setMessage(e.target.value)
  }

  const handleChange = (event: SelectChangeEvent) => {
    const value = event.target.value

    if (value === 'Hebrew') {
      setIsTextFromRight('rtl')
    } else {
      setIsTextFromRight('')
    }
    void handleTaskLanguage(task?.id ?? 0, value)
    setSelectedOption(value)
  }

  const handleTaskLanguage = async (
    taskId: number,
    language: string
  ) => {
    try {
      await users.updateUserTask(taskId, undefined, language).then().catch((err) => {
        console.log(err)
      })
    } catch (err) {
      console.log(err)
    }
  }

  const handleGeneratedAI = async () => {
    setIsLoading(true)
    setHasRequested(true)
    try {
      await handlePersonalisation(task?.id ?? 0, personalization)
      await users.postAIGenerated(currentTask[0].id).then((response) => {
        setMessage(response.prediction)
        setHasRequested(true)
      }).catch((err) => {
        toast.error('Please try again!')
        setRequestFailed(true)
        setHasRequested(false)
        console.log(err)
      })
    } catch (err) {
      console.log(err)
    } finally {
      setIsLoading(false)
    }
  }

  const isiPhone = () => {
    return /iPhone/i.test(navigator.userAgent)
  }

  const handleCopyClick = async () => {
    if (inputRef.current) {
      const textArea = inputRef.current as HTMLTextAreaElement | null
      if (textArea) {
        textArea.focus()
        textArea.select()
        try {
          if (isiPhone()) {
            await window.navigator.clipboard.writeText(textArea.value)
          } else {
            navigator.clipboard.writeText(textArea.value)
          }

          await users.updateUserTask(task?.id ?? 0, undefined, undefined, true).then().catch((err) => {
            console.log(err)
          })

          toast.success('Text copied to clipboard!', {
            position: toast.POSITION.BOTTOM_RIGHT
          })

          if (task?.platform === 'Facebook') {
            setTimeout(() => {
              window.open('https://www.facebook.com/', '_blank')
            }, 30)
          } else if (task?.platform === 'LinkedIn') {
            setTimeout(() => {
              window.open('https://www.linkedin.com/', '_blank')
            }, 30)
          } else if (task?.platform === 'Instagram') {
            setTimeout(() => {
              window.open('https://www.instagram.com/', '_blank')
            }, 30)
          }
        } catch (err) {
          console.error('Unable to copy text to clipboard', err)
        }
      }
    }
  }

  const platformImageMap: Record<string, string> = {
    Facebook: facebook,
    LinkedIn: linkedin,
    Instagram: instagram
  }

  useEffect(() => {
    setIsInputEmpty(inputValue.trim() === '')
  }, [inputValue])

  const handlePersonalisation = async (
    taskId: number,
    personalization: string
  ) => {
    try {
      await users.updateUserTask(taskId, undefined, undefined, undefined, undefined, personalization).then().catch((err) => {
        console.log(err)
      })
    } catch (err) {
      console.log(err)
    }
  }

  const handlePersonalizationChange = (e: { target: { value: any } }) => {
    const inputValue = e.target.value
    const words = inputValue.split(/\s+/)
    const limitedWords = words.slice(0, 80)
    const limitedValue = limitedWords.join(' ')
    setIsPersonalizationEmpty(limitedValue.trim() === '')
    if (words.length > 80) {
      setWordCountError(true)
      toast.error('Maximum 80 words allowed')
    } else {
      setWordCountError(false)
      setPersonalization(limitedValue)
    }
  }

  return (
    <div className='w-full'>
      <div className="flex justify-center items-center md:justify-start py-12">
        {task?.platform &&
          <img src={platformImageMap[task?.platform]} alt={task?.platform} width={35} />
        }
        <span>
          <h3 className="ml-2 md:text-xl font-medium">
            {task?.platform}
          </h3>
        </span>
      </div>

      <div className="flex flex-col p-4 md:p-6 md:px-12 rounded-2xl bg-gray-100 gap-3 w-full">
        <h1 className="font-bold font-base text-xl leading-7 text-center md:text-start text-black block">{((task?.task_title) != null) ? task.task_title : ''}</h1>
        <h2 className="font-normal text-start break-words text-gray-500 text-sm md:text-lg">
          {((task?.task_body) != null) ? task.task_body : ''}
        </h2>
        <span className='group relative text-purple-600 text-sm md:text-base font-bold inline-block'>
          Publishing Time
          <FontAwesomeIcon icon={solid('clock')} style={{ color: '#A66EDD', marginLeft: '0.5rem' }} />
          <div className='absolute md:left-36 top-1/2 transform -translate-y-1/2 ml-2 shadow border-solid bg-purple-300 text-black font-light text-xs py-1 px-2 w-40 rounded-md opacity-0 group-hover:opacity-100 transition-opacity duration-300'>
            {task?.when}
          </div>
        </span>
        <div className='flex items-center mb-2 p-2 rounded-xl branda-score mt-1 shadow shadow-purple-400'>
          <span className='text-2xl'><FontAwesomeIcon icon={solid('exclamation-circle')} style={{ color: '#A66EDD' }} /></span>
          <p className='text-xs md:text-sm ml-3'>
            Below you can find your personalized content suggestions 🌟 🥳 ✌🏻 <br />
            Remember ‼️ 🔔 this is a general template! Please check the spelling and edit the text to your specific needs before publishing! 🤩
          </p>
        </div>

        {task?.platform === 'LI' && (
          <p className='block md:hidden text-sm basis-3/12'>For this task, English is preferred.</p>
        )}
     <div className={`flex flex-col md:flex-row items-center my-5 ${task?.subject_for_ai ? 'justify-between' : 'justify-end'}`}>
          {task?.platform === 'LI' && (
            <p className='hidden md:block text-sm mr-3 mb-5 md:mb-0 basis-6/12 text-right'>For this task, English is preferred.</p>
          )}
          {task?.subject_for_ai &&
            <div className="flex flex-col w-11/12 md:w-5/12 lg:w-5/12">
              <div className='flex flex-col relative'>
                <span className='text-xs'>Personalization</span>
                <TextareaAutosize
                  minRows={2}
                  value={personalization}
                  onChange={handlePersonalizationChange}
                  placeholder='After you’re done click on Go'
                  style={{
                    backgroundColor: '#fff',
                    border: '1px solid #ccc',
                    borderRadius: '5px',
                    width: '100%',
                    boxSizing: 'border-box',
                    marginRight: '10px',
                    fontSize: '14px',
                    textAlign: 'left'
                  }}
                  className='p-3'
                />
                {wordCountError && <p className="text-red-500">Maximum 80 words allowed</p>}
              </div>
            </div>
            }
          <div className='flex relative w-11/12 md:w-4/12 lg:w-4/12 items-center mt-4 md:mt-0'>
            <FormControl className='w-full' >
              <InputLabel id="simple-select-label">
                Choose Language
              </InputLabel>
              <Select
                labelId="simple-select-label"
                id="simple-select"
                label="Choose Language"
                onChange={handleChange}
                value={selectedOption}
              >
                {LANGUAGES.map((item, index) => (
                  <MenuItem key={index} value={item.value}>
                    <img
                      alt={`${item.value}`}
                      src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${item.img_value}.svg`}
                      className={'inline mr-2 h-4 rounded-sm'}
                    />
                    {item.title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <div className='flex flex-col items-center ml-3'>
              {message === '' &&
                <>
                  <button
                    type="button"
                    className={`w-fit border bg-purple-100 hover:bg-purple-300 border-gray-300 rounded-md shadow-sm px-3 py-2 text-left sm:text-sm ${isPersonalizationEmpty ? '' : 'bg-purple-300'} ${hasRequested ? 'cursor-not-allowed opacity-50' : ''}`}
                    aria-haspopup="listbox"
                    disabled={hasRequested}
                    aria-expanded="true"
                    aria-labelledby="listbox-label"
                    onClick={handleGeneratedAI}
                  >
                    {isLoading ? 'Loading...' : 'GO!'}
                  </button>
                  <span className={requestFailed ? 'inline text-sm text-red-300' : 'hidden'}>Please retry!</span>
                </>
              }
            </div>
          </div>
        </div>

        <div className="relative flex justify-center">
          {isLoading && (
            <div className="flex flex-col items-center absolute top-4 mx-auto">
              <p className="text-sm md:text-base text-purple-700 mb-3">Perfection takes time ✨🪄 👌</p>
              <FontAwesomeIcon icon={faCircleNotch} spin fontSize={'24pt'} className='text-purple-700' />
            </div>
          )}
          <TextareaAutosize
            className={`rounded-lg bg-white w-full outline-none p-4 text-base ${isTextFromRight}`}
            value={message}
            ref={inputRef}
            disabled={isLoading}
            onChange={handleTextareaChange}
            placeholder={isLoading ? '' : 'Your personalized content suggestion will appear HERE after choosing your preferred language for this task. Choose wisely! You get only one suggestion per task ☝🏻. Afterwards, click on ‘Go’.'}
            minRows={8}
          />
        </div>

        <div className='flex justify-center mt-12 md:mt-0 bottom-8 md:justify-end'>
          <button
            onClick={handleCopyClick}
            disabled={message === ''}
            className='flex justify-center items-center md:right-12 bottom-4 mb-2 md:mb-8 weekly_plan_button capitalize text-white font-medium rounded-lg text-xs md:text-sm text-center transition-all ease-in-out duration-600 bg-blue-500'
          >Copy and Go to Platform</button>
        </div>
      </div>
    </div>
  )
}
