import React from 'react'

interface Props {
  isOpen: boolean
  onClose: () => void
  onConfirm: () => void
}

export default function DeleteConfirmationModal ({
  isOpen,
  onClose,
  onConfirm
}: Props) {
  return (
    <div
      className={`fixed z-40 top-0 left-0 w-full h-full flex items-center justify-center ${isOpen ? '' : 'hidden'
        }`}
    >
      <div className="absolute w-full h-full bg-gray-800 opacity-50"></div>
      <div className="bg-white mx-4 md:mx-0 z-50 p-2 md:p-6 shadow-md border-2 border-gray-700 rounded-md">
        <div className="flex flex-col items-center justify-between p-2 md:p-3 rounded-t dark:border-gray-600 text-left">
          <div className="flex items-center justify-between w-full mb-4">
            <span className="text-xl" role="img" aria-label="Bell icon">
              🔔
            </span>
            <button
              onClick={onClose}
              type="button"
              className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 inline-flex justify-center items-center"
              data-modal-hide="static-modal"
            >
              <svg
                className="w-3 h-3"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 14"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                />
              </svg>
              <span className="sr-only">Close modal</span>
            </button>
          </div>
          <div className=" items-start">
            <p className="font-medium text-xs md:text-xl text-black  md:p-4 mr-5 mb-2">
            You are about to delete all your data from myBranda.<br/>
              After deletion, this data will never be restored.<br/>
              <strong>Are you sure you want to continue?</strong>
            </p>

          </div>
        </div>
        <div className="flex justify-center md:justify-end mb-4">
          <button
            className="mr-2 px-2 md:px-4 py-2 bg-green-600 text-xs md:text-base text-white rounded"
            onClick={onClose}
          >
            No, don&apos;t delete
          </button>
          <button
            className="px-2 md:px-4 py-2 bg-red-500 text-xs md:text-base text-white rounded"
            onClick={onConfirm}
          >
            Yes, delete my profile
          </button>
        </div>
      </div>
    </div>
  )
}
