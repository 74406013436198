const subscriptionPlans = [
  {
    id: -1,
    branda_type: 'Brand Trial',
    price: '$0',
    img: 'images/Subscription-plan-0.svg',
    title: 'Your Personal Brand Identity \n Personal Branding score \n Personalized 2 weeks plan \n Personalized content suggestions \n Personal task reminders',
    paypro_url: '',
    product_id: -1
  },
  {
    id: 0,
    branda_type: 'Brand Booster',
    price: '$10',
    img: 'images/Subscription-plan-1.svg',
    title: 'Your Personal Brand Identity \n Personal Branding score \n Personalized 4 weeks plan \n Personalized content suggestions \n Personal task reminders',
    paypro_url: 'https://store.payproglobal.com/checkout?products[1][id]=83053',
    product_id: 83053
  },
  {
    id: 1,
    branda_type: 'Brand Star',
    price: '$25',
    img: 'images/Subscription-plan-2.svg',
    title: 'Your Personal Brand Identity \n Personal Branding score \n Personalized 12 weeks plan \n Personalized content suggestions \n Personal task reminders',
    paypro_url: 'https://store.payproglobal.com/checkout?products[1][id]=88434',
    product_id: 88434
  },
  {
    id: 2,
    branda_type: 'Brand Master',
    price: '$85',
    img: 'images/Subscription-plan-3.svg',
    title: '<a class="underline" href="/contact-us?message=Tell me more about custom business plans!">Contact us</a> to discover myBranda’s custom-made branding plans tailored to your organization!',
    paypro_url: '',
    product_id: 0
  }
]

export default subscriptionPlans
