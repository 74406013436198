import React from 'react'
import Heading from '../../atoms/Heading/Heading'
import ShareButton from './ShareButton'

interface Props {
  className?: string
}

export default function BrandaScoreTitle ({ className }: Props) {
  return (
    <div className='flex flex-col md:flex-row justify-between bg-white rounded-2xl p-4'>
      <div className='flex flex-col justify-start items-start gap-2 md:p-4'>
        <Heading className='text-black font-manrope text-lg md:text-xl lg:text-4xl font-medium' text='Your Branda Score' />
        <Heading className='text-gray-400 font-manrope text-base font-normal leading-6' text="Discover your brand activity score and benchmark it to your peers!" />
      </div>
      <ShareButton />
    </div>
  )
}
