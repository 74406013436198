/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/space-before-function-paren */
import React, { useState } from 'react'
import BrandaScoreTitle from '../BrandaScore/BrandaScoreTitle'
import BrandaScoreAverage from '../BrandaScore/BrandaScoreAverage'
import Benchmark from '../BrandaScore/Benchmark'
import StartYourPlan from '../BrandaScore/StartYourPlan'
import BrandaScorePopUp from '../BrandaScore/BrandaScorePopUp/BrandaScorePopUp'
import SocialMediaPlatformPopUp from '../BrandaScore/BrandaScorePopUp/SocialMediaPlatformPopUp'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import SocialsPopUp from '../BrandaScore/BrandaScorePopUp/SocialsPopUp'
import { users } from 'app/services/Api'
import { setCurrentUser } from 'app/quiz/quizSlice'
import BrandaUserLevels from '../BrandaScore/BrandaUserLevels'

export default function BrandaScoreForm() {
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(true)
  const [isSocialMediaPlatformPopUpOpen, setIsSocialMediaPlatformPopUpOpen] = useState(false)
  const [isInstagramPopUpOpen, setIsInstagramPopUpOpen] = useState(false)
  const [isFacebookPopUpOpen, setIsFacebookPopUpOpen] = useState(false)
  const [isLinkedInPopUpOpen, setIsLinkedInPopUpOpen] = useState(false)
  const [socialMediaCount, setSocialMediaCount] = useState(0)

  const currentUser = useAppSelector(state => state.quizReducer.currentUser)
  const isIGActive = useAppSelector((state) => state.userSocialMediaReducer.instagram)
  const isIGPROActive = useAppSelector((state) => state.userSocialMediaReducer.instagrampro)
  const isFBActive = useAppSelector((state) => state.userSocialMediaReducer.facebook)
  const isFBPROActive = useAppSelector((state) => state.userSocialMediaReducer.facebookpro)
  const isLIActive = useAppSelector((state) => state.userSocialMediaReducer.linkedin)
  const dispatch = useAppDispatch()

  const getUser = async () => {
    await users.getCurrentUser(true).then((response) => {
      if (!response.response) {
        dispatch(setCurrentUser(response))
      } else {
        dispatch(setCurrentUser(null))
      }
    })
      .catch((err) => {
        console.log(err)
      })
  }

  const handleSocialMediaPlatformPopUpConfirm = () => {
    if (isIGActive || isIGPROActive) {
      setIsInstagramPopUpOpen(true)
    } else if (isFBActive || isFBPROActive) {
      setIsFacebookPopUpOpen(true)
    } else if (isLIActive) {
      setIsLinkedInPopUpOpen(true)
    }
    const countActiveSocials = (isIGActive || isIGPROActive ? 1 : 0) + (isFBActive || isFBPROActive ? 1 : 0) + (isLIActive ? 1 : 0)
    setSocialMediaCount(countActiveSocials)
    setIsSocialMediaPlatformPopUpOpen(false)
  }

  const handleSocialMediaPlatformPopUpClose = () => {
    setIsSocialMediaPlatformPopUpOpen(false)
  }

  const handleInstagramPopUpClose = () => {
    setIsInstagramPopUpOpen(false)
  }

  const handleFacebookPopUpClose = () => {
    setIsFacebookPopUpOpen(false)
  }

  const handleLinkedInPopUpClose = () => {
    setIsLinkedInPopUpOpen(false)
  }

  const handleInstagramPopUpConfirm = async () => {
    setIsInstagramPopUpOpen(false)
    if ((!isFBActive && !isFBPROActive) && !isLIActive) {
      setIsSocialMediaPlatformPopUpOpen(false)
    } else if (isFBActive || isFBPROActive) {
      setIsFacebookPopUpOpen(true)
    } else if (isLIActive) {
      setIsLinkedInPopUpOpen(true)
      setIsSocialMediaPlatformPopUpOpen(false)
    }

    if (socialMediaCount === 1 && currentUser?.id !== undefined) {
      await users.updateUser(currentUser?.id, { has_finished_quiz: true })
      void getUser()
    }
  }

  const handleFacebookPopUpConfirm = async () => {
    setIsFacebookPopUpOpen(false)
    setIsSocialMediaPlatformPopUpOpen(false)
    setIsLinkedInPopUpOpen(true)

    if (socialMediaCount === 2 && !isLIActive && currentUser?.id !== undefined) {
      await users.updateUser(currentUser?.id, { has_finished_quiz: true })
      void getUser()
    }
  }

  const handleLinkedInPopUpConfirm = async () => {
    setIsLinkedInPopUpOpen(false)
    if (!isIGActive && !isFBActive) {
      setIsSocialMediaPlatformPopUpOpen(false)
    }
    if (currentUser?.id !== undefined) {
      await users.updateUser(currentUser?.id, { has_finished_quiz: true })
      void getUser()
    }
  }

  const handleConfirmationCompleted = () => {
    setIsConfirmationModalOpen(false)
    setIsSocialMediaPlatformPopUpOpen(true)
  }

  const handleCloseConfirmation = () => {
    setIsConfirmationModalOpen(false)
  }

  return (
    <div className="flex flex-col gap-5 visible_1">

      {currentUser?.has_finished_quiz === false
        ? (
          <>
            <BrandaScorePopUp
              isOpen={isConfirmationModalOpen}
              onClose={handleCloseConfirmation}
              onConfirm={handleConfirmationCompleted}
            />

            {isSocialMediaPlatformPopUpOpen && (
              <SocialMediaPlatformPopUp
                isOpen={isSocialMediaPlatformPopUpOpen}
                onClose={handleSocialMediaPlatformPopUpClose}
                onConfirm={handleSocialMediaPlatformPopUpConfirm}
              />
            )}

            {(isIGActive || isIGPROActive) && (isInstagramPopUpOpen) && (
              <SocialsPopUp
                isOpen={isInstagramPopUpOpen}
                onClose={handleInstagramPopUpClose}
                onConfirm={handleInstagramPopUpConfirm}
                socialMedia={isIGPROActive ? 'InstagramPro' : 'Instagram'}
              />
            )}

            {(isFBActive || isFBPROActive) && isFacebookPopUpOpen && (
              <SocialsPopUp
                isOpen={isFacebookPopUpOpen}
                onClose={handleFacebookPopUpClose}
                onConfirm={handleFacebookPopUpConfirm}
                socialMedia={isFBPROActive ? 'FacebookPro' : 'Facebook'}
              />
            )}

            {isLIActive && isLinkedInPopUpOpen && (
              <SocialsPopUp
                isOpen={isLinkedInPopUpOpen}
                onClose={handleLinkedInPopUpClose}
                onConfirm={handleLinkedInPopUpConfirm}
                socialMedia="Linkedin"
              />
            )}
          </>
          )
        : (
            null
          )
      }

      <BrandaScoreTitle />
      <BrandaScoreAverage />
      <BrandaUserLevels />
      <Benchmark />
      <StartYourPlan />
    </div>
  )
}
