/* eslint-disable */
import { type ApiClient } from '../ApiClient'

export class Chat {
  private readonly api: ApiClient

  constructor (api: ApiClient) {
    this.api = api
  }
  async chats (first_name: string, last_name: string, country: string, message: string): Promise<any> {
    return await this.api.post<object>('chats/', { first_name, last_name, country, message })
  }
}