/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from 'react'
import Card from '../../atoms/Card/Card'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { setGoalQ1, setGoalQ2 } from '../../../app/quiz/quizSlice'
import { users } from 'app/services/Api'

interface Props {
  className: string
  handleShowPage: (page: string) => void
}

export default function QuizGoalsPageForm ({ className, handleShowPage }: Props) {
  const GoalQ1 = useAppSelector(state => state.quizReducer.GoalQ1)
  const GoalQ2 = useAppSelector(state => state.quizReducer.GoalQ2)
  const currentUser = useAppSelector(state => state.quizReducer.currentUser)
  const country = useAppSelector(state => state.quizReducer.country)
  const dispatch = useAppDispatch()
  const [cards, setCards] = useState([
    {
      id: 1,
      number: 0,
      text: 'I want more customers',
      isActive: false
    },
    {
      id: 2,
      number: 0,
      text: 'I want to charge more for my services',
      isActive: false
    },
    {
      id: 3,
      number: 0,
      text: 'I want to overcome my fear of self exposure',
      isActive: false
    },
    {
      id: 4,
      number: 0,
      text: 'I want to generate more quality content',
      isActive: false
    },
    {
      id: 5,
      number: 0,
      text: 'I want to be more likeable',
      isActive: false
    },
    {
      id: 6,
      number: 0,
      text: 'I want to strengthen my existing online brand',
      isActive: false
    }
  ])

  const [isDisabled, setIsDisabled] = useState(true)

  useEffect(() => {
    const activeCards = cards.filter((card) => card.isActive)
    setIsDisabled(activeCards.length !== 2)
  }, [cards])

  useEffect(() => {
    if (currentUser?.goal_q1 !== null || currentUser?.goal_q2 !== null) {
      const clickedGoalQ1 = cards.find((card) => card.text === currentUser?.goal_q1)
      const clickedGoalQ2 = cards.find((card) => card.text === currentUser?.goal_q2)

      if (clickedGoalQ1 != null) {
        clickedGoalQ1.isActive = true
        clickedGoalQ1.number = 1
        dispatch(setGoalQ1(currentUser?.goal_q1 ?? ''))
      }

      if (clickedGoalQ2 != null) {
        clickedGoalQ2.isActive = true
        clickedGoalQ2.number = 2
        dispatch(setGoalQ2(currentUser?.goal_q2 ?? ''))
      }

      setCards([...cards])
    }
  }, [currentUser])

  const handleCardClick = (id: number) => {
    const clickedCard = cards.find((card) => card.id === id)
    const activeCards = cards.filter((card) => card.isActive)

    if (clickedCard !== null && clickedCard !== undefined) {
      if (activeCards.length === 0) {
        clickedCard.number = 1
        clickedCard.isActive = !clickedCard.isActive

        dispatch(setGoalQ1(clickedCard.isActive ? clickedCard.text : ''))
      } else if (activeCards.length === 1) {
        clickedCard.number = 2
        clickedCard.isActive = !clickedCard.isActive

        dispatch(setGoalQ2(clickedCard.isActive ? clickedCard.text : ''))
      } else if (activeCards.length === 2) {
        const sortedActiveCards = activeCards.sort((a, b) => a.number - b.number)
        const firstActiveCard = sortedActiveCards[0]
        const secondActiveCard = sortedActiveCards[1]

        if (activeCards.includes(clickedCard) && clickedCard === firstActiveCard) {
          firstActiveCard.number = 0
          secondActiveCard.number = 1
          clickedCard.isActive = !clickedCard.isActive

          dispatch(setGoalQ1(clickedCard.isActive ? clickedCard.text : ''))
        }

        if (activeCards.includes(clickedCard) && clickedCard === secondActiveCard) {
          secondActiveCard.number = 0
          clickedCard.isActive = !clickedCard.isActive

          dispatch(setGoalQ2(clickedCard.isActive ? clickedCard.text : ''))
        }
      }
    }

    setCards([...cards])
  }

  const handleUpdateUser = async (e: React.FormEvent) => {
    e.preventDefault()

    await users.updateRegisteredUser(currentUser?.id ?? 0, {
      goal_q1: GoalQ1,
      goal_q2: GoalQ2,
      country,
      step_name: 'Gender'
    }, true)

    handleShowPage('pageFive')
  }

  return (
    <div className={className}>
      {cards.map((card) => (
        <Card
          key={card.id}
          id={card.id}
          handleCardClick={() => { handleCardClick(card.id) }}
          isActive={card.isActive}
          text={card.text}
          className="quiz_page_two_form_card flex justify-center items-center relative"
          number={card.number}
        />
      ))}
      <button
        onClick={handleUpdateUser}
        disabled={isDisabled}
        className="quiz_form_button text-white rounded-lg px-9 py-2.5 text-center mt-2.5"
      >
        Next
      </button>
    </div>
  )
}
