import Link from '../../atoms/Link/Link'

interface Props {
  className: string
}

export default function FooterLinks ({ className }: Props) {
  return (
    <div className={className}>
      <Link
        className="my_branda_logo flex self-start md:self-center text-black font-medium text-lg md:text-2xl"
        linkTo="/"
        name="myBranda"
        tm={true}
      />
      <div className='flex flex-row gap-2 mb-5 md:mb-0'>
        <Link className="text-sm md:text-base font-normal" name="About Us" linkTo="https://mybranda.com/about-us" target='_blank' rel='noreferrer' />
        <Link className="text-sm md:text-base font-normal" name="Contact Us" linkTo="https://mybranda.com/contact-us" target='_blank' rel='noreferrer' />
        <Link className="text-sm md:text-base font-normal" name="Terms of Service" linkTo="https://mybranda.com/terms" target='_blank' rel='noreferrer' />
        <Link className="text-sm md:text-base font-normal" name="Privacy" linkTo="https://mybranda.com/privacy" target='_blank' rel='noreferrer' />
      </div>
    </div>
  )
}
