/* eslint-disable @typescript-eslint/quotes */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useEffect, useState } from 'react'
import VideoPlayer from "../../Player/VideoPlayer"
import AskBranda from '../../../atoms/Icon/AskBranda'

const videoUrl3 = 'ScoreWsub.mp4'
const mobileVideoUrl3 = 'ScoreMsub.mp4'
const transcriptContent3 = `
  <div>
    <h2 style="font-size: 24px; margin-bottom: 10px;">Your Brand Score</h2>
    <p style="font-size:12px;">You're amazing! <br/>
    Did you like your Branda?<br/><br/>
    
    Anyways, we're almost done...<br/>
    Before sending your personalized plan, let's try to assess your current brand performance! <br/>
    In the next step, please choose your active Social Media profiles or the ones you plan to post from. Choose at least two platforms. Your personal FaceBook account is mandatory - because this is where Personal Branding can actually get Personal!<br/><br/>
    
    Afterwards, please check your profiles and fill in how many posts do you publish on average every week and add how many followers or friends you currently have on each account. I've already broken down the Types of posts for you. If there are posts that you don't publish weekly, you can fill in decimal numbers.  Again, this is only a one-time process!<br/><br/>
    
    You might ask, why do I even need this score?<br/>
    Well, every process has a starting point. This is how I can make sure you'll be able to accurately assess your current brand level, benchmark your score to other industry peers, and visually track your progress! <br/>
    You will also get lots of cool insights!<br/>
    It will take you a minute and, believe me, It's worth it!<br/><br/>
    
    Ready to check your score?!
    </p>
  </div>
`
interface Props {
  isOpen: boolean
  onClose: () => void
  onConfirm: () => void
}

export default function BrandaScorePopUp ({
  isOpen,
  onClose,
  onConfirm
}: Props) {
  const [isModalOpen, setModalOpen] = useState<boolean>(false)
  const [showTranscript, setShowTranscript] = useState<boolean>(false)
  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth < 768)

  useEffect(() => {
    const handleResize = (): void => {
      setIsMobile(window.innerWidth < 768)
    }
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const handleModalOpen = (): void => {
    setModalOpen(true)
  }
  const handleCloseModal = (): void => {
    setModalOpen(false)
  }
  const toggleTranscript = (): void => {
    setShowTranscript(!showTranscript)
  }

  return (
    <div className={`flex fixed top-0 right-0 left-0 z-50 backdrop-blur-sm justify-center items-center w-full md:inset-0 h-full ${isOpen ? '' : 'hidden'}`}>
      <div className="absolute w-full h-full bg-gray-800 opacity-50"></div>
      <div className="relative">
        <div className="branda-score w-[350px] md:w-[600px] h-72 rounded-3xl shadow dark:bg-gray-700 mt-2 pt-5">
          <div className='flex justify-end w-full pr-5'>
            <AskBranda handleModalOpen={handleModalOpen} />
          </div>
          {isModalOpen && (
              <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
                <div className="bg-white p-5 rounded-lg max-w-2xl w-full h-[87%] md:h-[unset]">
                  <div className='flex justify-end' >
                    <button className="absolute text-white text-xl" onClick={handleCloseModal}style={{ marginTop: '-22px' }}>
                      &times;
                    </button>
                  </div>
                  {!showTranscript
                    ? (
                          <>
                            <VideoPlayer filename={isMobile ? mobileVideoUrl3 : videoUrl3} />
                          </>
                      )
                    : (
                          <div>
                            <div className="transcript-content bg-[#ffffffb8] rounded p-2" style={{ height: '90%', overflowY: 'auto' }}>
                              <div dangerouslySetInnerHTML={{ __html: transcriptContent3 }} />
                            </div>

                          </div>
                      )}
                  <div className="flex justify-between mt-4">
                    <button
                        className="px-4 py-2 bg-black rounded text-white w-28"
                        onClick={toggleTranscript}
                    >
                      {showTranscript ? 'Video' : 'Transcript'}
                    </button>
                    <button
                        className="px-4 py-2 bg-gray-200 rounded w-28"
                        onClick={handleCloseModal}
                    >
                      Skip
                    </button>
                  </div>
                </div>
              </div>
          )}
          <div className="flex flex-col items-center justify-center p-2 md:p-3 rounded-t dark:border-gray-600 gap-4">
            <p className="font-light text-base md:text-lg text-black p-0 mr-5">
              Before your first task,
            </p>
            <p className='text-sm md:text-2xl text-black font-semibold md:font-bold'>
              Let&apos;s calculate your personal Branda Score!
            </p>
            <p className="text-sm md:text-base font-light md:text-lg text-black p-0">
              No worries, this is a one time proccess &#128077;
            </p>
          </div>
          <div className="p-2 md:px-5 pt-6 md:pt-3 space-y-4">
            <div className="flex justify-center items-center">
              <button
                className="px-10 py-3 w-48 text-sm md:text-xl font-semibold text-white rounded-2xl bg-purple-600"
                onClick={onConfirm}
              >
                Start
              </button>
            </div>
          </div>
        </div>

      </div>
    </div>
  )
}
