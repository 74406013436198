import WeeklyGoalsContent from '../../organisms/WeeklyGoalsContent/WeeklyGoalsContent'

interface Props {
  className?: string
}

export default function WeeklyGoals ({ className }: Props) {
  return (
    <div className='flex flex-col gap-3 justify-center w-full md:basis-9/12 p-5 md:p-16 mb-24 bg-white rounded-3xl'>
      <WeeklyGoalsContent />
    </div>
  )
}
