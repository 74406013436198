/* eslint-disable  */
import React, { useEffect, useState } from 'react'
import {
  Autocomplete,
  TextField
} from '@mui/material'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { professions, users } from '../../../app/services/Api'
import { setProfession } from '../../../app/quiz/quizSlice'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
interface Props {
  className: string
  handleShowPage: (page: string) => void
}
interface Profession {
  id: number
  name: string
}

export default function QuizProfessionPageForm ({ className, handleShowPage }: Props) {
  const theme = useTheme()
  const isSmallerScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const dispatch = useAppDispatch()
  const currentUser = useAppSelector(state => state.quizReducer.currentUser)
  const profession = useAppSelector(state => state.quizReducer.profession)
  const [isDisabled, setIsDisabled] = useState(true)
  const [availableProfessions, setAvailableProfessions] = useState<Profession[]>([])
  const [selectedOption, setSelectedOption] = useState<string | null>(() => {
    const storedProfession = localStorage.getItem('profession')
    return storedProfession !== null ? storedProfession : null
  })

  const fetchData = async () => {
    try {
      const response = await professions.getAllProfessions()
      setAvailableProfessions(response)
    } catch (error) {
      console.error('Error fetching Profession data:', error)
    }
  }

  useEffect(() => {
    const fetchProfessions = async () => {
      try {
        await fetchData()
      } catch (error) {
        console.error('Error fetching Profession data:', error)
      }
    }

    fetchProfessions()
  }, [])

  const handleChange = async (event: any, value: any) => {
    setSelectedOption(value || '')
    dispatch(setProfession(value || ''))

    await users.updateRegisteredUser(currentUser?.id ?? 0, {
      profession: value
    }, true)
  }

  useEffect(() => {
    setIsDisabled(!selectedOption)
  }, [selectedOption])


  const handleUpdateUser = async (e: React.FormEvent) => {
    e.preventDefault()

    if (isDisabled) {
      return
    }

    await users.updateRegisteredUser(currentUser?.id ?? 0, {
      profession,
      step_name: 'Keywords'
    }, true)
    
    handleShowPage('pageEight')
  }

  useEffect(() => {
    if (currentUser && currentUser.profession) {
      setSelectedOption(currentUser.profession)
      dispatch(setProfession(currentUser.profession))
    }
  }, [currentUser]);

  return (
    <div className={className}>
      <Autocomplete
        disablePortal
        id="combo-box-profession"
        options={availableProfessions.map((profession) => profession.name)}
        value={selectedOption}
        onChange={handleChange}
        renderInput={(params) => <TextField {...params} label="Select a profession" />}
        sx={{ width: isSmallerScreen ? '90%' : '50%' }}
      />
      <button
        disabled={isDisabled}
        onClick={handleUpdateUser}
        className='quiz_form_button text-white rounded-lg px-9 py-2.5 text-center relative md:h-16  mt-8'
      >
        Next
      </button>
    </div>
  )
}
