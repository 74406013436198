/* eslint-disable @typescript-eslint/no-misused-promises */
import React, { useState, useEffect } from 'react'
import Input from '../../atoms/Input/Input'
import CustomLink from '../../atoms/Link/Link'
import { auth } from '../../../app/services/Api'
// import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

interface Props {
  className?: string
}

export default function ResetPassword ({ className }: Props) {
  const [email, setEmail] = useState('')
  const [isDisabled, setIsDisabled] = useState(true)
  // const navigate = useNavigate()

  useEffect(() => {
    setIsDisabled(email === '')
  }, [email])

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value)
  }

  const handleFormSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    await auth.resetPassword(email).then((response) => {
      // navigate('/sign-in')
      toast.info("We've sent you an email with instructions on how to reset your password. Please check your email.", {
        autoClose: 6000
      })
    }).catch((error) => {
      console.log(error)
    })
  }

  return (
    <div className='sign_in_sign_up visible_1 min-h-screen'>
      <div className="flex flex-col  items-center justify-center">
        <div className='flex flex-row w-full justify-between px-8'>
          <CustomLink className='my_branda_logo sm:ml-5 flex self-center font-medium text-2xl md:text-3xl pb-2 mt-5' linkTo='/' name='myBranda' tm={true} />
        </div>
        <form onSubmit={ handleFormSubmit }>
          <div className="flex flex-col justify-center items-start w-72 md:w-96 mt-24">
            <div className="font-medium text-xl md:text-3xl leading-8 text-gray-900 mt-6">
              <h1>Forgot your password?</h1>
            </div>
            <div className="font-normal font-weight-400 text-sm md:text-sm leading-6 text-gray-600 block mt-4">
              <h2>Enter the email address you used to register. You will be sent an email with instructions to reset your password.</h2>
            </div>
            <div className='flex flex-col gap-5 mt-5 w-96'>
              <Input
                placeholder="E-mail address" type="email" onChange={handleEmailChange}
                className="px-2 py-4 h-13 border border-gray-300 rounded-xl bg-white block w-72 md:w-96 focus:outline-none font-medium text-base leading-5 text-gray-900"
                value={email}
              />
            </div>
            <button disabled={isDisabled} className='quiz_form_button  text-white mt-3 !h-11 !w-full'>
              Send email
            </button>
            <div className='flex w-full mt-3 text-sm gap-1'>
              <p>Already have an account?</p>
              <a href="/sign-in" className="text-purple-700 font-medium flex justify-end text-sm hover:underline">Sign In</a>
            </div>
          </div>
        </form>
      </div>
      <div className='flex flex-col justify-center items-center fixed bottom-1 left-0 right-0  text-sm'>
        <p className='flex flex-col md:flex-row items-center gap-1'>
          By signing up, you agree to our
          <a href="https://www.mybranda.com/privacy" className="text-purple-700 font-medium flex justify-end text-sm hover:underline">
            Privacy Policy and Terms of Service
          </a>
        </p>
      </div>
    </div>
  )
}
