import ContactUsPageFrom from '../../molecules/ContactUsPageForm/ContactUsPageForm'
import ContactUsPageInfo from '../../molecules/ContactUsPageInfo/ContactUsPageInfo'

interface Props {
  className?: string
}

export default function ContactUsContent ({ className }: Props) {
  return (
    <div className='relative items-start w-full visible_1'>
      <ContactUsPageInfo/>
      <ContactUsPageFrom className='w-full'/>
    </div>
  )
}
