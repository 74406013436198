/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import React, { useState, useEffect } from 'react'
import Input from '../../atoms/Input/Input'
import CustomLink from '../../atoms/Link/Link'
import { users } from '../../../app/services/Api'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { setCurrentUser } from '../../../app/quiz/quizSlice'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'
import { jwtDecode } from 'jwt-decode'

interface Props {
  className?: string
}

interface jwtPayload {
  user_id: number
  target: string
  needs_sign_in: boolean
}

export default function SignInForm ({ className }: Props) {
  const [password, setPassword] = useState('')
  const [email, setEmail] = useState('')
  const [isDisabled, setIsDisabled] = useState(true)
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [showPassword, setShowPassword] = useState(false)
  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword)
  }
  const currentUser = useAppSelector((state) => state.quizReducer.currentUser)

  const queryParams = new URLSearchParams(location.search)
  const redirectQueryString = queryParams.get('redirect')

  useEffect(() => {
    setIsDisabled(password === '' || email === '')
    redirectIfLoggedIn()
  }, [password, email, currentUser])

  const redirectIfLoggedIn = () => {
    const redirectToken = redirectQueryString ? jwtDecode<jwtPayload>(redirectQueryString ?? '') : null

    if (redirectToken && !redirectToken.needs_sign_in) {
      navigate(redirectToken.target)
      return
    }

    if (currentUser) {
      if (redirectToken && currentUser.id !== redirectToken.user_id) {
        dispatch(setCurrentUser(null))
        return
      }

      const today = new Date()
      const todayDate = today.getFullYear() + '-' + ('0' + (today.getMonth() + 1)).slice(-2) + '-' + ('0' + today.getDate()).slice(-2)

      if (redirectToken) {
        navigate(redirectToken.target)
      } else if (currentUser.subscription.length && currentUser.subscription[0].valid_until < todayDate) {
        navigate('/subscription')
      } else if (currentUser.remind_me_later_sent) {
        navigate('/quiz?create-subscription=1&user=' + currentUser.id)
      } else if (!currentUser.has_finished_steps) {
        navigate('/quiz')
      } else {
        navigate('/all-weekly-goals')
      }
    }
  }

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value)
  }

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value)
  }

  const clearLocalStorage = () => {
    if (typeof localStorage !== 'undefined') {
      window.localStorage.clear()
    } else {
      console.log('localStorage is not available')
    }
  }

  const handleFormSubmit = async (e: React.FormEvent) => {
    e.preventDefault()

    await users.signIn(email, password, true).then((response) => {
      if (response.jwt) {
        clearLocalStorage()
        dispatch(setCurrentUser(response.user))
        toast.success('You have successfully logged in.')
      } else {
        toast.error('Incorrect email or password!')
      }
    })
      .catch(err => {
        if (err.response?.data) {
          toast.error(err.response.data.detail)
        }
      })
  }

  return (
    <div className='sign_in_sign_up visible_1 min-h-screen'>
      <div className="flex flex-col items-center justify-center">
        <div className='flex flex-row w-full justify-between px-8'>
          <CustomLink className='my_branda_logo sm:ml-5 flex self-center font-medium text-2xl md:text-3xl pb-2 mt-5' linkTo='/' name='myBranda' tm={true} />
        </div>
        <form onSubmit={handleFormSubmit}>
          <div className="flex flex-col justify-center items-center ">
            <div className="font-medium text-xl md:text-3xl leading-8 text-gray-900 mt-24">
              <h1>Welcome back to myBranda</h1>
            </div>
            <div className="font-normal font-weight-400 text-sm md:text-base text-gray-600 block md:mt-2">
              <h2>Sign in with your myBranda® login and password</h2>
            </div>
            <div className='flex flex-col gap-3 mt-7 w-full'>
              <Input
                placeholder="E-mail" type="email" onChange={handleEmailChange}
                className="px-2 py-4 h-13 border border-gray-300 rounded-xl bg-white blockw-96 focus:outline-none font-medium text-base text-gray-900"
                value={email}
              />

              <div className="relative flex justify-between w-full">
                <Input
                  placeholder="Password"
                  type={showPassword ? 'text' : 'password'}
                  onChange={handlePasswordChange}
                  className="px-2 py-4 h-13 w-full border border-gray-300 rounded-xl bg-white block focus:outline-none font-medium text-base text-gray-900 pr-10"
                  value={password}
                />
                <div
                  className="absolute inset-y-0 right-0 flex items-center pr-3 cursor-pointer"
                  onClick={togglePasswordVisibility}
                >
                  <FontAwesomeIcon icon={showPassword ? faEye : faEyeSlash} style={{ fontSize: '14px' }} />
                </div>
              </div>

              <button disabled={isDisabled} className='quiz_form_button text-white !w-full !h-11'>Sign In</button>
            </div>
            <a href="/reset-password" className="text-purple-700 font-semibold flex justify-center mt-3 items-center text-sm hover:underline">Forgot Your Password?</a>
          </div>
        </form>
        <div className='flex flex-col justify-center items-center fixed bottom-1 left-0 right-0 text-sm'>
          <p className='flex flex-col md:flex-row items-center gap-1'>By signing up, you agree to our
            <a href="https://www.mybranda.com/privacy" className="text-purple-700 font-medium flex justify-end text-sm hover:underline">
              Privacy Policy and Terms of Service
            </a>
          </p>
        </div>
      </div>
    </div>
  )
}
