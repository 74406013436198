/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/no-unnecessary-type-assertion */
import QuizGoalsPageInfo from '../../molecules/QuizGoalsPageInfo/QuizGoalsPageInfo'
import QuizGoalsPageForm from '../../molecules/QuizGoalsPageForm/QuizGoalsPageForm'
import AskBranda from '../../atoms/Icon/AskBranda'

interface Props {
  className: string
  handleShowPage: (page: string) => void
  handleModalOpen: () => void
}

export default function QuizGoalsPage ({ className, handleShowPage, handleModalOpen }: Props): JSX.Element {
  return (
    <div className={className}>
      <AskBranda handleModalOpen={handleModalOpen} />
      <QuizGoalsPageInfo className='flex flex-col justify-center items-center relative visible_1' />
      <QuizGoalsPageForm handleShowPage={handleShowPage} className='w-auto md:w-full lg:w-[582px] flex flex-wrap justify-center items-center mt-5 relative visible_1' />
    </div>
  )
}
