/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-misused-promises */
import React, { useState } from 'react'
import { TextField, Button, TextareaAutosize, FormControl, InputLabel, Select, MenuItem, type SelectChangeEvent } from '@mui/material'
import BackButton from 'components/atoms/BackButton/BackButton'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { setProfessionPage } from 'app/quiz/adminSlice'
import { toast } from 'react-toastify'
import { professions } from 'app/services/Api'

interface EditProfessionProps {
  existingProfession: Profession
  onEditProfession: (editedProfession: Profession) => void
}

interface Profession {
  id: number
  name: string
}

export default function EditProfession ({ existingProfession, onEditProfession }: EditProfessionProps) {
  const dispatch = useAppDispatch()
  const professionPage = useAppSelector(state => state.adminFaq.professionPage)
  const [editedProfession, setEditedProfession] = useState<Profession>({ ...existingProfession })
  const [backPage, setBackPage] = useState('')

  const handleBackPage = (page: string) => {
    dispatch(setProfessionPage(page))

    if (professionPage === 'profession-admin-edit') {
      setBackPage(page)
    }
    window.scrollTo(0, 0)
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    setEditedProfession((prev) => ({ ...prev, [name]: value }))
  }

  const handleEditProfession = async () => {
    try {
      const response = await professions.update(editedProfession.id, editedProfession, true)
      onEditProfession(response)
      toast.success('Profession successfully saved!')
    } catch (error) {
      console.error('Error editing Profession:', error)
    }
    dispatch(setProfessionPage('profession-admin'))
  }

  return (
    <div className="flex items-center justify-start ">
      <div className="md:w-2/3 bg-white rounded-3xl p-8">
        <BackButton
          backPage={backPage}
          handleBackPage={() => { handleBackPage('profession-admin') }}
          text="Back"
          className="quiz_back_button block mb-8"
        />
        <div className="my-2 mb-10 text-xl font-medium">Edit Profession</div>
        <form>
          <div className="mb-4">
            <TextField
              label="Name"
              type="text"
              name="name"
              value={editedProfession.name}
              onChange={handleInputChange}
              fullWidth
            />
          </div>
          <div className="text-center mt-8">
            <Button
              className="w-40 bg-fuchsia-800 text-white font-bold py-2 px-4 rounded"
              type="button"
              variant="outlined"
              onClick={handleEditProfession}
            >
              Save Changes
            </Button>
          </div>
        </form>
      </div>
    </div>
  )
}
