/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable */
import React, { useEffect, useRef, useState } from 'react'
import { quiz, users } from '../../../app/services/Api'
import QuizSecondStepGenieForm from './QuizSecondStepGenieForm'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { FormLabel, Radio, RadioGroup, FormControlLabel } from '@mui/material'
import QuizSecondStepGenieTitle from '../QuizSecondStepTitle/QuizSecondStepGenieTitle'
import { setQuestionQ11, setQuestionQ21, setQuestionQ22, setQuestionQ23, setQuestionQ24, setQuestionQ25, setQuestionQ26, setQuestionQ27, setQuestionQ28, setQuestionQ29, setQuestionQ210, setQuestionQ211, setQuestionQ212, setSection } from '../../../app/quiz/quizSlice'

interface Props {
  className: string
  handleShowPage: (page: string) => void
}
export default function QuizSecondStepForm ({ className, handleShowPage }: Props) {
  const dispatch = useAppDispatch()
  const preSignedupUserId = useAppSelector(state => state.quizReducer.userPreSignupId)
  const currentUser = useAppSelector(state => state.quizReducer.currentUser)
  const Q1_1 = useAppSelector(state => state.quizReducer.Q1_1)
  const Q2_1 = useAppSelector(state => state.quizReducer.Q2_1)
  const Q2_2 = useAppSelector(state => state.quizReducer.Q2_2)
  const Q2_3 = useAppSelector(state => state.quizReducer.Q2_3)
  const Q2_4 = useAppSelector(state => state.quizReducer.Q2_4)
  const Q2_5 = useAppSelector(state => state.quizReducer.Q2_5)
  const Q2_6 = useAppSelector(state => state.quizReducer.Q2_6)
  const Q2_7 = useAppSelector(state => state.quizReducer.Q2_7)
  const Q2_8 = useAppSelector(state => state.quizReducer.Q2_8)
  const Q2_9 = useAppSelector(state => state.quizReducer.Q2_9)
  const Q2_10 = useAppSelector(state => state.quizReducer.Q2_10)
  const Q2_11 = useAppSelector(state => state.quizReducer.Q2_11)
  const Q2_12 = useAppSelector(state => state.quizReducer.Q2_12)
  const genie = useAppSelector(state => state.quizReducer.Genie)
  const [isDisabled, setIsDisabled] = useState(false)

  useEffect(() => {
    if (Q2_1 === '' || Q2_2 === '' || Q2_3 === '' || Q2_4 === '' || Q2_5 === '' ||
    Q2_6 === '' || Q2_7 === '' || Q2_7 === '' || Q2_9 === '' || Q2_10 === '' ||
    Q2_11 === '' || Q2_12 === '' || genie === '') {
      setIsDisabled(true)
    } else {
      setIsDisabled(false)
    }
  }, [Q2_1, Q2_2, Q2_3, Q2_4, Q2_5, Q2_6, Q2_7, Q2_8, Q2_9, Q2_10, Q2_11, Q2_12, genie])


  useEffect(() => {
    if (currentUser?.quiz_step?.Q1_1 !== undefined) {
      dispatch(setQuestionQ11(currentUser?.quiz_step?.Q1_1))
    }
    if (currentUser?.quiz_step?.Q2_1 !== undefined) {
      dispatch(setQuestionQ21(currentUser?.quiz_step?.Q2_1))
    }
    if (currentUser?.quiz_step?.Q2_2 !== undefined) {
      dispatch(setQuestionQ22(currentUser?.quiz_step?.Q2_2))
    }
    if (currentUser?.quiz_step?.Q2_3 !== undefined) {
      dispatch(setQuestionQ23(currentUser?.quiz_step?.Q2_3))
    }
    if (currentUser?.quiz_step?.Q2_4 !== undefined) {
      dispatch(setQuestionQ24(currentUser?.quiz_step?.Q2_4))
    }
    if (currentUser?.quiz_step?.Q2_5 !== undefined) {
      dispatch(setQuestionQ25(currentUser?.quiz_step?.Q2_5))
    }
    if (currentUser?.quiz_step?.Q2_6 !== undefined) {
      dispatch(setQuestionQ26(currentUser?.quiz_step?.Q2_6))
    }
    if (currentUser?.quiz_step?.Q2_7 !== undefined) {
      dispatch(setQuestionQ27(currentUser?.quiz_step?.Q2_7))
    }
    if (currentUser?.quiz_step?.Q2_8 !== undefined) {
      dispatch(setQuestionQ28(currentUser?.quiz_step?.Q2_8))
    }
    if (currentUser?.quiz_step?.Q2_9 !== undefined) {
      dispatch(setQuestionQ29(currentUser?.quiz_step?.Q2_9))
    }
    if (currentUser?.quiz_step?.Q2_10 !== undefined) {
      dispatch(setQuestionQ210(currentUser?.quiz_step?.Q2_10))
    }
    if (currentUser?.quiz_step?.Q2_11 !== undefined) {
      dispatch(setQuestionQ211(currentUser?.quiz_step?.Q2_11))
    }
    if (currentUser?.quiz_step?.Q2_12 !== undefined) {
      dispatch(setQuestionQ212(currentUser?.quiz_step?.Q2_12))
    }
  }, [currentUser])

  const updateUnansweredQuestions = (questionKey: string) => {
    setUnansweredQuestions(prevUnanswered => prevUnanswered.filter(question => !question.hasOwnProperty(questionKey)))
  }

  const handleFirstQuestion = async (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setQuestionQ21(event.target.value))
    updateUnansweredQuestions('Q2_1')
    await users.updateRegisteredUser(currentUser?.id ?? 0, { Q2_1: event.target.value, step_name: 'Step Two' }, true)
  }

  const handleSecondQuestion = async (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setQuestionQ22(event.target.value))
    updateUnansweredQuestions('Q2_2')
    await users.updateRegisteredUser(currentUser?.id ?? 0, { Q2_2: event.target.value, step_name: 'Step Two' }, true)
  }

  const handleThirdQuestion = async (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setQuestionQ23(event.target.value))
    updateUnansweredQuestions('Q2_3')
    await users.updateRegisteredUser(currentUser?.id ?? 0, { Q2_3: event.target.value, step_name: 'Step Two' }, true)
  }
  
  const handleFourthQuestion = async (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setQuestionQ24(event.target.value))
    updateUnansweredQuestions('Q2_4')
    await users.updateRegisteredUser(currentUser?.id ?? 0, { Q2_4: event.target.value, step_name: 'Step Two' }, true)
  }

  const handleFifthQuestion = async (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setQuestionQ25(event.target.value))
    updateUnansweredQuestions('Q2_5')
    await users.updateRegisteredUser(currentUser?.id ?? 0, { Q2_5: event.target.value, step_name: 'Step Two' }, true)
  }

  const handleSixthQuestion = async (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setQuestionQ26(event.target.value))
    updateUnansweredQuestions('Q2_6')
    await users.updateRegisteredUser(currentUser?.id ?? 0, { Q2_6: event.target.value, step_name: 'Step Two' }, true)
  }

  const handleSeventhQuestion = async (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setQuestionQ27(event.target.value))
    updateUnansweredQuestions('Q2_7')
    await users.updateRegisteredUser(currentUser?.id ?? 0, { Q2_7: event.target.value, step_name: 'Step Two' }, true)
  }

  const handleEighthQuestion = async (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setQuestionQ28(event.target.value))
    updateUnansweredQuestions('Q2_8')
    await users.updateRegisteredUser(currentUser?.id ?? 0, { Q2_8: event.target.value, step_name: 'Step Two' }, true)
  }
  
  const handleNinthQuestion = async (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setQuestionQ29(event.target.value))
    updateUnansweredQuestions('Q2_9')
    await users.updateRegisteredUser(currentUser?.id ?? 0, { Q2_9: event.target.value, step_name: 'Step Two' }, true)
  }
  
  const handleTenthQuestion = async (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setQuestionQ210(event.target.value))
    updateUnansweredQuestions('Q2_10')
    await users.updateRegisteredUser(currentUser?.id ?? 0, { Q2_10: event.target.value, step_name: 'Step Two' }, true)
  }
  
  const handleEleventhQuestion = async (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setQuestionQ211(event.target.value))
    updateUnansweredQuestions('Q2_11')
    await users.updateRegisteredUser(currentUser?.id ?? 0, { Q2_11: event.target.value, step_name: 'Step Two' }, true)
  }
  
  const handleTwelfthQuestion = async (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setQuestionQ212(event.target.value))
    updateUnansweredQuestions('Q2_12')
    await users.updateRegisteredUser(currentUser?.id ?? 0, { Q2_12: event.target.value, step_name: 'Step Two' }, true)
  }

  const values: object = {
    Q1_1,
    Q2_1,
    Q2_2,
    Q2_3,
    Q2_4,
    Q2_5,
    Q2_6,
    Q2_7,
    Q2_8,
    Q2_9,
    Q2_10,
    Q2_11,
    Q2_12,
    Genie: genie
  }

  const handleGetQuizSection = async () => {
    let preSignupId = 0
    if (preSignedupUserId === 0) {
      preSignupId = currentUser?.pre_signup_id ?? 0
    } else {
      preSignupId = preSignedupUserId
    }

    await quiz.getQuizSection(values, preSignupId).then((response) => {
      handleShowPage('pageStep3')
      dispatch(setSection(response.score.Section))
    }).catch((error) => {
      console.log(error)
    })
    await users.updateRegisteredUser(currentUser?.id ?? 0, { step_name: 'Step Three' }, true)
  }

  const formRef = useRef<HTMLFormElement | null>(null)
  const [unansweredQuestions, setUnansweredQuestions] = useState<{ [key: string]: string }[]>([])

  const scrollToUnansweredQuestion = () => {
    if (unansweredQuestions.length > 0 && formRef.current) {
      const firstUnansweredQuestionId = `radio-group-${Object.keys(unansweredQuestions[0])[0]}`;
      const firstUnansweredQuestion = formRef.current.querySelector(
        `div[id^="${firstUnansweredQuestionId}"]`
      )

      if (firstUnansweredQuestion) {
        firstUnansweredQuestion.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        })
      }
    }
  }
  
  useEffect(() => {
    // Scroll to the first unanswered question when there are unanswered questions
    scrollToUnansweredQuestion()
  }, [unansweredQuestions])

  
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    const updatedUnanswered = {
      Q2_1: Q2_1,
      Q2_2: Q2_2,
      Q2_3: Q2_3,
      Q2_4: Q2_4,
      Q2_5: Q2_5,
      Q2_6: Q2_6,
      Q2_7: Q2_7,
      Q2_8: Q2_8,
      Q2_9: Q2_9,
      Q2_10: Q2_10,
      Q2_11: Q2_11,
      Q2_12: Q2_12,
      genie: genie
    }

    // Create an array of key-value pairs for unanswered questions
    const unansweredQuestionsArray = Object.entries(updatedUnanswered)
      .filter(([key, value]) => value === '' || value === null)
      .map(([key, value]) => ({ [key]: value }))

    setUnansweredQuestions(unansweredQuestionsArray)
    scrollToUnansweredQuestion()

    if(unansweredQuestionsArray.length === 0) {
      await handleGetQuizSection()
    }
  }

  return (
    <form ref={formRef} id="quizForm" className={className} onSubmit={handleSubmit}>
       <div id='radio-group-Q2_1'  className={`flex border justify-between rounded-md p-4 mx-3 w-full items-center md:items-auto md:w-auto ${unansweredQuestions.some(obj => obj.hasOwnProperty('Q2_1') && obj['Q2_1'] === '' || obj['Q2_1'] === null) ? 'border-red-500' : ''}`}>
        <FormLabel id="radio-buttons-group-label" className='flex items-center text-black text-wrap-balance quiz-four-text w-[300px] lg:w-[400px]'>
          <span className='w-4/5 text-sm md:text-base md:w-full lg:w-4/5 text-black'>When someone senior at work strongly disagree&apos;s with me, I tend to insist on my professional view</span>
        </FormLabel>
        <RadioGroup value={Q2_1} aria-labelledby="radio-buttons-group-label" name="radio-buttons-group" className='test-class'>
          <FormControlLabel value="1" control={<Radio onChange={handleFirstQuestion}/>} label="Very true" />
          <FormControlLabel value="2" control={<Radio onChange={handleFirstQuestion} />} label="Sometimes" />
          <FormControlLabel value="3" control={<Radio onChange={handleFirstQuestion} />} label="Not at all" />
        </RadioGroup>
      </div>
      <div id='radio-group-Q2_2' className={`flex border justify-between rounded-md p-4 mx-3 w-full items-center md:items-auto md:w-auto ${unansweredQuestions.some(obj => obj.hasOwnProperty('Q2_2') && obj['Q2_2'] === '' || obj['Q2_2'] === null) ? 'border-red-500' : ''}`}>
        <FormLabel id="radio-buttons-group-label" className='flex items-center text-black text-wrap-balance quiz-four-text w-[300px] lg:w-[400px]'>
          <span className='w-4/5 text-sm md:text-base md:w-full lg:w-4/5 text-black'>When I don&apos;t get credit for a professional achievement I feel deeply frustrated</span>
        </FormLabel>
        <RadioGroup value={Q2_2} aria-labelledby="radio-buttons-group-label" name="radio-buttons-group" className='test-class'>
          <FormControlLabel value="1" control={<Radio onChange={handleSecondQuestion} />} label="Very true" />
          <FormControlLabel value="2" control={<Radio onChange={handleSecondQuestion}/>} label="Sometimes" />
          <FormControlLabel value="3" control={<Radio onChange={handleSecondQuestion}/>} label="Not at all" />
        </RadioGroup>
      </div>
      <div id='radio-group-Q2_3' className={`flex border justify-between rounded-md p-4 mx-3 w-full items-center md:items-auto md:w-auto ${unansweredQuestions.some(obj => obj.hasOwnProperty('Q2_3') && obj['Q2_3'] === '' || obj['Q2_3'] === null) ? 'border-red-500' : ''}`}>
        <FormLabel id="radio-buttons-group-label" className='flex items-center text-black text-wrap-balance quiz-four-text w-[300px] lg:w-[400px]'>
          <span className='w-4/5 text-sm md:text-base md:w-full lg:w-4/5 text-black'>I excel at setting goals and crushing them</span>
        </FormLabel>
        <RadioGroup value={Q2_3} aria-labelledby="radio-buttons-group-label" name="radio-buttons-group" className='test-class'>
          <FormControlLabel value="1" control={<Radio onChange={handleThirdQuestion} />} label="Very true" />
          <FormControlLabel value="2" control={<Radio onChange={handleThirdQuestion}/>} label="Sometimes" />
          <FormControlLabel value="3" control={<Radio onChange={handleThirdQuestion}/>} label="Not at all" />
        </RadioGroup>
      </div>
      <div id='radio-group-Q2_4' className={`flex border justify-between rounded-md p-4 mx-3 w-full items-center md:items-auto md:w-auto ${unansweredQuestions.some(obj => obj.hasOwnProperty('Q2_4') && obj['Q2_4'] === '' || obj['Q2_4'] === null) ? 'border-red-500' : ''}`}>
        <FormLabel id="radio-buttons-group-label" className='flex items-center text-black text-wrap-balance quiz-four-text w-[300px] lg:w-[400px]'>
          <span className='w-4/5 text-sm md:text-base md:w-full lg:w-4/5 text-black'>I can spend hours learning something new</span>
        </FormLabel>
        <RadioGroup value={Q2_4} aria-labelledby="radio-buttons-group-label" name="radio-buttons-group" className='test-class'>
          <FormControlLabel value="1" control={<Radio onChange={handleFourthQuestion} />} label="Very true" />
          <FormControlLabel value="2" control={<Radio onChange={handleFourthQuestion}/>} label="Sometimes" />
          <FormControlLabel value="3" control={<Radio onChange={handleFourthQuestion}/>} label="Not at all" />
        </RadioGroup>
      </div>
      <div id='radio-group-Q2_5' className={`flex border justify-between rounded-md p-4 mx-3 w-full items-center md:items-auto md:w-auto ${unansweredQuestions.some(obj => obj.hasOwnProperty('Q2_5') && obj['Q2_5'] === '' || obj['Q2_5'] === null) ? 'border-red-500' : ''}`}>
        <FormLabel id="radio-buttons-group-label" className='flex items-center text-black text-wrap-balance quiz-four-text w-[300px] lg:w-[400px]'>
          <span className='w-4/5 text-sm md:text-base md:w-full lg:w-4/5 text-black'>I like to have an in-depth view on things</span>
        </FormLabel>
        <RadioGroup value={Q2_5} aria-labelledby="radio-buttons-group-label" name="radio-buttons-group" className='test-class'>
          <FormControlLabel value="1" control={<Radio onChange={handleFifthQuestion} />} label="Very true"/>
          <FormControlLabel value="2" control={<Radio onChange={handleFifthQuestion} />} label="Sometimes" />
          <FormControlLabel value="3" control={<Radio onChange={handleFifthQuestion} />} label="Not at all" />
        </RadioGroup>
      </div>
      <div id='radio-group-Q2_6' className={`flex border justify-between rounded-md p-4 mx-3 w-full items-center md:items-auto md:w-auto ${unansweredQuestions.some(obj => obj.hasOwnProperty('Q2_6') && obj['Q2_6'] === '' || obj['Q2_6'] === null) ? 'border-red-500' : ''}`}>
        <FormLabel id="radio-buttons-group-label" className='flex items-center text-black text-wrap-balance quiz-four-text w-[300px] lg:w-[400px]'>
          <span className='w-4/5 text-sm md:text-base md:w-full lg:w-4/5 text-black'>I highly enjoy solving riddles</span>
        </FormLabel>
        <RadioGroup value={Q2_6} aria-labelledby="radio-buttons-group-label" name="radio-buttons-group" className='test-class'>
          <FormControlLabel value="1" control={<Radio onChange={handleSixthQuestion} />} label="Very true" />
          <FormControlLabel value="2" control={<Radio onChange={handleSixthQuestion} />} label="Sometimes" />
          <FormControlLabel value="3" control={<Radio onChange={handleSixthQuestion} />} label="Not at all" />
        </RadioGroup>
      </div>
      <div id='radio-group-Q2_7' className={`flex border justify-between rounded-md p-4 mx-3 w-full items-center md:items-auto md:w-auto ${unansweredQuestions.some(obj => obj.hasOwnProperty('Q2_7') && obj['Q2_7'] === '' || obj['Q2_7'] === null)  ? 'border-red-500' : ''}`}>
        <FormLabel id="radio-buttons-group-label" className='flex items-center text-black text-wrap-balance quiz-four-text w-[300px] lg:w-[400px]'>
          <span className='w-4/5 text-sm md:text-base md:w-full lg:w-4/5 text-black'>I genuinely like talking with people, even people I don&apos;t know</span>
        </FormLabel>
        <RadioGroup value={Q2_7} aria-labelledby="radio-buttons-group-label" name="radio-buttons-group" className='test-class'>
          <FormControlLabel value="1" control={<Radio onChange={handleSeventhQuestion} />} label="Very true" />
          <FormControlLabel value="2" control={<Radio onChange={handleSeventhQuestion} />} label="Sometimes" />
          <FormControlLabel value="3" control={<Radio onChange={handleSeventhQuestion} />} label="Not at all" />
        </RadioGroup>
      </div>
      <div id='radio-group-Q2_8' className={`flex border justify-between rounded-md p-4 mx-3 w-full items-center md:items-auto md:w-auto ${unansweredQuestions.some(obj => obj.hasOwnProperty('Q2_8') && obj['Q2_8'] === '' || obj['Q2_8'] === null) ? 'border-red-500' : ''}`}>
        <FormLabel id="radio-buttons-group-label" className='flex items-center text-black text-wrap-balance quiz-four-text w-[300px] lg:w-[400px]'>
          <span className='w-4/5 text-sm md:text-base md:w-full lg:w-4/5 text-black'>I care a lot about what other people think about me</span>
        </FormLabel>
        <RadioGroup value={Q2_8} aria-labelledby="radio-buttons-group-label" name="radio-buttons-group" className='test-class'>
          <FormControlLabel value="1" control={<Radio onChange={handleEighthQuestion} />} label="Very true" />
          <FormControlLabel value="2" control={<Radio onChange={handleEighthQuestion} />} label="Sometimes" />
          <FormControlLabel value="3" control={<Radio onChange={handleEighthQuestion} />} label="Not at all" />
        </RadioGroup>
      </div>
      <div id='radio-group-Q2_9' className={`flex border justify-between rounded-md p-4 mx-3 w-full items-center md:items-auto md:w-auto ${unansweredQuestions.some(obj => obj.hasOwnProperty('Q2_9') && obj['Q2_9'] === '' || obj['Q2_9'] === null) ? 'border-red-500' : ''}`}>
        <FormLabel id="radio-buttons-group-label" className='flex items-center text-black text-wrap-balance quiz-four-text w-[300px] lg:w-[400px]'>
          <span className='w-4/5 text-sm md:text-base md:w-full lg:w-4/5 text-black'>I have at least 4 close friends I regularly share intimate details with</span>
        </FormLabel>
        <RadioGroup value={Q2_9} aria-labelledby="radio-buttons-group-label" name="radio-buttons-group" className='test-class'>
          <FormControlLabel value="1" control={<Radio onChange={handleNinthQuestion} />} label="Very true" />
          <FormControlLabel value="2" control={<Radio onChange={handleNinthQuestion} />} label="Sometimes" />
          <FormControlLabel value="3" control={<Radio onChange={handleNinthQuestion} />} label="Not at all" />
        </RadioGroup>
      </div>
      <div id='radio-group-Q2_10' className={`flex border justify-between rounded-md p-4 mx-3 w-full items-center md:items-auto md:w-auto ${unansweredQuestions.some(obj => obj.hasOwnProperty('Q2_10') && obj['Q2_10'] === '' || obj['Q2_10'] === null) ? 'border-red-500' : ''}`}>
        <FormLabel id="radio-buttons-group-label" className='flex items-center text-black text-wrap-balance quiz-four-text w-[300px] lg:w-[400px]'>
          <span className='w-4/5 text-sm md:text-base md:w-full lg:w-4/5 text-black'>I believe I&apos;m in charge of my own destiny</span>
        </FormLabel>
        <RadioGroup value={Q2_10} aria-labelledby="radio-buttons-group-label" name="radio-buttons-group">
          <FormControlLabel value="1" control={<Radio onChange={handleTenthQuestion} />} label="Very true" />
          <FormControlLabel value="2" control={<Radio onChange={handleTenthQuestion} />} label="Sometimes" />
          <FormControlLabel value="3" control={<Radio onChange={handleTenthQuestion} />} label="Not at all" />
        </RadioGroup>
      </div>
      <div id='radio-group-Q2_11' className={`flex border justify-between rounded-md p-4 mx-3 w-full items-center md:items-auto md:w-auto ${unansweredQuestions.some(obj => obj.hasOwnProperty('Q2_11') && obj['Q2_11'] === '' || obj['Q2_11'] === null) ? 'border-red-500' : ''}`}>
        <FormLabel id="radio-buttons-group-label" className='flex items-center text-black text-wrap-balance quiz-four-text w-[300px] lg:w-[400px]'>
          <span className='w-4/5 text-sm md:text-base md:w-full lg:w-4/5 text-black'>I seek or aspire to managerial roles</span>
        </FormLabel>
        <RadioGroup value={Q2_11} aria-labelledby="radio-buttons-group-label" name="radio-buttons-group">
          <FormControlLabel value="1" control={<Radio onChange={handleEleventhQuestion} />} label="Very true" />
          <FormControlLabel value="2" control={<Radio onChange={handleEleventhQuestion} />} label="Sometimes" />
          <FormControlLabel value="3" control={<Radio onChange={handleEleventhQuestion} />} label="Not at all" />
        </RadioGroup>
      </div>
      <div id='radio-group-Q2_12' className={`flex border justify-between rounded-md p-4 mx-3 w-full items-center md:items-auto md:w-auto ${unansweredQuestions.some(obj => obj.hasOwnProperty('Q2_12') && obj['Q2_12'] === '' || obj['Q2_12'] === null) ? 'border-red-500' : ''}`}>
        <FormLabel id="radio-buttons-group-label" className='flex items-center text-black text-wrap-balance quiz-four-text w-[300px] lg:w-[400px]'>
          <span className='w-4/5 text-sm md:text-base md:w-full lg:w-4/5 text-black'>I usually find myself instructing others how to do things</span>
        </FormLabel>
        <RadioGroup value={Q2_12} aria-labelledby="radio-buttons-group-label" name="radio-buttons-group">
          <FormControlLabel value="1" control={<Radio onChange={handleTwelfthQuestion} />} label="Very true" />
          <FormControlLabel value="2" control={<Radio onChange={handleTwelfthQuestion} />} label="Sometimes" />
          <FormControlLabel value="3" control={<Radio onChange={handleTwelfthQuestion} />} label="Not at all" />
        </RadioGroup>
      </div>
      <QuizSecondStepGenieTitle className='flex flex-col justify-center items-center gap-5'/>
      <QuizSecondStepGenieForm className='flex w-full lg:w-auto' isAnswered={`${unansweredQuestions.some(obj => obj.hasOwnProperty('genie') && obj['genie'] === '' || obj['genie'] === null) ? 'border-red-500' : ''}`} handleClick={() => updateUnansweredQuestions('genie')} />
      <button className="quiz_form_button text-white rounded-lg px-9 py-2.5 text-center mt-10 mb-7 h-16">
        Next
      </button>
    </form>
  )
}
