/* eslint-disable */
import { type ApiClient } from '../ApiClient'

export class Quiz {
  private readonly api: ApiClient

  constructor (api: ApiClient) {
    this.api = api
  }
  
  async getQuizSection (
    values: object,
    pre_sign_up_user: number
  ): Promise<any> {
    return await this.api.post<object>('quiz_scores/calculate_score/', {
      values,
      pre_sign_up_user
    })
  }
  
  async getQuizBranda (
    values: object,
    pre_sign_up_user: number
  ): Promise<any> {
    return await this.api.post<object>('quiz_scores/calculate_score/', {
      values,
      pre_sign_up_user
    })
  }
}