/* eslint-disable */
import { type ApiClient } from '../ApiClient'

export class Auth {
  private readonly api: ApiClient

  constructor (api: ApiClient) {
    this.api = api
  }

  async resetPassword (email: string): Promise<any> {
    return await this.api.post<object>('auth/users/reset_password/', { email })
  }

  async resetPasswordConfirm (
    uid: string | undefined,
    token: string | undefined,
    new_password: string,
    re_new_password: string
  ): Promise<any> {
    return await this.api.post<object>('auth/users/reset_password_confirm/', {
      uid,
      token,
      new_password,
      re_new_password
    })
  }

  async unsubscribeFromEmails (email: string, notification_type: string ): Promise<any> {
    return await this.api.post<object>('manage_subscriptions/', { email, notification_type })
  }
}
