import Header from '../../atoms/Heading/Heading'
import { Instagram, Facebook, LinkedIn } from '@mui/icons-material/'
import React, { useState } from 'react'

export default function ProfileConnect () {
  const [isComingSoon] = useState(true)
  return (
    <div className='flex flex-col justify-start items-start md:justify-start md:items-start w-full gap-3'>
      <Header className='font-semibold text-2xl leading-7 block mb-3 md:mb-5 text-gray-700' text='Coming soon...' />
      <div className='flex flex-col items-start gap-2 mb-2'>
        <div className='flex items-center w-full'>
          <Facebook style={{ color: '#1877F2' }} />
          <label className='font-light text-black text-sm ml-2'>
            Facebook
          </label>
        </div>
        <div className='ml-6'>
          <div className='group relative'>
            <button
              type="submit"
              className={`flex items-center rounded-lg text-xs w-48 h-8 hover:bg-gray-400 ${isComingSoon
                ? 'pointer-events-none cursor-not-allowed bg-gray-300 text-black'
                : 'bg-black text-white'
                }`}
              disabled={isComingSoon}
            >
              <Facebook className='ml-2 mr-2' />
              Connect to Facebook
            </button>
            {isComingSoon && (
              <div className='absolute left-1/2 transform -translate-x-1/2 top-full sm:translate-x-0 sm:translate-y-1/2 md:left-full md:top-1/2 md:transform md:-translate-y-1/2 bg-gray-800 text-white text-xs py-1 px-2 mx-2 w-24 rounded-md opacity-0 group-hover:opacity-100 transition-opacity duration-300'>
                Coming Soon
              </div>
            )}
          </div>
        </div>
      </div>
      <div className='flex flex-col items-start gap-2 mb-2'>
        <div className='flex items-center w-full'>
          <Instagram style={{ color: 'rgb(206, 53, 134)' }} />
          <label className='font-light text-black text-sm ml-2'>
            Instagram
          </label>
        </div>
        <div className='ml-6'>
          <div className='group relative'>
            <button
              type="submit"
              className={`flex items-center rounded-lg text-xs w-48 h-8 ${isComingSoon
                ? 'pointer-events-none cursor-not-allowed bg-gray-300 text-black'
                : 'bg-black text-white'
                }`}
              disabled={isComingSoon}
            >
              <Instagram className='ml-2 mr-2' />
              Connect to Instagram
            </button>
            {isComingSoon && (
              <div className='absolute left-1/2 transform -translate-x-1/2 top-full sm:translate-x-0 sm:translate-y-1/2 md:left-full md:top-1/2 md:transform md:-translate-y-1/2 bg-gray-800 text-white text-xs py-1 px-2 mx-2 w-24 rounded-md opacity-0 group-hover:opacity-100 transition-opacity duration-300'>
                Coming Soon
              </div>
            )}
          </div>
        </div>
      </div>
      <div className='flex flex-col items-start gap-2 mb-2'>
        <div className='flex items-center w-full'>
          <LinkedIn style={{ color: '#0077B5' }} />
          <label className='font-light text-black text-sm ml-2'>
            Linkedin
          </label>
        </div>
        <div className='ml-6'>
          <div className='group relative'>
            <button
              type="submit"
              className={`flex items-center rounded-lg text-xs w-48 h-8 ${isComingSoon
                ? 'pointer-events-none cursor-not-allowed bg-gray-300 text-black'
                : 'bg-black text-white'
                }`}
              disabled={isComingSoon}
            >
              <LinkedIn className='ml-2 mr-2' />
              Connect to Linkedin
            </button>
            {isComingSoon && (
              <div className='absolute left-1/2 transform -translate-x-1/2 top-full sm:translate-x-0 sm:translate-y-1/2 md:left-full md:top-1/2 md:transform md:-translate-y-1/2 bg-gray-800 text-white text-xs py-1 px-2 mx-2 w-24 rounded-md opacity-0 group-hover:opacity-100 transition-opacity duration-300'>
                Coming Soon
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
