/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import FooterLinks from '../../molecules/FooterLinks/FooterLinks'
import FooterIcons from '../../molecules/FooterIcons/FooterIcons'

interface Props {
  className: string
}
export default function Footer ({ className }: Props) {
  return (
    <div className={className}>
      <FooterLinks className="flex justify-left flex-col md:flex-row items-center md:justify-center items-end gap-3 md:gap-5 ml-0 md:ml-5" />
      <FooterIcons className="flex flex-row justify-center items-center gap-8 md:gap-5 mt-2 md:mt-0 md:mr-5" />
    </div>
  )
}
