import { createSlice, type PayloadAction } from '@reduxjs/toolkit'

export interface UserState {
  faqPage: string
  taskPage: string
  professionPage: string
}

const initialState: UserState = {
  faqPage: 'faq-admin',
  taskPage: 'task-admin',
  professionPage: 'profession-admin'
}

export const adminSlice = createSlice({
  name: 'adminSlice',
  initialState,
  reducers: {
    setFaqPage: (state, action: PayloadAction<string>) => {
      state.faqPage = action.payload
    },
    setTaskPage: (state, action: PayloadAction<string>) => {
      state.taskPage = action.payload
    },
    setProfessionPage: (state, action: PayloadAction<string>) => {
      state.professionPage = action.payload
    }
  }
})

export const {
  setFaqPage, setTaskPage, setProfessionPage
} = adminSlice.actions

export default adminSlice.reducer
