/* eslint-disable */
import { type ApiClient } from '../ApiClient'

export class ContactUs {
  private readonly api: ApiClient

  constructor (api: ApiClient) {
    this.api = api
  }

  async contactUs (title: string, full_name: string, message: string, email: string): Promise<any> {
    return await this.api.post<object>('contact_us/', { title, full_name, message, email })
  }
}  
