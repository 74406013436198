/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable multiline-ternary */

import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Button from 'components/atoms/Button/Button'
import CustomLink from '../../atoms/Link/Link'
import { useAppDispatch, useAppSelector } from 'components/../app/hooks'
import { users } from 'app/services/Api'
import { setCurrentUser } from 'app/quiz/quizSlice'
import { toast } from 'react-toastify'
import { AiOutlineMenu, AiOutlineClose } from 'react-icons/ai'

interface Props {
  className: string
  titleClassName?: string
  transparentBg?: boolean
}

export default function NavBar ({ className, transparentBg }: Props): JSX.Element {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [isScreenWidthAbove768, setIsScreenWidthAbove768] = useState(false)
  const [menuOpen, setMenuOpen] = useState(false)
  const currentUser = useAppSelector((state) => state.quizReducer.currentUser)

  const updateScreenWidth = (): void => {
    if (window.innerWidth > 768) {
      setIsScreenWidthAbove768(true)
      setMenuOpen(false)
    } else {
      setIsScreenWidthAbove768(false)
    }
  }

  useEffect(() => {
    updateScreenWidth()
    window.addEventListener('resize', updateScreenWidth)

    return () => {
      window.removeEventListener('resize', updateScreenWidth)
    }
  }, [])

  const toggleMenu = (): void => { setMenuOpen(!menuOpen) }

  const handleLogOut = async (): Promise<void> => {
    await users
      .logOut(true)
      .then(() => {
        dispatch(setCurrentUser(null))
        navigate('/')
        toast.success('Successfully logged out!')
      })
      .catch((err) => {
        console.log(err)
      })
  }

  return (
    <nav className={`flex justify-between items-center ${className}`}>
      {/* Logo on the left */}
      <div className='flex items-center'>
      <CustomLink
        className={`my_branda_logo sm:ml-5 flex self-center font-medium text-2xl md:text-3xl pb-2 md:pb-3 text-${(transparentBg === true) ? 'black' : 'white'}`}
        linkTo={(currentUser != null) ? '/dashboard' : '/'}
        name="myBranda"
        tm={true}
      />
      </div>
      {/* Hamburger menu button for smaller screens */}
      <button
        className="md:hidden text-black text-3xl"
        onClick={toggleMenu}
      >
        {menuOpen ? <AiOutlineClose /> : <AiOutlineMenu />}
      </button>

      {/* Menu for larger screens or toggle menu on small screens */}
      <div
        className={`${
          isScreenWidthAbove768 || menuOpen ? 'flex' : 'hidden'
        } flex-col md:flex-row items-center gap-2 absolute md:static bg-black bg-opacity-75 md:bg-transparent w-full md:w-auto z-50 top-24 md:top-0 left-0 md:left-auto p-5 md:p-0`}
      >
        <Link to="/about-us" className={`nav-link ${isScreenWidthAbove768 ? 'text-black' : 'text-white'} hover:shadow-md hover:bg-white rounded-lg hover:border-white p-3`}>
          About Us
        </Link>
        <Link to="/subscription-home" className={`nav-link ${isScreenWidthAbove768 ? 'text-black' : 'text-white'} hover:shadow-md hover:bg-white rounded-lg hover:border-white p-3`}>
          Our plans
        </Link>
        <Link to="/contact-us" className={`nav-link ${isScreenWidthAbove768 ? 'text-black' : 'text-white'} hover:shadow-md hover:bg-white rounded-lg hover:border-white p-3`}>
          Contact us
        </Link>

        {currentUser == null ? (
          <>
            {<Link to="/contact-us?message=Register to try out myBranda">
              <Button
                text="Sign In"
                className="sign_in_button border hover:shadow-md hover:bg-white hover:text-black border-white !w-20 md:!w-44 md:text-base flex p-2.5 md:p-3 justify-center items-center gap-3 rounded-lg text-white"
              />
            </Link>}
          </>
        ) : (
          <button
            onClick={handleLogOut}
            className="sign_in_button border hover:shadow-md hover:bg-white hover:text-black border-white text-xs !w-20 md:!w-44 md:text-base flex p-3 justify-center items-center gap-3 rounded-lg text-white"
          >
            Log Out
          </button>
        )}
      </div>
    </nav>
  )
}
