/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useEffect, useState } from 'react'
import { ReactComponent as QuizExpertiseImg } from '../../../img/expertise.svg'
import { setExpertiseKeywords } from '../../../app/quiz/quizSlice'
import { Chip, CircularProgress, TextField } from '@mui/material'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { users } from 'app/services/Api'
import { toast } from 'react-toastify'
import LoadingButton from '@mui/lab/LoadingButton'
import AskBranda from '../../atoms/Icon/AskBranda'

interface Props {
  className: string
  handleShowPage: (page: string) => void
  handleModalOpen: () => void
}

export default function QuizExpertise ({ className, handleShowPage, handleModalOpen }: Props): JSX.Element {
  const dispatch = useAppDispatch()
  const [isDisabled, setIsDisabled] = useState(true)
  const [loading, setLoading] = useState(false)
  const [inputValue, setInputValue] = useState<string>('')
  const [keywords, setKeywords] = useState<string[]>([])
  const [isTextFieldDisabled, setIsTextFieldDisabled] = useState(false)
  const [isRequestProccessed, setIsRequestProccessed] = useState(false)
  const [isInputEmpty, setIsInputEmpty] = useState(true)

  const currentUser = useAppSelector(state => state.quizReducer.currentUser)
  const userFbWeeklyPosts = useAppSelector((state) => state.quizReducer.userFbWeeklyPosts)
  const userIgWeeklyPosts = useAppSelector((state) => state.quizReducer.userIgWeeklyPosts)
  const userLnWeeklyPosts = useAppSelector((state) => state.quizReducer.userLnWeeklyPosts)
  const userTwWeeklyPosts = useAppSelector((state) => state.quizReducer.userTwWeeklyPosts)
  const userSocialPosts = useAppSelector((state) => state.quizReducer.userSocialPosts)
  const country = useAppSelector((state) => state.quizReducer.country)
  const userFbPost = useAppSelector(state => state.quizReducer.userFbPost)
  const userIgPost = useAppSelector(state => state.quizReducer.userIgPost)
  const expertiseKeywords = useAppSelector(state => state.quizReducer.expertise_keywords)

  useEffect(() => {
    setIsInputEmpty(inputValue.trim() === '')
  }, [inputValue])

  useEffect(() => {
    setIsDisabled(keywords.length === 0)
    setIsTextFieldDisabled(keywords.length === 5)
  }, [keywords])

  useEffect(() => {
    dispatch(setExpertiseKeywords(keywords.join(', ')))
  }, [keywords, dispatch])

  const handleButtonClick = (): void => {
    addKeyword()
  }

  const addKeyword = (): void => {
    const trimmedValue = inputValue.trim()
    if (trimmedValue.length > 0 && keywords.length < 5) {
      const words = trimmedValue.split(',')
      const invalidWord = words.find(word => {
        const spaceCount = word.trim().split(' ').length - 1
        return spaceCount > 2
      })

      if (invalidWord != null) {
        toast.error('Please write one, two or a three-words phrase and press Enter ⏎.', {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 5000,
          closeOnClick: true
        })
        return
      }

      const newKeywords = words.map(word => word.trim())
      const remainingSpace = 5 - keywords.length

      if (newKeywords.length <= remainingSpace) {
        setKeywords([...keywords, ...newKeywords.slice(0, remainingSpace)])
        setInputValue('')
      } else {
        toast.error(`You can only add up to ${remainingSpace} keywords at a time.`)
      }
    } else if (keywords.length >= 5) {
      toast.error('You can only add up to 5 keywords.', {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 5000,
        closeOnClick: true
      })
      setIsTextFieldDisabled(true)
    }
  }

  const registerUser = async () => {
    const weekly_fb_posts_number = userFbWeeklyPosts === '' ? null : userFbWeeklyPosts
    const weekly_ig_posts_number = userIgWeeklyPosts === '' ? null : userIgWeeklyPosts
    const weekly_ln_posts_number = userLnWeeklyPosts === '' ? null : userLnWeeklyPosts
    const weekly_tw_posts_number = userTwWeeklyPosts === '' ? null : userTwWeeklyPosts
    const social_media_usage = userSocialPosts
    const fb_posts = userFbPost
    const ig_posts = userIgPost
    const expertise_keywords = expertiseKeywords

    await users.updateRegisteredUser(currentUser?.id ?? 0, {
      country,
      weekly_fb_posts_number,
      weekly_ig_posts_number,
      weekly_ln_posts_number,
      weekly_tw_posts_number,
      social_media_usage,
      fb_posts,
      ig_posts,
      expertise_keywords,
      step_name: 'Step One'
    }, true).then((response) => {
      setIsRequestProccessed(true)
    }).catch((error) => {
      console.log(error)
      setIsRequestProccessed(false)
      toast.error('Failed to change page. Please try again!')
    })

    handleShowPage('pageStep1')
  }

  const handleChipDelete = (chipToDelete: string) => () => {
    setKeywords((chips) => chips.filter((chip) => chip !== chipToDelete))
  }

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    setLoading(true)
    await registerUser()
  }
  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      addKeyword()
    }
  }

  return (
    <div className={className}>
       <div className='flex justify-end w-full custom-quiz-margin pt-5 pr-5' >
         <AskBranda handleModalOpen={handleModalOpen} />
        </div>
      <div className="flex flex-col justify-center relative items-center gap-3 visible_1 mt-12 md:mt-0">
        <QuizExpertiseImg />
        <h1 className="text-xl md:text-2xl font-medium mt-10  visible_1">What&apos;s Your Expertise?</h1>
        <p className="hidden md:inline-block text-sm md:text-base px-5 py-2 md:p-0 text-center mb-4 text-gray-400 visible_1">
          Choose <strong>up to 5 </strong> specific keywords which describe your expertise. <br /> <strong> Be accurate!</strong> Your content suggestions will be based on them.
        </p>
        <p className="md:hidden text-sm md:text-base px-5 py-2 md:p-0 text-center mb-2 text-gray-400 visible_1">
          Choose <strong>up to 5 </strong> specific keywords which describe your expertise.<strong> Be accurate!</strong> Your content suggestions will be based on them.
        </p>
        <div className="flex flex-col visible_1">
          <div className="flex items-center justify-start h-fit rounded-lg">
            <p className="text-black px-2 md:px-3 md:py-4 mb-4 md:mb-0">I&apos;m specializing in:</p>
          </div>
          <div className="flex flex-col">
            <TextField
              label="Add keywords here"
              variant="outlined"
              value={inputValue}
              className='w-60'
              onKeyDown={(e) => { handleKeyDown(e) }}

              onChange={(e) => { setInputValue(e.target.value) }}
              disabled={isTextFieldDisabled}
              InputProps={{
                style: {
                  borderRadius: '8px'
                },
                endAdornment: (
                  <button
                    type="button"
                    className={`rounded-lg text-sm hover:bg-purple-100 w-16 h-8 border-2 border-purple-300 ${isInputEmpty ? '' : 'bg-purple-200'}`}
                    onClick={handleButtonClick}
                  >
                    Enter
                  </button>
                )
              }}
            />
            <span className='text-center text-xs mt-2'>Type a keyword and press enter ⏎.</span>
          </div>
        </div>
        <div className="flex flex-wrap justify-center md:justify-start mt-4">
          {keywords.map((keyword, index) => (
            <div key={index} className="m-1">
              <Chip label={keyword} onDelete={handleChipDelete(keyword)} />
            </div>
          ))}
        </div>
        <LoadingButton
          onClick={handleSubmit}
          loading={loading}
          loadingIndicator={<CircularProgress color="inherit" size={28} />}
          disabled={isDisabled}
          color='inherit'
          className='quiz_form_button !capitalize !text-white !font-medium !rounded-lg !text-lg px-9 py-2.5 text-center !mt-2 mr-2 h-14'
        >
          {!loading && !isRequestProccessed ? 'Next' : ''}
        </LoadingButton>
      </div>
    </div>
  )
}
