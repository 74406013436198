// import Image from '../../atoms/Image/Image'
import { ReactComponent as QuizFinishPage } from '../../../img/finish-quiz-image.svg'
import Header from '../../atoms/Heading/Heading'

interface Props {
  className: string
}

export default function BrandaResultPage2Info ({ className }: Props) {
  return (
    <div className={className}>
      <div className="flex justify-center items-center">
        <QuizFinishPage />
      </div>
      <div className='flex flex-col justify-center items-center gap-3'>
        <Header className='text-xl font-medium text-center text-white  mt-5 md:text-2xl w-72 md:w-auto' text='Your Personalized Plan Is Ready!' />
      </div>
      <div className='flex items-center justify-center'>
        <div className='finish-quiz-middle-box flex flex-col justify-center items-center gap-3 mt-5 w-64 md:w-80 p-6 rounded-lg text-base font-medium text-center text-white'>
          <Header className='text-md font-normal text-white' text='Start skyrocketing your personal brand, and win more clients!' />
          <Header className='text-xs font-normal w-60 tracking-widest' text="Our AI  branding platform just built you a personalized branding plan, including social media content suggestions tailored to your personality, industry and goals" />
        </div>
      </div>
    </div>
  )
}
