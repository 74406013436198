import { configureStore } from '@reduxjs/toolkit'
import quizReducer from './quiz/quizSlice'
import userSocialMediaReducer from './quiz/userSocialMedia'
import adminFaq from './quiz/adminSlice'

export const store = configureStore({
  reducer: {
    quizReducer,
    userSocialMediaReducer,
    adminFaq
  }
})

export type RootState = ReturnType<typeof store.getState>

export type AppDispatch = typeof store.dispatch
