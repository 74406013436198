/* eslint-disable */
import { type ApiClient } from '../ApiClient'

export class Notifications {
  private readonly api: ApiClient

  constructor (api: ApiClient) {
    this.api = api
  }

  async notifyUser (email: string, template_type: boolean): Promise<any> {
    return await this.api.post<object>('notifications/', { email, template_type })
  }

  async createLeadNotifications (email: string, paypro_product_id: number): Promise<any> {
    return await this.api.post<object>('lead_notification/', { email, paypro_product_id })
  }
}
