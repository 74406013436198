/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import { useAppSelector } from 'app/hooks'
import Heading from 'components/atoms/Heading/Heading'
import React, { useEffect, useRef, useState } from 'react'
import AOS from 'aos'
import 'aos/dist/aos.css'

interface Props {
  className?: string
}

export default function BrandaUserLevels ({ className }: Props) {
  const currentUser = useAppSelector(state => state.quizReducer.currentUser)
  const [highlightedLevel, setHighlightedLevel] = useState<number | null>(null)
  const highlightedLevelNameRef = useRef<HTMLSpanElement>(null)
  const containerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    AOS.init({ duration: 1600 })
  }, [])

  useEffect(() => {
    if (currentUser?.level !== undefined) {
      setHighlightedLevel(currentUser.level)
    }
  }, [currentUser])

  // useEffect(() => {
  //   function handleScroll () {
  //     if (containerRef.current) {
  //       // const rect = containerRef.current.getBoundingClientRect()
  //       // const viewportHeight = window.innerHeight || document.documentElement.clientHeight

  //       // // Calculate whether the top and bottom of the container are within the viewport
  //       // const containerTopInView = rect.top >= 0 && rect.top <= viewportHeight
  //       // const containerBottomInView = rect.bottom >= 0 && rect.bottom <= viewportHeight

  //       // // Trigger animation when either the top or bottom of the container is within the viewport
  //       // if (containerTopInView || containerBottomInView) {
  //       //   containerRef.current.classList.add('animate-class')
  //       //   setTimeout(() => {
  //       //     if (testLevel?.current !== null) {
  //       //       testLevel.current.scrollLeft += 100
  //       //       testLevel.current.scrollIntoView({ behavior: 'smooth', inline: 'center' })
  //       //     }
  //       //   }, 1200)
  //       }
  //       // else {
  //       // Remove animation class when the container is out of the viewport
  //       // containerRef.current.classList.remove('animate-class')
  //       // }
  //     }
  //   }

  //   // Attach scroll event listener
  //   window.addEventListener('scroll', handleScroll)
  //   // Detach scroll event listener when component is unmounted
  //   return () => {
  //     window.removeEventListener('scroll', handleScroll)
  //   }
  // }, [])

  function getLevelName (level: number): string {
    switch (level) {
      case 0:
        return 'Beginner'
      case 1:
        return 'Climber'
      case 2:
        return 'Progressive'
      case 3:
        return 'Skilled'
      case 4:
        return 'Wizard'
      case 5:
        return 'Advanced'
      case 6:
        return 'Guru'
      case 7:
        return 'Dominator'
      case 8:
        return 'Royalty'
      case 9:
        return 'Legendary'
      case 10:
        return 'Exceptional'
      default:
        return 'Unknown'
    }
  }

  function getLevelText (level: number): string {
    switch (level) {
      case 0:
        return "You're just starting off your Brand Building journey 💪🏻"
      case 1:
        return 'You`re in a good place in the middle 👍'
      case 2:
        return 'You`re slightly above the average ⬆️'
      case 3:
        return 'Looks like you`ve done some Brand Work here 🙂'
      case 4:
        return 'You have some Brand Magic in you; let`s make it grow ❇️'
      case 5:
        return 'You perform WAY better than your industry 🤫'
      case 6:
        return 'Your activity is impressive 😮'
      case 7:
        return 'On the right path to becoming a Thought Leader 💪🏻'
      case 8:
        return 'Your activity is off the charts 👑'
      case 9:
        return 'Looks like you`re starting your own Brand Legacy! 💖'
      case 10:
        return 'Sure you need us? 😅'
      default:
        return ''
    }
  }

  return (
    <div className={className} ref={containerRef}>
      <div className='bg-white rounded-2xl p-4 overflow-x-scroll whitespace-nowrap no-scrollbar'>
        <div className="flex items-start items-center justify-start md:justify-center w-full mt-5 mb-4 md:mb-8 gap-3 relative scroll-container">
          <Heading className='main_header_home font-manrope text-sm md:text-lg font-bold md:font-semibold' text={'Your Brand Level➔'} />
          {[0, 1, 2, 3, 4, 5, 6, 7, 8].map((level, index) => (
            <div key={level} className="relative scroll-item" data-aos="fade-right" data-aos-delay={index * 200}>
              {/* Highlighted level name */}
              {highlightedLevel !== null && level === highlightedLevel &&
                <span className="absolute top-0 left-1/2 transform -translate-x-1/2 -mt-6 text-xs text-center text-purple-700 font-bold">YOU</span>
              }
              {/* Level indicator */}
              <span ref={level === highlightedLevel ? highlightedLevelNameRef : null} className={`text-xs font-medium border-2 border-slate-200 rounded-md p-2 ${highlightedLevel !== null && level === highlightedLevel ? 'text-white !font-semibold brand_me_up_and_quiz_button border animate-me-test' : level <= (highlightedLevel ?? 0) ? 'bg-gradient-to-r from-purple-100 to-purple-100' : 'bg-gray-100 text-gray-400'}`}>
                {getLevelName(level)}
              </span>
              {/* Connector line */}
              {level < 8 && (
                <span className="absolute top-1/2 left-full transform -translate-y-1/2">
                  <span className={`h-0.5 w-4 ${level <= (highlightedLevel ?? 0) ? 'bg-gradient-to-r from-purple-100 to-purple-100' : 'bg-gray-300'} rounded-full inline-block after:block after:w-1 after:h-1 after:bg-gray-300 after:rounded-full after:absolute after:left-full after:transform after:-translate-y-1/2 after:content-['']`}></span>
                </span>
              )}
            </div>
          ))}
        </div>
        {[0, 1, 2, 3, 4, 5, 6, 7, 8].map((level, index) => (
          <div key={level} className='flex flex justify-start' data-aos="fade-right" >
            <p className="text-start text-xs font-manrope text-gray-400 mb-0.5 md:mb-0  md:mt-0.5 md:ml-14">{highlightedLevel === level ? getLevelText(level) : ''}</p>
          </div>
        ))}
      </div>
    </div>
  )
}
