import React from 'react'
import Header from '../../atoms/Heading/Heading'

interface Props {
  className: string
}

export default function QuizThirdStepTitleOne ({ className }: Props) {
  return (
    <div className={className}>
      <Header className='hidden md:block text-lg font-medium mt-4' text='Choose the most accurate option'/>
      <Header className='block md:hidden text-lg font-medium mt-4 text-center' text='Choose the most<br> accurate option'/>
      <Header className='text-md md:text-base font-bold md:font-medium' text='I am <b>mostly</b>' />
    </div>
  )
}
