import { type ChangeEvent } from 'react'
import { setEmail } from '../../../app/quiz/quizSlice'
import { useAppDispatch } from '../../../app/hooks'

interface Props {
  type: string
  placeholder: string
  className: string
  setInput?: (value: string) => void
  email?: boolean
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void
  value?: string
}

export default function Input ({ type, placeholder, className, setInput, email, onChange }: Props) {
  const dispatch = useAppDispatch()

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    (email ?? false) ? dispatch(setEmail(e.target.value)) : setInput?.(e.target.value)
    if (onChange !== undefined && onChange !== null) {
      onChange(e)
    }
  }
  return (
    <input onChange={handleInputChange} className={className} type={type} placeholder={placeholder} />
  )
}
