import SubscriptionPageInfo from '../../molecules/SubscriptionPageInfo/SubscriptionPageInfo'
import FaqItemContent from '../../organisms/FaqItemContent/FaqItemContent'

export default function Subscription () {
  return (
    <div className="w-full justify-center relative items-center visible_1 basis-9/12">
      <SubscriptionPageInfo />
      <div className='mt-4'>
      <FaqItemContent filterData='Subscription' hideCategoryTitle={true} designSubscription={true} />
      </div>
    </div>
  )
}
