import React from 'react'
import BrandaThankYouPageInfo3 from '../../molecules/BrandaThankYouPage/BrandaThankYouPageInfo3'
import BrandaThankYouPageInfo1 from '../../molecules/BrandaThankYouPage/BrandaThankYouPageInfo1'
import BrandaThankYouPageInfo2 from '../../molecules/BrandaThankYouPage/BrandaThankYouPageInfo2'

interface Props {
  className: string
  handleShowPage: (page: string) => void
}

export default function BrandaThankYouPage ({ className, handleShowPage }: Props) {
  return (
    <div className={className}>
      <BrandaThankYouPageInfo1 className=''/>
      <BrandaThankYouPageInfo2 className=''/>
      <BrandaThankYouPageInfo3 className=''/>
    </div>
  )
}
