import React from 'react'
import Footer from 'components/organisms/Footer/Footer'
import Headers from 'components/molecules/Headers/Headers'
import NavBar from 'components/organisms/NavBar/NavBar'
import ScrollTopOnReload from 'app/services/ScrollTopOnReload'

interface Props {
  className: string
}

export default function Home ({ className }: Props) {
  return (
    <div className={className}>
      <ScrollTopOnReload />
      <div className="fixed w-full pb-10 z-10">
        <NavBar transparentBg={true} className='relative flex flex-row justify-between items-center p-5 pt-3 h-24 align-center -mb-5 sm:-mb-2' />
      </div>
      <Headers className='flex justify-end md:justify-center flex-col align-center home-page relative z-1' />
      <Footer className='flex flex-col items-start md:items-center md:flex-row justify-between w-full min-h-20 p-3 pt-8 md:p-3 -mt-2 bg-white' />
    </div>
  )
}
