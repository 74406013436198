/* eslint-disable */
import axios, { type AxiosResponse, AxiosError } from 'axios'

interface ApiResponse<T> {
  data: T
}

export class ApiClient {
  private readonly BASE_URL: string

  constructor (baseURL: string) {
    this.BASE_URL = baseURL
  }

  private async request<T>(
    method: string,
    url: string,
    data?: object,
    credentials?: boolean
  ): Promise<any> {
    try {
      const response: AxiosResponse<ApiResponse<T>> = await this.requestRaw(method, url, data, credentials)
      return response.data
    } catch (err) {
      if (axios.isAxiosError(err)) {
        const axiosError = err as AxiosError;

        if (axiosError.response && axiosError.response.status === 422) {
          window.location.href = '/subscription';
          return
        }

        throw err;
      }
    }
  }

  private async requestRaw<T>(
    method: string,
    url: string,
    data?: object,
    credentials?: boolean
  ): Promise<any> {

    try {
      return axios({
        method,
        url: `${this.BASE_URL}${url}`,
        data,
        withCredentials: credentials
      });
    } catch (err) {
      if (axios.isAxiosError(err)) {
        const axiosError = err as AxiosError;

        if (axiosError.response && axiosError.response.status === 422) {
          window.location.href = '/subscription';
          return
        }
      }

      throw err;
    }
  }

  async get<T>(url: string, credentials?: boolean): Promise<any> {
    return await this.request<T>('get', url, undefined, credentials)
  }

  async getWithParameters<T>(url: string, data?: object, credentials?: boolean): Promise<any> {
    return await this.request<T>('get', url, data, credentials)
  }

  async post<T>(url: string, data?: object, credentials?: boolean): Promise<any> {
    return await this.request<T>('post', url, data, credentials)
  }

  async postRaw<T>(url: string, data?: object, credentials?: boolean): Promise<any> {
    return await this.requestRaw<T>('post', url, data, credentials)
  }

  async put<T>(url: string, data?: object, credentials?: boolean): Promise<any> {
    return await this.request<T>('put', url, data, credentials)
  }

  async patch<T>(url: string, data?: object, credentials?: boolean): Promise<any> {
    return await this.request<T>('patch', url, data, credentials)
  }

  async delete<T>(url: string, data: object, credentials?: boolean): Promise<any> {
    return await this.request<T>('delete', url, data, credentials)
  }
}
