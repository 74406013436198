import WeeklyGoalsFrom from '../../molecules/WeeklyGoalsForm/WeeklyGoalsForm'

interface Props {
  className?: string
}

export default function WeeklyGoalsContent ({ className }: Props) {
  return (
    <div className="relative md:mx-auto w-full visible_1">
      <WeeklyGoalsFrom/>
    </div>
  )
}
