/* eslint-disable @typescript-eslint/no-misused-promises */
import React, { useEffect, useState } from 'react'
import QuizBirthdatePageInfo from '../../molecules/QuizBirthdatePageInfo/QuizBirthdatePageInfo'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import dayjs from 'dayjs'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { setBirthdate } from '../../../app/quiz/quizSlice'
import { users } from 'app/services/Api'

interface Props {
  className: string
  handleShowPage: (page: string) => void
}

const minDate = dayjs('1920-01-01')
const maxDate = dayjs('2005-12-31')

export default function QuizBirthdatePage ({ className, handleShowPage }: Props) {
  const [value, setValue] = React.useState<dayjs.Dayjs | null>(null)
  const [isDisabled, setIsDisabled] = React.useState(true)
  const [invalidDate, setInvalidDate] = useState('')
  const dispatch = useAppDispatch()
  const currentUser = useAppSelector(state => state.quizReducer.currentUser)
  const birthdate = useAppSelector(state => state.quizReducer.birthdate)

  useEffect(() => {
    if (value === null || !dayjs(value, 'YYYY-MM-DD', true).isValid() || maxDate.isBefore(value)) {
      if (maxDate.isBefore(value)) {
        setInvalidDate('Sorry you must be over 18 to take this quiz 😔')
      }
      setIsDisabled(true)
    } else {
      setInvalidDate('')
      const userBirthday = value.format('YYYY-MM-DD').toString()
      dispatch(setBirthdate(userBirthday))
      setIsDisabled(false)
    }
  }, [value])

  useEffect(() => {
    if (currentUser?.birthdate !== null && currentUser?.birthdate !== undefined) {
      dispatch(setBirthdate(currentUser?.birthdate))
      setValue(dayjs(currentUser?.birthdate))
    }
  }, [currentUser])

  const handleUpdateUser = async (e: React.FormEvent) => {
    e.preventDefault()

    await users.updateRegisteredUser(currentUser?.id ?? 0, {
      birthdate,
      step_name: 'Profession'
    }, true)

    handleShowPage('pageSeven')
  }

  const handleDateChange = async (newValue: dayjs.Dayjs | null) => {
    setValue(newValue)

    const updatedBirthdate = newValue?.format('YYYY-MM-DD').toString()
    await users.updateRegisteredUser(currentUser?.id ?? 0, {
      birthdate: updatedBirthdate
    }, true)
  }

  return (
    <div className={className}>
      <QuizBirthdatePageInfo className='flex flex-col relative visible_1 justify-center items-center gap-5' />
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <span className='text-sm text-red-600'>{invalidDate}</span>
        <DatePicker className='w-1/2 md:w-72 visible_1'
          value={value}
          onChange={handleDateChange}
          minDate={minDate}
          maxDate={maxDate}
        />
      </LocalizationProvider>
      <button
        disabled={isDisabled}
        onClick={handleUpdateUser}
        className='quiz_form_button text-white rounded-lg px-9 py-2.5 text-center visible_1 relative mt-4 md:h-16'
      >Next</button>
    </div>
  )
}
