import React from 'react'
import QuizProfessionPageInfo from '../../molecules/QuizProfessionPageInfo/QuizProfessionPageInfo'
import QuizProfessionPageForm from '../../molecules/QuizProfessionPageForm/QuizProfessionPageForm'

interface Props {
  className: string
  handleShowPage: (page: string) => void
}

export default function QuizProfessionPage ({ className, handleShowPage }: Props) {
  return (
    <div className={className}>
      <QuizProfessionPageInfo className='flex flex-col visible_1 relative justify-center items-center gap-2 mt-10'/>
      <QuizProfessionPageForm handleShowPage={handleShowPage} className='flex flex-col justify-center items-center w-full relative visible_1'/>
     </div>
  )
}
