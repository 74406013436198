/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/no-misused-promises */
import React, { useState, useEffect } from 'react'
import Input from '../../atoms/Input/Input'
import Header from '../../atoms/Heading/Heading'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { setPassword, setConfirmPassword, setCurrentUser } from '../../../app/quiz/quizSlice'
import { users } from '../../../app/services/Api'
import { toast } from 'react-toastify'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'

interface Props {
  className: string
  handleShowPage: (name: string) => void
}

const validatePasswordFormat = (password: string): boolean => {
  // Password must have at least 1 uppercase letter and at least 1 number or symbol
  return /^(?=.*[A-Z])(?=.*\d).{8,}$/.test(password)
}

export default function QuizPasswordPage ({ className, handleShowPage }: Props) {
  const [touched, setTouched] = useState({ password: false, confirmPassword: false })
  const [passwordMatch, setPasswordMatch] = useState(true)
  const [isDisabled, setIsDisabled] = useState(true)
  const dispatch = useAppDispatch()
  const password = useAppSelector((state) => state.quizReducer.password)
  const confirmPassword = useAppSelector((state) => state.quizReducer.confirmPassword)
  const [showPassword, setShowPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)

  let email = useAppSelector(state => state.quizReducer.email)
  let first_name = useAppSelector(state => state.quizReducer.first_name)
  let last_name = useAppSelector(state => state.quizReducer.last_name)

  const togglePasswordVisibility = (field: string) => {
    if (field === 'password') {
      setShowPassword((prevShowPassword) => !prevShowPassword)
    } else if (field === 'confirmPassword') {
      setShowConfirmPassword((prevShowConfirmPassword) => !prevShowConfirmPassword)
    }
  }

  useEffect(() => {
    setIsDisabled(password === '' || confirmPassword === '' || !passwordMatch)
  }, [password, confirmPassword, passwordMatch])

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()

    // Client-side validation
    if (!validatePasswordFormat(password)) {
      toast.error(
        <p>The password should contain at least 8 characters, letters and numbers and at least one capital letter</p>,
        {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 5000
        }
      )
      return
    }

    try {
      setIsDisabled(true)
      await users.validatePassword(password)

      if (localStorage.getItem('pre_sign_up_user')) {
        const stringifiedUserFromStorage = JSON.parse(localStorage.getItem('pre_sign_up_user') ?? '')
        email = stringifiedUserFromStorage.email
        first_name = stringifiedUserFromStorage.name
        last_name = stringifiedUserFromStorage.last_name
      }

      await users.registerPartial(email, password, confirmPassword, first_name, last_name).catch((error) => {
        console.log(error)
        toast.error('Failed to change page. Please try again!')
      })

      await users.signIn(email, password, true).then((response) => {
        if (response.jwt) {
          dispatch(setCurrentUser(response.user))
        }
      }).catch(err => {
        toast.error(err)
        setIsDisabled(false)
      })

      handleShowPage('pageFour')
    } catch (error) {
      toast.error(<p>The password should contain at least 8 characters, letters and numbers and at least one capital letter</p>, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 5000
      })

      setIsDisabled(false)
    }
  }

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newPassword = e.target.value
    setTouched((prevTouched) => ({ ...prevTouched, password: true }))
    setPassword(newPassword)
    dispatch(setPassword(newPassword))
    setPasswordMatch(newPassword === confirmPassword)
  }

  const handleConfirmPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newConfirmPassword = e.target.value
    setTouched((prevTouched) => ({ ...prevTouched, confirmPassword: true }))
    setConfirmPassword(newConfirmPassword)
    dispatch(setConfirmPassword(newConfirmPassword))
    setPasswordMatch(password === newConfirmPassword)
  }

  return (
    <div className={className}>
      <form onSubmit={handleSubmit} className="m-auto flex flex-col justify-center relative visible_1 items-center md:w-auto">
        <Header className='text-xl font-medium mt-8 mb-8 flex items-center' text='Enter your new password' />
        <div className="flex gap-3 md:space-x-8 flex-col md:flex-row ml-0">
          <div className="relative flex justify-between w-full">
            <Input
              placeholder="Password"
              type={showPassword ? 'text' : 'password'}
              onChange={handlePasswordChange}
              className="w-64 bg-gray-50 border border-gray-300 text-gray-900 text-base rounded-lg flex-grow h-14 p-2.5"
              value={password}
            />
            <div
              className="absolute inset-y-0 right-0 flex items-center pr-3 cursor-pointer"
              onClick={() => { togglePasswordVisibility('password') }}
            >
              <FontAwesomeIcon icon={showPassword ? faEye : faEyeSlash} style={{ fontSize: '14px' }} />
            </div>
          </div>
          <div className="relative flex justify-between w-full">
            <Input
              placeholder="Confirm Password"
              type={showConfirmPassword ? 'text' : 'password'}
              onChange={handleConfirmPasswordChange}
              className="w-64 bg-gray-50 border border-gray-300 text-gray-900 text-base rounded-lg flex-grow h-14 p-2.5"
              value={confirmPassword}
            />
            <div
              className="absolute inset-y-0 right-0 flex items-center pr-3 cursor-pointer"
              onClick={() => { togglePasswordVisibility('confirmPassword') }}
            >
              <FontAwesomeIcon icon={showConfirmPassword ? faEye : faEyeSlash} style={{ fontSize: '14px' }} />
            </div>
          </div>
        </div>
        {!passwordMatch && touched.password && touched.confirmPassword && (
          <p className="text-red-500 text-sm mt-8">Passwords do not match.</p>
        )}
        <button disabled={isDisabled} className='quiz_form_button text-white rounded-lg px-9 py-2.5 text-center relative mt-10 md:h-16'>
          Next
        </button>
        <p className='mt-5 w-11/12 md:w-7/12 rounded-lg border-2 border-purple-200 p-2 text-center' style={{ fontSize: window.innerWidth < 768 ? '0.5rem' : '0.7rem' }}>The password should contain at least 8 characters, letters and numbers and at least one capital letter</p>
      </form>
    </div>
  )
}
