import React from 'react'
import Header from '../../atoms/Heading/Heading'

interface Props {
  className: string
}

export default function QuizSecondStepTitle ({ className }: Props) {
  return (
    <div className={className}>
      <Header className='text-base md:text-2xl font-medium text-center' text='Choose the extent to which </br> the following sentences describe you'/>
      <Header className='text-sm font-normal' text='One option per row' />
    </div>
  )
}
