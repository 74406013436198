import Header from '../../atoms/Heading/Heading'

interface Props {
  className: string
}

export default function QuizSecondStepGenieTitle ({ className }: Props) {
  return (
    <div className={className}>
      <Header className='text-base text-center mt-10 mb-5 font-medium w-full md:w-4/5' text='If a genie granted you one wish, what would you choose from the below options?'/>
    </div>
  )
}
