/* eslint-disable */
import { type ApiClient } from '../ApiClient'

export class Brandas {
  private readonly api: ApiClient

  constructor (api: ApiClient) {
    this.api = api
  }

  async  getBrandaForUser (id?: number): Promise<any> {
    return await this.api.get<object>(`brandas/${id}/`)
  }
  
  async getBranda (name: string): Promise<any> {
    return await this.api.get<object>(`brandas/?name=${name}`)
  }
} 