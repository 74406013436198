import Header from '../../atoms/Heading/Heading'

interface Props {
  className: string
}

export default function QuizThirdStepTitleThree ({ className }: Props) {
  return (
    <div className={className}>
      <Header className='text-md md:text-base font-bold md:font-medium mb-2 md:mb-4' text='I am <b>deeply</b> frustrated when'/>
    </div>
  )
}
