import QuizFirstStepForm from '../../molecules/QuizFirstStepForm/QuizFirstStepForm'
import QuizFirstStepTitle from '../../molecules/QuizFirstStepTitle/QuizFirstStepTitle'
import Steps from '../../molecules/Steps/Steps'

interface Props {
  className: string
  handleShowPage: (page: string) => void
}

export default function QuizFirstStep ({ className, handleShowPage }: Props) {
  return (
    <div className={className}>
      <Steps currentPage='pageStep1' />
      <QuizFirstStepTitle className='flex flex-col relative visible_1 justify-center items-center gap-5' />
      <QuizFirstStepForm handleShowPage={handleShowPage} className='flex flex-col relative visible_1 justify-center items-center gap-3 mt-5 mx-3 md:mx-auto' />
    </div>
  )
}
