import * as React from 'react'
import CircularProgress, { type CircularProgressProps } from '@mui/material/CircularProgress'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { useAppDispatch } from '../../../app/hooks'
import { useEffect, useState } from 'react'

function CircularProgressWithLabel (
  props: CircularProgressProps & { value: number }
) {
  const [isWidthMobile, setIsWidthMobile] = useState(false)

  const updateScreenWidth = () => {
    if (window.innerWidth < 475) {
      setIsWidthMobile(true)
    } else {
      setIsWidthMobile(false)
    }
  }

  useEffect(() => {
    updateScreenWidth()
    window.addEventListener('resize', updateScreenWidth)

    return () => {
      window.removeEventListener('resize', updateScreenWidth)
    }
  }, [])

  return (
    <div className='w-full flex flex-col justify-center items-center'>
      <div className='flex justify-center'>
        <Box className='relative flex flex-col'>
          <CircularProgress color='secondary' style={{ color: 'rgba(133, 96, 216, 1)' }} variant="determinate" {...props} className='!w-40 !h-40' />
          <Box className='p-5 absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center'>
            <Typography
              variant="caption"
              component="div"
              color="text.primary"
              fontSize='20pt'
            >{`${Math.round(props.value)}%`}</Typography>
          </Box>
        </Box>
      </div>
      <div className="flex justify-center scroller mt-10 relative overflow-hidden">
        <span className='absolute top-10 text-sm md:text-lg text-center font-normal'>
          Remember, your Branda is <b>NOT</b> you!<br />
          {isWidthMobile ? <span>It’s an aspired Brand Image <br/> helping you to tell your story</span> : 'It’s an aspired Brand Image helping you to tell your story' } <br />{isWidthMobile ? <span>and become more memorable <br/> and consistent online 💜</span> : 'and become more memorable and consistent online 💜' }
        </span>
      </div>
    </div>
  )
}

export default function CircularWithValueLabel () {
  const [progress, setProgress] = useState(10)
  const dispatch = useAppDispatch()
  const [, setIsScreenWidthAbove768] = useState(false)

  const updateScreenWidth = () => {
    if (window.innerWidth > 768) {
      setIsScreenWidthAbove768(true)
    } else {
      setIsScreenWidthAbove768(false)
    }
  }

  useEffect(() => {
    updateScreenWidth()
    window.addEventListener('resize', updateScreenWidth)

    return () => {
      window.removeEventListener('resize', updateScreenWidth)
    }
  }, [])

  useEffect(() => {
    const intervalTime = window.innerWidth > 768 ? 1200 : 1600
    const timer = setInterval(() => {
      setProgress((prevProgress) => (prevProgress >= 100 ? 0 : prevProgress + 10))
    }, intervalTime)

    return () => {
      clearInterval(timer)
    }
  }, [dispatch])

  return <CircularProgressWithLabel value={progress} />
}
