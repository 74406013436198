/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import React, { useState } from 'react'
import { toast } from 'react-toastify'

interface Props {
  isOpen: boolean
  onClose: () => void
  onConfirm: (reason: string) => void
}

export default function TaskAbandonedModal ({ isOpen, onClose, onConfirm }: Props) {
  const [selectedReason, setSelectedReason] = useState('')
  const [otherReason, setOtherReason] = useState('')

  const handleConfirm = () => {
    if (selectedReason) {
      onConfirm(selectedReason)
      onClose()
      toast.success('We always try to improve, and we really appreciate your feedback!', { autoClose: 3000 })
    }
  }

  return (
    <div
      className={`fixed z-40 top-0 left-0 w-full h-full flex items-center justify-center ${isOpen ? '' : 'hidden'
        }`}
    >
      <div className="absolute w-full h-full bg-gray-800 opacity-50"></div>
      <div className=" mx-3 relative p-4 bg-white rounded-3xl shadow dark:bg-gray-700 p-5 md:p-6">
        <div className="flex items-center justify-between w-full mb-4">
          <span className="text-xl" role="img" aria-label="Bell icon">
          </span>
          <button
            onClick={onClose}
            type="button"
            className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 inline-flex justify-center items-center"
            data-modal-hide="static-modal"
          >
            <svg
              className="w-3 h-3"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 14 14"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
              />
            </svg>
            <span className="sr-only">Close modal</span>
          </button>
        </div>
        <div className="items-start">
          <p className="font-medium text-base md:text-xl text-black  md:p-2  mb-4">
          Why did you choose to drop this task?
          </p>
        </div>
        <div className="flex flex-col mb-4 md:ml-3">
          <label className="inline-flex items-center mb-2 text-sm md:text-base">
            <input
              type="radio"
              className="form-radio h-4 w-4 md:h-5 md:w-5 text-gray-600"
              value="I already posted something similar"
              checked={selectedReason === 'I already posted something similar'}
              onChange={() => { setSelectedReason('I already posted something similar') }
              }
            />
            <span className="ml-2">I already posted something similar</span>
          </label>
          <label className="inline-flex items-center mb-2 text-sm md:text-base">
            <input
              type="radio"
              className="form-radio h-4 w-4 md:h-5 md:w-5 text-gray-600"
              value="The task is too personal"
              checked={selectedReason === 'The task is too personal'}
              onChange={() => { setSelectedReason('The task is too personal') }}
            />
            <span className="ml-2">The task is too personal</span>
          </label>
          <label className="inline-flex items-center mb-2 text-sm md:text-base">
            <input
              type="radio"
              className="form-radio h-4 w-4 md:h-5 md:w-5 text-gray-600"
              value="Found it irrelevant to my Brand"
              checked={selectedReason === 'Found it irrelevant to my Brand'}
              onChange={() => { setSelectedReason('Found it irrelevant to my Brand') }}
            />
            <span className="ml-2">Found it irrelevant to my Brand</span>
          </label>
          <label className="inline-flex items-center mb-2 text-sm md:text-base">
            <input
              type="radio"
              className="form-radio h-4 w-4 md:h-5 md:w-5 text-gray-600"
              value="Other"
              checked={selectedReason === 'Other'}
              onChange={() => { setSelectedReason('Other') }}
            />
            <span className="ml-2">Other</span>
          </label>
          {selectedReason === 'Other' && (
            <input
              type="text"
              value={otherReason}
              onChange={(e) => { setOtherReason(e.target.value) }}
              placeholder="Enter other reason"
              className="border border-gray-300 rounded px-3 py-1 mt-2"
            />
          )}
        </div>
        <div className="flex justify-center  mb-4 ">
          <button
            className="px-10 md:px-12 py-2  bg-purple-700 text-xs md:text-base text-white  rounded"
            onClick={handleConfirm}
          >
            Send
          </button>
        </div>
      </div>
    </div>
  )
}
