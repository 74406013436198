import React from 'react'
import Header from '../../atoms/Heading/Heading'
import { useAppSelector } from '../../../app/hooks'

export default function BasicInfo () {
  const currentUser = useAppSelector((state) => state.quizReducer.currentUser)

  return (
    <div className='flex flex-col justify-center items-start md:justify-start md:items-start w-full gap-3'>
      <Header className='font-medium  text-xl md:text-3xl leading-7 text-black block mb-6 mt-7 md:4' text='Your Details' />
      <div className='flex flex-row items-center gap-6 sm:gap-14 mb-2'>
        <label className='font-thin text-slate-400 text-sm w-20'>
          Full Name
        </label>
        <input placeholder="Your Name" type='text' className='text-xs border border-transparent p-2 w-32 h-7' value={`${currentUser?.first_name} ${currentUser?.last_name}`} disabled />
      </div>
      <div className='flex flex-row items-center gap-6 sm:gap-14 mb-2'>
        <label className='font-thin text-slate-400 text-sm w-20'>
        Gender
        </label>
        <input placeholder="Your Gender" type='text' className='text-xs border border-transparent p-2 w-32 h-7' value={currentUser?.gender} disabled />
      </div>
      <div className='flex flex-row items-center gap-6 sm:gap-14 mb-2'>
        <label className='font-thin text-slate-400 text-sm w-20'>
         Birthdate
        </label>
        <input id='dob' type='date' className='text-xs border border-transparent p-2 w-32 h-7' value={currentUser?.birthdate} disabled />
      </div>
      <div className='flex flex-row items-center gap-2 sm:gap-10 mb-2 flex-shrink-0'>
        <label className='font-thin text-slate-400 text-sm w-24'>
          Email address
        </label>
        <input id='email' type='text' className='text-xs md:text-sm bg-slate-50 p-2 w-44 md:w-72 h-7' value={currentUser?.email} disabled />
      </div>
      <div className='flex flex-row items-center gap-6 sm:gap-14 mb-2'>
        <label className='font-thin text-slate-400 text-sm w-20'>
          Profession
        </label>
        <input placeholder="Your Profession" type='text' className='text-xs border border-transparent p-2 w-32 h-7' value={currentUser?.profession} disabled />
      </div>
    </div>
  )
}
