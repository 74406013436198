import React, { useState, useRef } from 'react'
import Heading from '../../atoms/Heading/Heading'
import shareLogo from '../../../img/shareLogo.svg'
import { SocialIcon } from 'react-social-icons'
import { toast } from 'react-toastify'

interface Props {
  network: string
  url: string
  name: string
}

export default function ShareButton () {
  const [isPopupOpen, setPopupOpen] = useState(false)

  const openPopup = () => {
    setPopupOpen(true)
  }

  const closePopup = () => {
    setPopupOpen(false)
  }

  const inputRef = useRef<HTMLInputElement | null>(null)

  const handleCopyClick = () => {
    if (inputRef.current != null) {
      inputRef.current.select()

      try {
        void navigator.clipboard.writeText(inputRef.current.value)
        toast.success('Text copied to clipboard!', {
          position: toast.POSITION.BOTTOM_RIGHT
        })
      } catch (err) {
        console.error('Unable to copy text to clipboard', err)
      }
    }
  }

  const SocialLink = ({ network, url, name }: Props) => (
    <div className="flex flex-col items-center">
      <SocialIcon network={network} url={url} target='_blank' style={{ width: '40px', height: '40px' }}/>
      <span className="text-xs text-gray-400 p-0.5">{name}</span>
    </div>
  )

  return (
    <div>
      <button
        className="flex flex-row bg-gray-100 rounded-lg h-12 w-32  flex items-center mt-5 justify-between p-3"
        onClick={openPopup}
      >
        <img src={shareLogo} alt="share" />
        <Heading className='text-gray-500 font-manrope text-medium font-semibold' text='Share' />
      </button>

      {isPopupOpen && (
        <div className="flex flex-col items-center justify-center fixed z-10 inset-0 bg-black bg-opacity-40">
          <div className="bg-gray-50 p-5 md:p-6 w-10/12 md:w-2/4 lg:w-2/4 xl:w-1/4 rounded-3xl shadow-md">
            <div className='flex flex-row justify-between mb-3'>
              <span
                className="text-2xl text-gray-700 font-semibold"
                onClick={closePopup}
              >
                Share
              </span>
              <button
                className="text-xl font-semibold text-gray-400 w-4"
                onClick={closePopup}
              >
                x
              </button>
            </div>
            <div className='flex flex-col gap-5'>
              <div className='flex flex-row justify-center items-center gap-7 mt-4'>
                <SocialLink
                  network="facebook"
                  url="https://www.facebook.com/sharer/sharer.php?u=https://mybranda.com/&text=Get%20your%20Personalized%20Branding%20Today!"
                  name="Facebook"
                />
                <SocialLink
                  network="x"
                  url="https://twitter.com/intent/tweet?url=https%3A%2F%2Fmybranda.com%2F&text=Automatically build and manage your personal brand. Grow organically online and get noticed by customers with our AI Brand Coach!"
                  name="Twitter"
                />
                <SocialLink
                  network="whatsapp"
                  url="https://api.whatsapp.com/send?url=https%3A%2F%2Fmybranda.com%2F&text=Automatically build and manage your personal brand. Grow organically online and get noticed by customers with our AI Brand Coach.%0A%0Ahttps://mybranda.com/"
                  name="WhatsApp"
                />
                <SocialLink
                  network="linkedin"
                  url="https://www.linkedin.com/shareArticle?mini=true&url=https%3A%2F%2Fmybranda.com%2F&title%3DAutomatically%20build%20and%20manage%20your%20personal%20brand.%20Grow%20organically%20online%20and%20get%20noticed%20by%20customers%20with%20our%20AI%20Brand%20Coach%26summary%3DGrow%20your%20business%20with%20a%20Personal%20Branding%20AI%20Coach&source=mybranda.com"
                  name="LinkedIn"
                />
              </div>
              <div className='flex flex-row gap-5 mt-2'>
                <input type='text' value='https://mybranda.com/' ref={(input) => (inputRef.current = input)} className='bg-white w-72 rounded-lg text-sm p-1.5' />
                <button onClick={handleCopyClick} className='bg-gray-600 text-white w-24 rounded-lg h-8'>Copy</button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
