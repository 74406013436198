/* eslint-disable */

import React, { useEffect, useState } from 'react'
import Heading from '../../atoms/Heading/Heading'
import plusLogo from '../../../img/plusLogo.svg'
import instagram from '../../../img/instagram.png'
import linkedin from '../../../img/linkedin.png'
import facebook from '../../../img/facebook.png'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import SocialsPopUp from './BrandaScorePopUp/SocialsPopUp'

interface Socials {
  name: string
  image: string
  altText: string
  question: string
}

interface SocialsButtonProps {
  network: Socials
  openPopup: (network: Socials) => void
}

const networks: Socials[] = [
  { name: 'Facebook', image: facebook, altText: 'Facebook', question: 'How many Followers/Friends do you have on Facebook?' },
  { name: 'Instagram', image: instagram, altText: 'Instagram', question: 'How many followers do you have on Instagram?' },
  { name: 'Linkedin', image: linkedin, altText: 'Linkedin', question: 'How many connections do you have on Linkedin?' }
]

const SocialsButton: React.FC<SocialsButtonProps> = ({ network, openPopup }) => (
  <button
    className="flex flex-row bg-gray-100 rounded-lg h-12 w-full gap-2 flex items-center p-3"
    onClick={() => openPopup(network)}
  >
    <div className="relative inline-block">
      <img src={network.image} alt={network.altText} className="h-8 block bg-center bg-no-repeat grayscale opacity-70" />
      <img src={plusLogo} className="absolute -top-1 -right-1 h-3.5" />
    </div>
    <Heading className="text-gray-400 font-manrope text-sm font-semibold" text={network.name} />
  </button>
)

interface Props {
  className?: string
  onSubmit: (network: Socials, count: number) => void;
}

export default function ConnectedNetworks({ onSubmit }: Props) {
  const currentUser = useAppSelector(state => state.quizReducer.currentUser)
  const [isPopupOpen, setPopupOpen] = useState(false)
  const [selectedNetwork, setSelectedNetwork] = useState<Socials | null>(null)
  const [networksList, setNetworksList] = useState([...networks])
  const [socialMediaPopUp, setIsSocialMediaPopUp] = useState(false)

  const openPopup = (network: Socials) => {
    setSelectedNetwork(network)
    setIsSocialMediaPopUp(true)
    setPopupOpen(true)
  }

  const closePopup = () => {
    setPopupOpen(false)
  }

  const handlePopupSubmit = async () => {
    setIsSocialMediaPopUp(false)
    if (selectedNetwork) {
      const order = ['facebook', 'instagram', 'linkedin']
      setNetworksList(prevList => {
        const filteredList = prevList.filter(network => network.name !== selectedNetwork.name);
    
        return filteredList.sort((a, b) => {
          const orderIndexA = order.indexOf(a.name.toLowerCase());
          const orderIndexB = order.indexOf(b.name.toLowerCase());
    
          // Sort by custom order
          if (orderIndexA !== orderIndexB) {
            return orderIndexA - orderIndexB;
          }
    
          // If the custom order is the same, use the default sorting
          return a.name.localeCompare(b.name);
        })
      })
    }
  }

  const updateSocials = () => {
    if (currentUser !== null) {
      const networks = ['Facebook', 'Instagram', 'Linkedin']
      return networks
        .filter(networkType => {
          const socialMedia = currentUser?.social_media_scores?.find(socialMedia => socialMedia.platform === networkType)
          return socialMedia === undefined || socialMedia.no_followers === 0 || socialMedia.no_followers === null
        })
        .map(networkType => {
          let question = ''
          if(networkType === ' Facebook') {
            question = 'How many Followers/Friends do you have on FB account'
          } else {
            question = `How many Followers do you have on ${networkType}?`
          }

          let network: Socials = {
            name: networkType,
            image: getImageForNetwork(networkType),
            altText: networkType,
            question
          }
          return network
        })
    }
  
    return []
  }

  const getImageForNetwork = (networkType: string) => {
    switch (networkType) {
      case 'Facebook':
        return facebook
      case 'Instagram':
        return instagram
      case 'Linkedin':
        return linkedin
      default: 
        return 'Facebook'
    }
  }
  
  useEffect(() => {
    if (currentUser !== null) {
      const updatedConnectedNetworksData = updateSocials()
      setNetworksList(updatedConnectedNetworksData)
    }
  }, [currentUser?.social_media_scores])

  return (
    <div>
      {/* <div className="flex flex-col gap-2 mt-4">
        {networksList.length !== 3 && networksList.map((network) => (
          <SocialsButton key={network.name} network={network} openPopup={openPopup} />
        ))}
      </div>  
        {isPopupOpen && selectedNetwork && (
          <SocialsPopUp
            isOpen={socialMediaPopUp}
            onClose={closePopup}
            onConfirm={handlePopupSubmit}
            socialMedia={selectedNetwork.name}
            submitText='Confirm'
            showClose={true}
          />
        )} */}
    </div>
  )
}
