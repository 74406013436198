/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/no-confusing-void-expression */
/* eslint-disable @typescript-eslint/quotes */
import { users } from 'app/services/Api'
import React, { useState } from 'react'
import { toast } from 'react-toastify'

interface Props {
  isOpen: boolean
  onClose: () => void
  onConfirm: () => void
  chosenPlan: string
  chosenPrice: string
}

export default function PlanChangeModal ({ isOpen, onClose, onConfirm, chosenPlan, chosenPrice }: Props) {
  return (
    <div
      className={`fixed z-40 top-0 left-0 w-full h-full flex items-center justify-center ${isOpen ? '' : 'hidden'
        }`}
    >
      <div className="absolute w-full h-full bg-gray-800 opacity-50"></div>
      <div className="bg-white z-50 p-2 md:p-6 shadow-md border-2 border-gray-700 rounded-md">
        <div className="flex flex-col items-center justify-between p-2 md:p-3 rounded-t dark:border-gray-600">
          <button
            onClick={onClose}
            type="button"
            className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
            data-modal-hide="static-modal"
          >
            <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
              <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
            </svg>
            <span className="sr-only">Close modal</span>
          </button>
          <p className="text-center font-medium  text-base  md:text-2xl text-black p-0 mr-5 mb-4">
          Double Checking with you  🔔
          </p>
          <div className="text-center md:text-start">
          <p className="text-xs md:text-base">Just making sure that you would like to purchase a <strong>{chosenPlan}</strong> plan, for <strong> {chosenPrice}</strong>.</p>
          <p className=" text-xs md:text-base">Your <strong>{chosenPlan}</strong> will start immediately after your current subscription is over. <br /> Changes in your payment will be reflected in your next billing cycle</p>
          </div>
        </div>
        <div className="flex justify-center md:justify-end mt-4 mb-4">
          <button className="text-sm md:text-base mr-2 px-2 md:px-4 py-1 md:py-2 bg-blue-500 text-white rounded" onClick={onConfirm}>Confirm</button>
          <button className="text-sm md:text-base px-2 md:px-4 py-1 md:py-2  bg-gray-500 text-white rounded" onClick={onClose}>Cancel</button>
        </div>
      </div>
    </div>
  )
}
