/* eslint-disable */
import { type ApiClient } from '../ApiClient'

export class Tasks {
  private readonly api: ApiClient

  constructor (api: ApiClient) {
    this.api = api
  }

  async getTask (taskId: number): Promise<any> {
    return await this.api.get<object>(`tasks/${taskId}/`)
  }

  async getAllTasks(taskType?: string): Promise<any> {
    const queryParam = taskType ? `?task_type=${taskType}` : '';
    return await this.api.get<object[]>(`tasks/${queryParam}`)
  }

  async create(data: object, credentials?: boolean) {
    return await this.api.post<object>('tasks/', data, credentials)
  }

  async update (id: number, data: object): Promise<any> {
    return await this.api.put<object>(`tasks/${id}/`, data, true)
  }

  async delete (id: number, credentials?: boolean): Promise<any> {
    return await this.api.delete<object>(`tasks/${id}/`, { id }, credentials)
  }

  
}
