import BrandaResultInfo from '../../molecules/BrandaResultInfo/BrandaResultInfo'
import BrandaResultForm from '../../molecules/BrandaResultForm/BrandaResultForm'

interface Props {
  className: string
  handleShowPage: (page: string) => void
}

export default function BrandaResult ({ className, handleShowPage }: Props) {
  return (
    <div className={className}>
      <BrandaResultInfo className=''/>
      <BrandaResultForm className='' handleShowPage={handleShowPage}/>
    </div>
  )
}
