/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import React, { useEffect, useRef, useState } from 'react'
import Image from 'components/atoms/Image/Image'
import Header from 'components/atoms/Heading/Heading'
import { useAppSelector } from 'app/hooks'
import { toast } from 'react-toastify'
import { brandas, preSignUpUser } from 'app/services/Api'
import { type Brandas } from './Branda'

interface Props {
  className: string
}

export default function BrandaResultInfo ({ className }: Props) {
  const email = useAppSelector(state => state.quizReducer.email)
  const branda = useAppSelector(state => state.quizReducer.Branda)
  const gender = useAppSelector(state => state.quizReducer.Gender)

  const [brandaInfoFromDb, setBrandaInfoFromDb] = useState<Brandas>({})
  const [image, setImage] = useState('')

  const componentRef = useRef(null)
  let brandaData: JSX.Element | null = null

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await brandas.getBranda(branda)
        const { name, img, mantra, prime, branda_id, life_goal, tactics } = response[0]
        const brandaDetails: Brandas = {
          img,
          mantra,
          prime,
          brandaId: branda_id,
          lifeGoal: life_goal,
          tactics,
          name
        }

        setBrandaInfoFromDb(brandaDetails)

        const localBrandaId = String(brandaDetails.brandaId)
        const localLifeGoal = String(brandaDetails.lifeGoal)
        const localTactics = String(brandaDetails.tactics)
        const localMantra = String(brandaDetails.mantra)
        const localPrime = String(brandaDetails.prime)
        const brandaLocalName = branda.split(' ').join('_')
        const genderFirstChar = gender.charAt(0).toLocaleLowerCase()
        const quizLocalImage = brandaLocalName + '_' + (['m', 'f'].includes(genderFirstChar) ? genderFirstChar : 'm')
        const imageSource = quizLocalImage + '.png'

        preSignUpUser.notifyUserForBranda(
          email,
          branda,
          localPrime,
          localMantra,
          localLifeGoal,
          localBrandaId,
          localTactics,
          imageSource
        ).then(() => {
          toast.success(<p>Your Branda has been sent to your email &#128516;</p>)
        }).catch((e) => {
          console.log(e)
        })
      } catch (error) {
        console.error(error)
      }
    }
    fetchData()

    const observer = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting) {
        const brandaName = branda.split(' ').join('_')
        const genderFirstChar = gender.charAt(0).toLocaleLowerCase()
        const quizImage = brandaName + '_' + (['m', 'f'].includes(genderFirstChar) ? genderFirstChar : 'm')
        // Dynamically import the image using import()
        import('../../../img/quiz-results/' + quizImage + '.png')
          .then((imageModule) => {
            // Once the image is imported, set it in the state
            setImage(imageModule.default)
          })
          .catch((error) => {
            console.error('Error importing image:', error)
          })
        // Stop observing the component once it's loaded
        observer.disconnect()
      }
    })

    // Start observing the component when it's mounted
    if (componentRef.current) {
      observer.observe(componentRef.current)
    }

    // Clean up the observer when the component unmounts
    return () => {
      if (componentRef.current) {
        observer.unobserve(componentRef.current)
      }
    }
  }, [])

  // Conditionally render the image only when it's available
  const renderImage = image && <Image className='w-68 h-52 md:w-92 md:h-80 mb-10' src={image} />
  const brandaId = String(brandaInfoFromDb.brandaId)
  const lifeGoal = String(brandaInfoFromDb.lifeGoal)
  const tactics = String(brandaInfoFromDb.tactics)
  const mantra = String(brandaInfoFromDb.mantra)
  const prime = String(brandaInfoFromDb.prime)

  if (brandaInfoFromDb !== null) {
    brandaData = (
      <div className="flex flex-col w-full mt-5 p-5">
        <div className='flex flex-col items-center'>
          <p className='text-white text-lg md:text-2xl'>Life Goal:</p>
          <span className='text-white text-center text-base md:text-base w-64 md:w-96'>{lifeGoal}</span>
        </div>
        <div className='flex flex-col items-center mt-10'>
          <p className='text-white text-lg md:text-2xl'>Branda Id:</p>
          <span className='text-white text-center text-base md:text-base w-64 md:w-96'>{brandaId}</span>
        </div>
        <div className='flex flex-col items-center mt-10'>
          <p className='text-white text-lg md:text-2xl mb-1'>Goal-crushing Tactics:</p>
          <span className='text-white text-center text-base md:text-base w-64 md:w-96' dangerouslySetInnerHTML={{ __html: tactics }}></span>
        </div>
      </div>
    )
  }

  return (
    <div className={className} ref={componentRef}>
      <div className='flex flex-col justify-center items-center gap-3'>
        <Header className='text-xl font-normal text-white md:text-3xl' text='Your Branda' />
        <Header className='text-2xl font-light text-white md:text-4xl' text={branda} />
      </div>
      <div className="flex justify-center mt-5">
        {renderImage}
      </div>
      <div className='flex items-center justify-center flex-col'>
        <div className='finish-quiz-middle-box flex flex-col justify-center items-center gap-3 mt-5  p-3 w-64 md:w-full rounded-lg'>
          <div className='flex flex-col items-center'>
            <p className='text-white opacity-70 text-base md:text-lg'>Prime Value:</p>
            <span className='text-white text-md md:text-lg '>{prime}</span>
          </div>
          <div className='flex flex-col items-center mt-5'>
            <p className='text-white opacity-70 text-base md:text-lg'>Mantra:</p>
            <span className='text-white text-md md:text-lg '>{mantra}</span>
          </div>
        </div>
        {brandaData}
      </div>
    </div>
  )
}
