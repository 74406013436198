import FaqItemContent from '../../organisms/FaqItemContent/FaqItemContent'

export default function Support () {
  return (
    <div className="w-full min-h-full justify-center relative items-center visible_1 md:basis-9/12 lg:basis-7/12 xl:basis-8/12">
      <FaqItemContent
        titleClass="text-xl md:text-3xl !font-extrabold !text-center !mt-4"
        title='How Can We Help?'
        isSupport={true}
      />
    </div>
  )
}
