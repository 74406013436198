/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/no-confusing-void-expression */
/* eslint-disable @typescript-eslint/quotes */
import { users } from 'app/services/Api'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'

interface Props {
  isOpen: boolean
  onCloseModal: (() => void) | null
}

export default function EndSubscriptionModal ({ isOpen, onCloseModal }: Props) {
  return (
    <div
      className={`fixed z-40 top-0 left-0 w-full h-full flex items-center justify-center ${isOpen ? '' : 'hidden'
        }`}
    >
      <div className="absolute w-full h-full bg-gray-800 opacity-50"></div>
      <div className="bg-white z-50 p-2 md:p-6 shadow-md border-4 border-purple-600 rounded-lg mx-2 md:mx-0">
        <div className="flex flex-col items-center justify-between p-2 md:p-4  dark:border-gray-600">
          {onCloseModal && (
            <button
              onClick={onCloseModal}
              type="button"
              className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
              data-modal-hide="static-modal"
            >
              <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
              </svg>
              <span className="sr-only">Close modal</span>
            </button>
          )}
          <div className="text-center">
          <p className="font-medium  text-xl  md:text-2xl text-black p-0 mr-5 mb-2">Your free trial has ended 😩</p>
          <p className="font-medium  text-sm md:text-xl text-black p-0 mr-5 mb-6">
            No worries 😉<br/>
            You can keep your Brand empowerment journey <br/>
            with our cost-effective Brand Plans here 👇🏻 and keep growing online! ✨🪴🌟
          </p>
          </div>
            <Link to="/subscription">
              <button className="brand_me_up_and_quiz_button text-white  md:font-medium rounded-lg text-sm md:text-lg px-4 md:px-9 py-1.5 md:py-2.5 text-center">
                Continue my Brand Empowerment 💪🏼
              </button>
            </Link>
        </div>
      </div>
    </div>
  )
}
