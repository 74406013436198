/* eslint-disable @typescript-eslint/no-misused-promises */
import React, { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { setQuestionQ11 } from '../../../app/quiz/quizSlice'
import { FormControl, RadioGroup, FormControlLabel, Radio } from '@mui/material'
import { users } from 'app/services/Api'

interface Props {
  className: string
  handleShowPage: (page: string) => void
}

export default function QuizPageThreeForm ({ className, handleShowPage }: Props) {
  const dispatch = useAppDispatch()
  const Q1_1 = useAppSelector(state => state.quizReducer.Q1_1)
  const currentUser = useAppSelector(state => state.quizReducer.currentUser)
  const [isDisabled, setIsDisabled] = useState(true)
  const [q11Value, setQ11Value] = useState('')

  useEffect(() => {
    if (Q1_1 !== '' || Q1_1 === null) {
      setIsDisabled(false)
    } else {
      setIsDisabled(true)
    }
  }, [Q1_1])

  useEffect(() => {
    if (currentUser?.quiz_step?.step_name === 'Step One' && currentUser?.quiz_step.Q1_1 !== undefined) {
      setQ11Value(currentUser?.quiz_step.Q1_1)
    }
  }, [currentUser])

  const handleCheckboxChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setQuestionQ11(event.target.value))
    setQ11Value(event.target.value)
    await users.updateRegisteredUser(currentUser?.id ?? 0, {
      Q1_1: event.target.value
    }, true)
  }

  const radioOptions = [
    { id: 'ambition', label: 'Ambition', value: '1' },
    { id: 'friendliness', label: 'Friendliness', value: '2' },
    { id: 'curiosity', label: 'Curiosity', value: '3' },
    { id: 'leadership', label: 'Leadership', value: '4' }
  ]

  const handleUpdateUser = async (e: React.FormEvent) => {
    e.preventDefault()

    await users.updateRegisteredUser(currentUser?.id ?? 0, {
      Q1_1,
      step_name: 'Step Two'
    }, true)

    handleShowPage('pageStep2')
  }

  return (
    <div className={className}>
      <div className='flex justify-center w-full'>
          <FormControl component="fieldset" className='w-full md:w-3/4 justify-center'>
          <RadioGroup
            aria-label="quiz"
            name="quiz-radio-group"
            value={Q1_1}
            onChange={handleCheckboxChange}
            className='justify-center w-full lg:!flex-row'
          >
            <div className="flex flex-col basis-5/12">
              {radioOptions.slice(0, 2).map((item) => (
                <div key={item.id} className="border p-2 rounded-md my-1 md:m-2">
                  <FormControlLabel
                    className='!flex !justify-between'
                    value={ item.value }
                    control={<Radio />}
                    label={ item.label }
                    checked={ item.value === q11Value }
                    labelPlacement="start"
                  />
                </div>
              ))}
            </div>
            <div className="flex flex-col basis-5/12">
              {radioOptions.slice(2).map((item) => (
                <div key={item.id} className="border p-2 rounded-md my-1 md:m-2">
                  <FormControlLabel
                    className='!flex !justify-between'
                    value={item.value}
                    control={<Radio />}
                    label={item.label}
                    checked={ item.value === q11Value }
                    labelPlacement="start"
                  />
                </div>
              ))}
            </div>
          </RadioGroup>
        </FormControl>
      </div>
      <button
        onClick={handleUpdateUser}
        className='quiz_form_button text-white rounded-lg px-9 py-2.5 text-center mt-2'
        disabled={isDisabled}
      >
        Next
      </button>
    </div>
  )
}
