import React from 'react'
import { useMediaQuery } from 'react-responsive'

export default function HomeVideo (): JSX.Element {
  const isMobile = useMediaQuery({ query: '(max-width: 728px)' })

  const defaultVideoUrl = 'https://www.youtube.com/embed/-Ms8VXJ4Fek'
  const mobileVideoUrl = 'https://www.youtube.com/embed/gGfOLF9a0pg'

  return (
    <div className="flex flex-col bg-purple-100 rounded-3xl mb-6 p-3 d:p-8">
      <div className="mb-1 items-start p-5 pb-3">
        <h1 className="main_header_home second_header_home text-xl md:text-3xl font-bold mb-4 text-center">
          Discover How Branda will Transform Your Brand!        </h1>
      </div>
      <div className="flex flex-col items-center">
        {isMobile
          ? (
            <div className="relative mb-6 video-iframe overflow-auto">
            <iframe
              className="w-full h-full rounded-3xl border-black border-8"
              src={mobileVideoUrl}
              title="myBranda Product Video"
              allow="autoplay; encrypted-media; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
            )
          : (
            <div className="relative mb-6 video-iframe">
              <iframe
                className="w-full h-full rounded-3xl border-black border-8"
                src={defaultVideoUrl}
                title="myBranda Product Video"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
            )}
        <button className="brand_me_up_and_quiz_button text-lg text-white font-normal py-4 px-16 rounded-2xl">
          <a href="/quiz">
            Build My Brand!
          </a>
        </button>
      </div>
    </div>
  )
}
