export const LANGUAGES = [
  {
    title: 'English US',
    value: 'American English dialect',
    img_value: 'US'
  },
  // {
  //   title: 'Albanian',
  //   value: 'Albanian',
  //   img_value: 'AL'
  // },
  // {
  //   title: 'Dutch',
  //   value: 'Dutch',
  //   img_value: 'NL'
  // },
  {
    title: 'English Australia',
    value: 'Australian English dialect',
    img_value: 'AU'
  },
  {
    title: 'English Canada',
    value: 'Canadian English dialect',
    img_value: 'CA'
  },
  {
    title: 'English UK',
    value: 'UK English dialect',
    img_value: 'GB'
  },
  // {
  //   title: 'French',
  //   value: 'French',
  //   img_value: 'FR'
  // },
  // {
  //   title: 'German',
  //   value: 'German',
  //   img_value: 'DE'
  // },
  {
    title: 'Hebrew',
    value: 'Hebrew',
    img_value: 'IL'
  },
  // {
  //   title: 'Hindi',
  //   value: 'Hindi',
  //   img_value: 'IN'
  // },
  // {
  //   title: 'Italian',
  //   value: 'Italian',
  //   img_value: 'IT'
  // },
  {
    title: 'Portuguese (Brazilian)',
    value: 'Portuguese Brazilian dialect',
    img_value: 'BR'
  },
  {
    title: 'Portuguese (European)',
    value: 'Portuguese European dialect',
    img_value: 'PT'
  },
  // {
  //   title: 'Russian',
  //   value: 'Russian',
  //   img_value: 'RU'
  // },
  {
    title: 'Spanish',
    value: 'Spanish',
    img_value: 'ES'
  }
] as const
