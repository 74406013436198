/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/no-redeclare */
/* eslint-disable @typescript-eslint/no-misused-promises */
import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { contactUs } from '../../../app/services/Api'
import { TextareaAutosize } from '@mui/base/TextareaAutosize'
import { useAppSelector } from 'app/hooks'
import Input from '@mui/material/Input/Input'
import { useLocation } from 'react-router-dom'

interface Props {
  className?: string
  customMessage?: string
}

export default function ContactUsPageFrom ({ className }: Props) {
  const currentUser = useAppSelector(state => state.quizReducer.currentUser)
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [message, setMessage] = useState('')
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [errorFields, setErrorFields] = useState<string[]>([])
  const location = useLocation()

  const handleInputChange = (field: string) => { setErrorFields(errorFields.filter((f) => f !== field)) }

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search)
    const queryParamMessage = queryParams.get('message') ?? ''

    setMessage(queryParamMessage)
    handleInputChange('message')
  }, [])

  useEffect(() => {
    setName(currentUser?.first_name ?? '')
    setEmail(currentUser?.email ?? '')
  }, [currentUser])

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()

    setIsSubmitting(true)
    setErrorFields([])

    if (name === '' || email === '' || message === '') {
      if (name === '') setErrorFields((fields) => [...fields, 'name'])
      if (email === '') setErrorFields((fields) => [...fields, 'email'])
      if (message === '') setErrorFields((fields) => [...fields, 'message'])
      toast.error('Please fill all the required fields.')
    } else {
      try {
        const response = await contactUs.contactUs('New Lead', name, message, email)

        if (response !== null) {
          toast.success('Message sent successfully!')
        } else if (response.status === 429) {
          toast.error('Rate limit exceeded. Please try again after 24h.')
        }
      } catch (error) {
        toast.error('Failed to send the message. Please try again.')
      }
    }
  }

  return (
    <form onSubmit={handleSubmit} className={className}>
      <div className="flex flex-col items-center md:items-start gap-5 mt-5">
        <div className="flex flex-col w-full md:flex-row gap-3">
          <Input
            value={name}
            placeholder='Name *' type="text"
            disableUnderline={true}
            className={`bg-gray-50 border-[0.5px] ${errorFields.includes('name') ? 'border-red-500' : 'border-gray-300'} text-gray-900 text-sm rounded-[12px] block w-full md:w-1/2 h-14 p-2.5 outline-[0.1px] outline-gray-300`}
            onChange={(e) => { setName(e.target.value); handleInputChange('name') }}
          />
          <Input
            value={email}
            disableUnderline={true}
            placeholder='Email *' type="email"
            className={`bg-gray-50 border-[0.5px] ${errorFields.includes('email') ? 'border-red-500' : 'border-gray-300'} text-gray-900 text-sm rounded-[12px] block w-full md:w-1/2 h-14 p-2.5 outline-[0.5px] outline-gray-300`}
            onChange={(e) => { setEmail(e.target.value); handleInputChange('email') }}
          />
        </div>
        <TextareaAutosize
          value={message}
          onChange={(e) => {
            setMessage(e.target.value)
            handleInputChange('message')
          }}
          placeholder='Message *' minRows={5}
          className={`bg-gray-50 border-[0.5px] ${errorFields.includes('message') ? 'border-red-500' : 'border-gray-300'} text-gray-900 text-sm rounded-[12px] block w-full h-16 p-2.5 outline-[0.5px] outline-gray-300 flex`}
        />
        <button type="submit" className="quiz_form_button capitalize text-white font-medium rounded-lg text-lg px-9 py-2.5 text-center mr-2 h-14" onClick={handleSubmit} disabled={isSubmitting}> Submit</button>
      </div>
    </form>
  )
}
