import { useEffect } from 'react'

export default function ScrollTopOnReload () {
  useEffect(() => {
    window.scrollTo(0, 0)

    window.onbeforeunload = () => {
      window.scrollTo(0, 0)
    }
  }, [])

  return null
}
