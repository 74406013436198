interface Props {
  className: string
  text: string
  handleBackPage: (page: string) => void
  backPage: string
}

export default function BackButton ({ className, text, handleBackPage, backPage }: Props) {
  return (
    <a onClick={() => { handleBackPage(backPage) }} className={className}>{text}</a>
  )
}
