import React from 'react'
import faqItemIcon from '../../../img/faqItemIcon.svg'
import faqItemIconNegative from '../../../img/faqItemIconNegative.svg'

interface FaqItemProps {
  title: string
  description: string
  isExpanded: boolean
  onClick: () => void
}

const FaqItem = (props: FaqItemProps) => {
  const { description, title, onClick, isExpanded } = props

  return (
    <>
      <div className="">
      <div className="flex flex-col">
        <div className="flex justify-between" onClick={onClick}>
          <h2 className="text-sm md:text-lg w-3/4 md:w-auto font-normal my-2">{title}</h2>
          <div className="my-auto mx-4">
            {isExpanded ? (<img src={faqItemIconNegative} alt="Negative Icon" />) : (<img src={faqItemIcon} alt="Positive Icon" />)}
          </div>
        </div>
        {isExpanded && (
            <div className='whitespace-pre-wrap text-xs lg:text-sm'
            dangerouslySetInnerHTML={{ __html: description }}
          />
        )}
      </div>
      </div>
    </>
  )
}

export default FaqItem
