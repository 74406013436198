import React, { useState, useEffect } from 'react'
import Heading from '../../atoms/Heading/Heading'
import ToDo from '../WeeklyGoalsInfo/ToDo'
import { useNavigate, useParams } from 'react-router-dom'
import { tasks, users } from 'app/services/Api'
import { type Task } from 'app/quiz/quizSlice'
import BackButton from 'components/atoms/BackButton/BackButton'
// import { gapi } from "gapi-script";
import VideoPlayer from '../Player/VideoPlayer'
import { useAppSelector } from '../../../app/hooks'
import AskBranda from '../../atoms/Icon/AskBranda'
const videoUrl5 = 'Task pageWsub.mp4'
const mobileVideoUrl5 = 'Task pageMsub.mp4'
const transcriptContent5 = `
  <div>
    <h2 style="font-size: 24px; margin-bottom: 10px;">Completing A Task</h2>
    <p style="font-size:12px;">Now, we're getting to the most interesting part... <br/>
    For every task, I have already written an amazing content suggestion that matches your unique Brand Voice!
    <br/> <br/>
    First, you can read what this task is all about and hover above the recommended publishing time. Then, select your preferred writing language in the drop-down menu and click on GO!  <br/>
    After a few seconds, the suggestion will appear in the box below. Read it carefully and edit it to fit you perfectly. When you're done, copy and paste the content to the relevant platform by simply clicking on "Copy and go-to platform."
    <br/> <br/>
    To make your life super easy, I've even added some ideas of the best visual assets to attach to your post. Make sure you delete all instructions before posting the text on your profile. 
    <br/> <br/>
    After completing a task, visit your weekly goals page and checkmark the task to strike it out from the list and improve your score!
    <br/> <br/>
    Every week, you will get fresh new tasks, and I will make sure to notify you regularly so you don't miss out on any of them, and keep boosting your brand!
    <br/> <br/>
    That's it!  <br/>
    Now, you have everything you need to start building your brand and shine online!
    <br/> <br/>
    Happy Branding!
    </p>
  </div>
`

interface Props {
  className?: string
}

export default function WeeklyGoalsFrom ({ className }: Props) {
  const [selectedItem, setSelectedItem] = useState('To do')
  const [backPage] = useState('/all-weekly-goals')
  const [task, setTask] = useState<Task>()
  const navigate = useNavigate()
  const { id } = useParams()
  let value: number
  if (id != null) value = parseInt(id)
  const [isModalOpen, setModalOpen] = useState<boolean>(false)
  const [showTranscript, setShowTranscript] = useState<boolean>(false)
  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth < 768)
  const currentUser = useAppSelector((state) => state.quizReducer.currentUser)

  const handleModalOpen = (): void => {
    setModalOpen(true)
  }
  const handleCloseModal = (): void => {
    setModalOpen(false)
  }
  const toggleTranscript = (): void => {
    setShowTranscript(!showTranscript)
  }
  useEffect(() => {
    const handleResize = (): void => {
      setIsMobile(window.innerWidth < 768)
    }
    window.addEventListener('resize', handleResize)

    const getTask = async () => {
      await tasks
        .getTask(value)
        .then((response) => {
          setTask(response)
        })
        .catch((err) => {
          console.log(err)
        })
    }

    void getTask()

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    setModalOpen(currentUser?.has_viewed_task_details === false)

    if (currentUser !== null && !currentUser.has_viewed_task_details) {
      users.updateUser(currentUser.id, { has_viewed_task_details: true }).catch((error) => {
        console.error(error)
      })
    }
  }, [currentUser])

  const handleBackPage = () => {
    navigate(backPage)
    window.scrollTo(0, 0)
  }

  return (
    <div className="flex flex-col justify-center items-center md:justify-start md:items-start w-full gap-3">
      <div className="flex justify-between w-full mb-4">
        <BackButton
          backPage={backPage}
          handleBackPage={() => { handleBackPage() }}
          text="Back"
          className="quiz_back_button block"
        />
        <div className='flex justify-end w-full'>
          <AskBranda handleModalOpen={handleModalOpen} />
            {isModalOpen && (
            <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
                <div className="bg-white p-5 rounded-lg max-w-2xl w-full h-[87%] md:h-[unset]">
                <div className='flex justify-end' >
                  <button className="absolute text-white text-xl" onClick={handleCloseModal} style={{ marginTop: '-22px' }}>
                    &times;
                  </button>
                </div>
                {!showTranscript
                  ? (
                    <>
                      <VideoPlayer filename={isMobile ? mobileVideoUrl5 : videoUrl5} />
                    </>
                    )
                  : (
                    <div>
                      <div className="transcript-content bg-[#ffffffb8] rounded p-2" style={{ height: '90%', overflowY: 'auto' }}>
                        <div dangerouslySetInnerHTML={{ __html: transcriptContent5 }} />
                      </div>

                    </div>
                    )}
                    <div className="flex justify-between mt-4">
                    <button
                      className="px-4 py-2 bg-black rounded text-white w-28"
                      onClick={toggleTranscript}
                    >
                      {showTranscript ? 'Video' : 'Transcript'}
                    </button>
                    <button
                      className="px-4 py-2 bg-gray-200 rounded w-28"
                      onClick={handleCloseModal}
                    >
                      Skip
                    </button>
                  </div>
                </div>
            </div>
            )}
        </div>

      </div>
      <Heading
        className="flex justify-start font-medium tracking-wide text-xl md:text-4xl leading-7 text-black block p-0"
        text="Your Weekly Goals"
      />
      <div className="flex flex-row gap-7 pt-4">
        <div className="flex">
          <button
            onClick={() => { setSelectedItem('To do') }}
            className={`cursor-pointer ${
              selectedItem === 'To do'
                ? 'underline underline-offset-[20px] text-base md:text-lg  underline-purple-100'
                : 'text-gray-500 text-base md:text-lg '
            }`}
          >
            To do
          </button>
          <div className="ml-2 bg-black px-2  text-white rounded-md text-center">
            1
          </div>
        </div>
      </div>

      {selectedItem === 'To do' && <ToDo task={task} />}
    </div>
  )
}
