import QuizThirdStepTitleOne from '../../molecules/QuizThirdStepTitle/QuizThirdStepTitleOne'
import QuizThirdStepFormOne from '../../molecules/QuizThirdStepForm/QuizThirdStepFormOne'
import QuizThirdStepTitleTwo from '../../molecules/QuizThirdStepTitle/QuizThirdStepTitleTwo'
import QuizThirdStepFormTwo from '../../molecules/QuizThirdStepForm/QuizThirdStepFormTwo'
import QuizThirdStepTitleThree from '../../molecules/QuizThirdStepTitle/QuizThirdStepTitleThree'
import QuizThirdStepFormThree from '../../molecules/QuizThirdStepForm/QuizThirdStepFormThree'
import Steps from '../../molecules/Steps/Steps'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { useEffect } from 'react'
import { setSection } from 'app/quiz/quizSlice'

interface Props {
  className: string
  handleShowPage: (page: string) => void
}

export default function QuizThirdStep ({ className, handleShowPage }: Props) {
  const currentUser = useAppSelector(state => state.quizReducer.currentUser)
  const section = useAppSelector(state => state.quizReducer.section)
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (currentUser?.quiz_step?.step_name === 'Step Three' && currentUser?.quiz_step.section !== undefined) {
      dispatch(setSection(currentUser?.quiz_step.section))
    }
  }, [currentUser])

  return (
    <div className={className}>
      <Steps currentPage='pageStep3' />
      <QuizThirdStepTitleOne className='flex flex-col relative visible_1 justify-center items-center gap-5' />
      <QuizThirdStepFormOne className='flex flex-row relative visible_1 mt-5 justify-center items-center w-full' section={section}/>
      <QuizThirdStepTitleTwo className='flex flex-col relative visible_1 justify-center items-center mt-10 mb-5' section={section}/>
      <QuizThirdStepFormTwo className='last_step_1 flex relative visible_1 justify-center' section={section}/>
      <QuizThirdStepTitleThree className='flex flex-col relative visible_1 justify-center items-center mt-10 mb-5'/>
      <QuizThirdStepFormThree className='last_step_2 flex flex-col relative visible_1 justify-center items-center mb-5' section={section} handleShowPage={handleShowPage}/>
    </div>
  )
}
