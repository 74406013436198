/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import React, { useState, useEffect } from 'react'
import { Instagram, Facebook, LinkedIn } from '@mui/icons-material/'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { setFacebook, setFacebookPro, setInstagram, setInstagramPro, setLinkedin } from 'app/quiz/userSocialMedia'
import VideoPlayer from '../../Player/VideoPlayer'
import AskBranda from '../../../atoms/Icon/AskBranda'
import { Popover } from '@mui/material'

const videoUrl3 = 'ScoreWsub.mp4'
const mobileVideoUrl3 = 'ScoreMsub.mp4'
const transcriptContent3 = `
  <div>
    <h2 style="font-size: 24px; margin-bottom: 10px;">Branda Score video</h2>
    <p style="font-size:12px;">You're amazing! <br/>
    Did you like your Branda?<br/><br/>
    
    Anyways, we're almost done...<br/>
    Before sending your personalized plan, let's try to assess your current brand performance! <br/>
    In the next step, please choose your active Social Media profiles or the ones you plan to post from. Choose at least two platforms. Your personal FaceBook account is mandatory - because this is where Personal Branding can actually get Personal!<br/><br/>
    
    Afterwards, please check your profiles and fill in how many posts do you publish on average every week and add how many followers or friends you currently have on each account. I've already broken down the Types of posts for you. If there are posts that you don't publish weekly, you can fill in decimal numbers.  Again, this is only a one-time process!<br/><br/>
    
    You might ask, why do I even need this score?<br/>
    Well, every process has a starting point. This is how I can make sure you'll be able to accurately assess your current brand level, benchmark your score to other industry peers, and visually track your progress! <br/>
    You will also get lots of cool insights!<br/>
    It will take you a minute and, believe me, It's worth it!<br/><br/>
    
    Ready to check your score?!
    </p>
  </div>
`

interface Platform {
  name: string
  icon: JSX.Element
}

interface Props {
  isOpen: boolean
  onClose: () => void
  onConfirm: () => void
}

export default function SocialMediaPlatformPopUp ({ isOpen, onClose, onConfirm }: Props) {
  const dispatch = useAppDispatch()
  const facebookChecked = useAppSelector((state) => state.userSocialMediaReducer.facebook)
  const facebookProChecked = useAppSelector((state) => state.userSocialMediaReducer.facebookpro)
  const instagramChecked = useAppSelector((state) => state.userSocialMediaReducer.instagram)
  const instagramProChecked = useAppSelector((state) => state.userSocialMediaReducer.instagrampro)
  const linkedinChecked = useAppSelector((state) => state.userSocialMediaReducer.linkedin)
  const [isDisabled, setIsDisabled] = useState(true)

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const [isPopoverOpen, setIsPopoverOpen] = useState(false)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setIsPopoverOpen(true)
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setIsPopoverOpen(false)
    setAnchorEl(null)
  }

  const platforms: Platform[] = [
    { name: 'Instagram', icon: <Instagram style={{ fontSize: '30px', opacity: '0.55' }}/> },
    { name: 'Facebook', icon: <Facebook style={{ fontSize: '30px', opacity: '0.55' }}/> },
    { name: 'LinkedIn', icon: <LinkedIn style={{ fontSize: '30px', opacity: '0.55' }}/> }
  ]

  useEffect(() => {
    const checkedCount =
      ((facebookChecked || facebookProChecked) ? 1 : 0) +
      ((instagramChecked || instagramProChecked) ? 1 : 0) +
      (linkedinChecked ? 1 : 0)

    setIsDisabled(checkedCount < 2)
  }, [facebookChecked, facebookProChecked, instagramChecked, instagramProChecked, linkedinChecked])

  useEffect(() => {
    setIsDisabled(true)
  }, [])

  const handlePlatformChange = (platform: string) => {
    switch (platform) {
      case 'Facebook':
        dispatch(setFacebook(true))
        break
      case 'FacebookPro':
        dispatch(setFacebookPro(!facebookProChecked))
        break
      case 'Instagram':
        dispatch(setInstagram(!instagramChecked))
        break
      case 'InstagramPro':
        dispatch(setInstagramPro(!instagramProChecked))
        break
      case 'LinkedIn':
        dispatch(setLinkedin(!linkedinChecked))
        break
      default:
        break
    }
  }
  const [isModalOpen, setModalOpen] = useState<boolean>(false)
  const [showTranscript, setShowTranscript] = useState<boolean>(false)
  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth < 768)

  const handleModalOpen = (): void => {
    setModalOpen(true)
  }
  const handleCloseModal = (): void => {
    setModalOpen(false)
  }
  const toggleTranscript = (): void => {
    setShowTranscript(!showTranscript)
  }
  useEffect(() => {
    const handleResize = (): void => {
      setIsMobile(window.innerWidth < 768)
    }
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])
  return (
    <div
      className={`flex fixed top-0 right-0 left-0 z-50 backdrop-blur-sm justify-center items-center w-full md:inset-0 h-full ${isOpen ? '' : 'hidden'}`}>
      <div className="absolute w-full h-full bg-gray-800 opacity-50"></div>
      <div className="relative">
        <div className="branda-score w-[350px] md:w-[600px] h-72 rounded-3xl shadow dark:bg-gray-700 mt-2">
          <div className='flex justify-end w-full pt-4 pr-4'>
            <AskBranda handleModalOpen={handleModalOpen}/>
          </div>
          {isModalOpen && (
            <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
              <div className="bg-white p-5 rounded-lg max-w-2xl w-full">
                <div className='flex justify-end'>
                  <button className="absolute text-white text-xl" onClick={handleCloseModal}
                          style={{ marginTop: '-22px' }}>
                    &times;
                  </button>
                </div>
                {!showTranscript
                  ? (
                    <>
                      <VideoPlayer filename={isMobile ? mobileVideoUrl3 : videoUrl3}/>
                    </>
                    )
                  : (
                    <div>
                      <div className="transcript-content bg-[#ffffffb8] rounded p-2"
                           style={{ height: '360px', overflowY: 'auto' }}>
                        <div dangerouslySetInnerHTML={{ __html: transcriptContent3 }}/>
                      </div>

                    </div>
                    )}
                <div className="flex justify-between mt-4">
                  <button
                    className="px-4 py-2 bg-black rounded text-white w-28"
                    onClick={toggleTranscript}
                  >
                    {showTranscript ? 'Video' : 'Transcript'}
                  </button>
                  <button
                    className="px-4 py-2 bg-gray-200 rounded w-28"
                    onClick={handleCloseModal}
                  >
                    Skip
                  </button>
                </div>
              </div>
            </div>
          )}
          <div
            className="flex flex-col items-center justify-center md:mt-2 px-2 md:px-3 rounded-t dark:border-gray-600 gap-4">
            <div className='text-center'>
              <p className="font-semibold md:font-medium text-base md:text-2xl text-black p-0 mb-0 flex justify-center">
                Where do you plan to get visible?
              </p>
              <p className='mt-2 text-xs hidden md:inline-block md:text-sm'>Choose at least 2 platforms, your <strong>Personal
                Facebook</strong> account is <span onClick={handleClick}
                                                   className={'underline decoration-dotted cursor-help'}>mandatory</span>!
              </p>
              <p className='mt-2 text-xs md:hidden md:text-sm'>Choose at least 2 platforms.<br/> Your <strong>Personal
                Facebook</strong> account is <span onClick={handleClick}
                                                   className={'underline decoration-dotted cursor-help'}>mandatory</span>!
              </p>
            </div>
            <div className="flex flex-row justify-center items-center ml-6 mb-4">
              {platforms.map((platform) => (
                <FormControlLabel
                  key={platform.name}
                  control={
                    <div className="flex flex-col items-center">
                      <div className={`relative flex justify-center border rounded-full ${
                        ((platform.name === 'Facebook' && facebookChecked) ||
                        (platform.name === 'Instagram' && instagramChecked) ||
                        (platform.name === 'LinkedIn' && linkedinChecked))
? 'bg-white shadow-md'
: ''
                      }`}>
                        <span className="absolute small-text font-medium text-purple-800 -bottom-4">Personal</span>
                        <Checkbox
                          checked={
                            (platform.name === 'Facebook' && facebookChecked) ||
                            (platform.name === 'Instagram' && instagramChecked) ||
                            (platform.name === 'LinkedIn' && linkedinChecked)
                          }
                          onChange={() => {
                            handlePlatformChange(platform.name)
                          }}
                          icon={platform.icon}
                          checkedIcon={React.cloneElement(platform.icon, {
                            style: {
                              color:
                                platform.name === 'Instagram' || platform.name === 'InstagramPro'
                                  ? 'rgba(206, 53, 134, 1)'
                                  : platform.name === 'LinkedIn'
                                    ? '#0077b5'
                                    : '#1976D2',
                              fontSize: platform.name === 'Twitter' ? '28px' : '30px'
                            }
                          })}
                        />
                      </div>
                    </div>
                  }
                  label={null}
                />
              ))}
            </div>
          </div>
          <div className="p-2 md:px-5 pt-6 md:pt-3 space-y-4">
            <div className="flex justify-center items-center">
              <button
                className={`px-10 py-3 w-48 text-sm md:text-xl font-semibold text-white rounded-2xl 
              ${isDisabled ? 'bg-gray-300 cursor-not-allowed' : 'bg-purple-600'}`}
                onClick={onConfirm}
                disabled={isDisabled}
              >
                Start
              </button>
            </div>
          </div>
        </div>
      </div>

      <Popover
        id={'popover'}
        open={isPopoverOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        className={'p-3.5'}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        <div className={'p-3.5 text-sm md:text-base'}>
          Click on the <svg xmlns="http://www.w3.org/2000/svg" className="inline-block icon icon-tabler icon-tabler-zoom-question"
               width="20"
               height="20" viewBox="0 0 24 24" strokeWidth="1.5" stroke={'#552bb1'}
               fill="none" strokeLinecap="round"
               strokeLinejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
            <path d="M10 10m-7 0a7 7 0 1 0 14 0a7 7 0 1 0 -14 0"/>
            <path d="M21 21l-6 -6"/>
            <path d="M10 13l0 .01"/>
            <path d="M10 10a1.5 1.5 0 1 0 -1.14 -2.474"/>
          </svg> icon to get more info.
        </div>
      </Popover>
    </div>
  )
}
