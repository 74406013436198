/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import Header from '../../atoms/Heading/Heading'
import ProfileArrowIcon from '../../../img/profilearrowicon.svg'
import BasicInfo from './BasicInfo'
import AccountSettings from './AccountSettings'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { setCurrentUser, setProfileSettingPage } from '../../../app/quiz/quizSlice'
import { useEffect, useState } from 'react'
import { brandas } from '../../../app/services/Api'
import { useNavigate } from 'react-router-dom'
import BackButton from 'components/atoms/BackButton/BackButton'
import SubscriptionPlan from './SubscriptionPlan'

export default function ProfileForm () {
  const dispatch = useAppDispatch()
  const profileSettingPage = useAppSelector(state => state.quizReducer.profileSettingPage)
  const currentUser = useAppSelector((state) => state.quizReducer.currentUser)
  const [backPage, setBackPage] = useState('')

  const navigate = useNavigate()
  const handleBackPage = (page: string) => {
    dispatch(setProfileSettingPage(page))

    if (profileSettingPage === 'profile-settings' || profileSettingPage === 'profile-basic' || profileSettingPage === 'profile-subscription' || profileSettingPage === 'profile-subscription-from-sub-page') {
      setBackPage('init-profile')
    } else {
      navigate(-1)
    }

    window.scrollTo(0, 0)
  }

  useEffect(() => {
    return () => {
      dispatch(setProfileSettingPage('init-profile'))
    }
  }, [dispatch])

  useEffect(() => {
    const getBrandaDetail = async () => {
      if ((currentUser != null) && !currentUser.branda_name) {
        await brandas.getBrandaForUser(currentUser.branda_type).then(response => {
          const newUser = { ...currentUser, branda_name: response.name }
          dispatch(setCurrentUser(newUser))
        })
          .catch(err => {
            console.log(err)
          })
      }
    }

    getBrandaDetail()
  }, [currentUser, dispatch])

  const handleShowPage = (page: string) => {
    dispatch(setProfileSettingPage(page))
    window.scrollTo(0, 0)
  }

  return (
    <div className='flex flex-col justify-center items-center md:justify-start md:items-start w-full gap-3'>
      <div className="flex w-full">
        <BackButton
          backPage={backPage}
          handleBackPage={() => { handleBackPage('init-profile') }}
          text="Back"
          className="quiz_back_button block"
        />
      </div>
      {profileSettingPage === 'init-profile' && (
        <>
          <Header className='font-normal font-medium text-2xl md:text-3xl leading-7 text-black block' text='My account' />
          <div className='border-b border-slate-200 w-full mb-4'></div>
          <div className='mb-2 flex justify-between w-full cursor-pointer'>
            <div className='flex'>
              <div className='mr-4 rounded-full h-10 w-10 bg-indigo-800 flex items-center justify-center'>
                <h2 className='text-white font-normal text-lg'>{currentUser?.first_name && currentUser.first_name.charAt(0) + currentUser?.last_name && currentUser.last_name.charAt(0)}</h2>
              </div>
              <div className='flex flex-col'>
                <h1 className='text-xl font-light flex items-center'>
                  {currentUser?.first_name} {currentUser?.last_name}
                </h1>
                <p className='text-sm font-thin'>{currentUser?.profession}</p>
              </div>
            </div>
          </div>
          <div className='w-full mt-8'>
            <div className='flex items-center justify-between w-full mb-7 px-2 py-3'>
              <h1 className='text-base font-light flex items-center'>
                Your Branda:
              </h1>
              <span className='text-xs font-bold'>
                {currentUser?.branda_name}
              </span>
            </div>
            <div className='flex items-center justify-between w-full px-2 py-3 mb-2 md:mb-7 rounded-md cursor-pointer hover:bg-violet-100' onClick={() => { handleShowPage('profile-basic') }}>
              <h1 className='text-base font-light'>
                Your Details
              </h1>
              <img src={ProfileArrowIcon} alt="Profile Arrow Icon" className='' />
            </div>
            <div className='flex items-center justify-between w-full px-2 py-3 mb-2 md:mb-7 rounded-md cursor-pointer hover:bg-violet-100' onClick={() => { handleShowPage('profile-settings') }}>
              <h1 className='text-base font-light'>
                Account settings
              </h1>
              <img src={ProfileArrowIcon} alt="Profile Arrow Icon" className='' />
            </div>
          </div>
          <div className='w-full mt-2 px-2 py-3'>
            <div className='flex items-center justify-between'>
              <h1 className='flex flex-col items-start text-base font-normal text-indigo-600'>
                <span className='text-sm font-light'>My Subscription</span>
              </h1>
              <button type="submit" className="button_bg w-28 h-10 text-sm text-center font-light capitalize text-white rounded-lg" onClick={() => { handleShowPage('profile-subscription') }}>Manage</button>
            </div>
          </div>
        </>
      )}
      {profileSettingPage === 'profile-basic' && <BasicInfo />}
      {profileSettingPage === 'profile-settings' && <AccountSettings />}
      {(profileSettingPage === 'profile-subscription' || profileSettingPage === 'profile-subscription-from-sub-page') && <SubscriptionPlan /> }
    </div>
  )
}
