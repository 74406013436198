/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/space-before-function-paren */
import React, { useEffect, useState } from 'react'
import Heading from '../../atoms/Heading/Heading'
import { RadialBarChart, PolarAngleAxis, RadialBar, ResponsiveContainer, BarChart, XAxis, Bar, YAxis } from 'recharts'
import { useAppSelector } from 'app/hooks'

const chartRadius = 50
interface Props {
  className?: string
}

export default function Benchmark({ className }: Props) {
  const currentUser = useAppSelector(state => state.quizReducer.currentUser)
  const [isScreenWidthAbove768, setIsScreenWidthAbove768] = useState(false)
  const [benchmarkYou, setBenchmarkYou] = useState(0)
  const [data, setData] = useState([
    { name: 'L1', value: 0 },
    { name: 'L2', value: 0 }
  ])

  const [benchmarkData, setBenchmarkData] = useState([
    { name: 'You', performance: 0 },
    { name: 'Average', performance: 0 }
  ])

  const [benchmarkLeaders, setBenchmarkLeaders] = useState([
    { name: 'Leaders', performance: 0 }
  ])

  const updateScreenWidth = () => {
    if (window.innerWidth > 768) {
      setIsScreenWidthAbove768(true)
    } else {
      setIsScreenWidthAbove768(false)
    }
  }

  useEffect(() => {
    const networkStrength = currentUser?.network_strength ?? 0
    const conversionPotential = currentUser?.conversion_potential ?? 0
    setData([
      { name: 'L1', value: networkStrength },
      { name: 'L2', value: conversionPotential }
    ])
    const benchmarkAverage = Math.ceil(currentUser?.benchmark_average ?? 0)
    const benchmarkLeaders = Math.ceil(currentUser?.benchmark_leaders ?? 0)

    const initialValueForYou = (Math.ceil(currentUser?.score ?? 0)) / Math.ceil(currentUser?.profession_average_score ?? 0) * 100
    const benchmarkForYou = benchmarkAverage * initialValueForYou / 100

    setBenchmarkYou(Math.ceil(benchmarkForYou))

    setBenchmarkData([
      { name: 'You', performance: Math.ceil(benchmarkForYou) },
      { name: 'Average', performance: benchmarkAverage }
    ])
    setBenchmarkLeaders([
      { name: 'Leaders', performance: benchmarkLeaders }
    ])
  }, [currentUser?.score])

  useEffect(() => {
    updateScreenWidth()
    window.addEventListener('resize', updateScreenWidth)

    return () => {
      window.removeEventListener('resize', updateScreenWidth)
    }
  }, [])

  let conversionText = ''
  if (currentUser?.conversion_potential !== null && currentUser?.conversion_potential !== undefined) {
    const conversionPotential = currentUser.conversion_potential
    if (conversionPotential > 100) {
      const ratio = conversionPotential / 100
      if (ratio <= 2) {
        const percentage = Math.round((ratio - 1) * 100)
        conversionText = `You are ${percentage}% more powerful than your industry.`
      } else {
        const times = ratio.toFixed(1)
        conversionText = `You are x${times} times more powerful than your industry.`
      }
    }
  }

  return (
    <div className='flex flex-col gap-5'>
      <div className='grid md:grid-cols-2 md:divide-x px-7 py-2 bg-white rounded-2xl'>
        <div className='flex items-center justify-between md:justify-center'>
          {isScreenWidthAbove768
            ? <Heading className='text-black text-base hidden md:inline font-medium' text='Network strength, %' />
            : <Heading className='text-black text-base inline md:hidden text-start md:text-start font-medium' text='Network strength,%' />
          }
          <RadialBarChart
            width={chartRadius * 2}
            height={chartRadius * 2}
            cx={chartRadius}
            cy={chartRadius}
            innerRadius={chartRadius * 0.4}
            outerRadius={chartRadius * 0.9}
            barSize={5}
            data={[data.find(item => item.name === 'L1')]}
            startAngle={90}
            endAngle={-270}
          >
            <PolarAngleAxis
              type="number"
              domain={[0, 100]}
              angleAxisId={0}
              tick={false}
            />
            <RadialBar background dataKey='value' fill='#8B5CF6' />
            <text
              x={chartRadius}
              y={chartRadius}
              textAnchor="middle"
              dominantBaseline="middle"
              className="text-sm font-semibold bg-white"
            >
              {Math.ceil(currentUser?.network_strength ?? 0)}
            </text>
          </RadialBarChart>
        </div>
        <div className='flex items-center justify-between md:justify-center'>
          <div className='flex flex-col'>
            <Heading className='text-black text-base text-start md:text-start font-medium' text='Conversion Potential, %' />
            <div className='text-black text-xs text-start md:text-start'>
              {conversionText && <h1>{conversionText}</h1>}
            </div>
          </div>
          <RadialBarChart
            width={chartRadius * 2}
            height={chartRadius * 2}
            cx={chartRadius}
            cy={chartRadius}
            innerRadius={chartRadius * 0.4}
            outerRadius={chartRadius * 0.9}
            barSize={5}
            data={[data.find(item => item.name === 'L2')]}
            startAngle={90}
            endAngle={-270}
          >
            <PolarAngleAxis
              type="number"
              domain={[0, 100]}
              angleAxisId={0}
              tick={false}
            />
            <RadialBar background dataKey='value' fill='#8B5CF6' />
            <text
              x={chartRadius}
              y={chartRadius}
              textAnchor="middle"
              dominantBaseline="middle"
              className="text-sm font-semibold bg-white"
            >
              {((currentUser?.conversion_potential ?? 0) <= 100) ? Math.ceil(currentUser?.conversion_potential ?? 0) : '👑'}
            </text>
          </RadialBarChart>
        </div>
      </div>
      <div className='flex flex-col md:flex-row items-start bg-white rounded-2xl p-5'>
        <div className='flex flex-col items-start mb-4'>
          <Heading className='text-black font-manrope text-2xl font-base' text='Benchmark' />
          <Heading className='text-gray-400 font-manrope !text-base font-base w-[250px]' text='See your industry perfomance' />
          {benchmarkYou !== undefined && benchmarkYou >= 100 &&
            <Heading className='text-gray-400 font-manrope !text-base font-bold w-[250px]' text='Sure you need us? 👑 😜' />
          }
        </div>
        <div className="flex w-full">
          <ResponsiveContainer width={isScreenWidthAbove768 ? '80%' : '100%'} height={250} style={{ borderRadius: '30px' }} className={isScreenWidthAbove768 ? '' : 'basis-8/12'}>
            <BarChart data={benchmarkData} barCategoryGap={15}>
              <defs>
                <linearGradient id='fillGradient2' x1='20%' y1='0%' x2='50%' y2='100%'>
                  <stop offset='0%' style={{ stopColor: '#c1a9f7', stopOpacity: 1 }} />
                  <stop offset='100%' style={{ stopColor: '#733bb9', stopOpacity: 1 }} />
                </linearGradient>
              </defs>
              <YAxis domain={[0, 100]} hide={true} />
              <XAxis xAxisId="0" dataKey={'performance'} axisLine={false} dx={40} dy={-180} tickLine={false} tickFormatter={(value) => `${value}%`}
                style={{ fill: '#000', fontSize: '1.3rem' }}
                className='!text-sm'
              />
              <XAxis xAxisId="1" dataKey="name" axisLine={false} dy={-20} tickLine={false} style={{ fill: '#718096', fontSize: '1.10rem' }} />
              <Bar dataKey='performance' fill='url(#fillGradient2)' background={{ fill: '#eee' }} barSize={22} radius={[5, 5, 5, 5]} />
            </BarChart>
          </ResponsiveContainer>
          <ResponsiveContainer width={isScreenWidthAbove768 ? '60%' : '100%'} height={250} style={{ borderRadius: '30px', margin: isScreenWidthAbove768 ? '0 0 0 -100px' : '0' }} className={isScreenWidthAbove768 ? '' : 'basis-4/12'}>
            <BarChart data={benchmarkLeaders} barCategoryGap={15}>
              <defs>
                <linearGradient id='fillGradient2' x1='20%' y1='0%' x2='50%' y2='100%'>
                  <stop offset='0%' style={{ stopColor: '#c1a9f7', stopOpacity: 1 }} />
                  <stop offset='100%' style={{ stopColor: '#733bb9', stopOpacity: 1 }} />
                </linearGradient>
              </defs>
              <YAxis domain={[0, 100]} hide={true} />
              <XAxis xAxisId="0" dataKey={'performance'} axisLine={false} dx={40} dy={-180} tickLine={false} tickFormatter={(value) => `${value}%`}
                style={{ fill: '#000', fontSize: '1.3rem' }}
                className='!text-sm'
              />
              <XAxis xAxisId="1" dataKey="name" axisLine={false} dy={-20} tickLine={false} style={{ fill: '#718096', fontSize: '1.10rem' }} />
              <Bar dataKey='performance' fill='url(#fillGradient2)' background={{ fill: '#eee' }} barSize={22} radius={[5, 5, 5, 5]} />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  )
}
