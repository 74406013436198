interface Props {
  className: string
  text: string
  description?: string
  classNameDescription?: string
}

export default function Heading ({ className, text, description, classNameDescription }: Props) {
  const formattedText = { __html: text.replace(/<br\s*\/?>/g, '<br>') }
  return (
    <>
      <h1 className={className} dangerouslySetInnerHTML={formattedText}/>
      <h2 className={`text-xs md:text-sm font-normal text-center md:text-left break-words text-gray-500 ${classNameDescription}`}>
        {description}
      </h2>
    </>
  )
}
