/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/no-confusing-void-expression */
import React, { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { setQuestion1 } from '../../../app/quiz/quizSlice'
import { FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material'
import { users } from 'app/services/Api'

interface Props {
  className: string
  section: string
}

export default function QuizThirdStepFormOne ({ className, section }: Props) {
  const dispatch = useAppDispatch()
  const currentUser = useAppSelector(state => state.quizReducer.currentUser)
  const [q1Value, setQ1Value] = useState(0)

  useEffect(() => {
    if (currentUser?.quiz_step?.step_name === 'Step Three' && currentUser?.quiz_step.Q1 !== undefined) {
      dispatch(setQuestion1(currentUser?.quiz_step.Q1))
      setQ1Value(Number(currentUser?.quiz_step.Q1))
    }
  }, [currentUser])

  const handleCheckboxChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setQuestion1(event.target.value))
    setQ1Value(Number(event.target.value))
    await users.updateRegisteredUser(currentUser?.id ?? 0, { Q1: event.target.value, step_name: 'Step Three' }, true)
  }

  let content: string[] = []
  switch (section) {
    case 'E':
      content = ['Authentic', 'Rational', 'Ambitious', 'Adventurous']
      break
    case 'I':
      content = ['Optimistic', 'Analytical', 'Unorthodox', 'Efficient']
      break
    case 'S':
      content = ['Accountable', 'Balanced', 'Assertive', 'Fun']
      break
    case 'C':
      content = ['Supportive', 'Dominant', 'Inventive', 'Moral']
      break
    default:
      content = []
      break
  }

  return (
    <div className={className}>
       <FormControl component="fieldset" className='w-full md:w-[480px] lg:w-[520px] items-center'>
        <RadioGroup
          aria-label="quiz"
          name="quiz-radio-group"
          onChange={handleCheckboxChange}
          className="last_step_first_form justify-center md:justify-between w-full !flex-row gap-[5px]"
        >
          <div className="flex flex-col mx-1 basis-5/12">
            {content.slice(0, 2).map((item, index) => (
              <div key={index} className="last_step border p-2 rounded-md mt-1 w-full md:w-full">
                <FormControlLabel
                  value={`${index + 1}`}
                  control={<Radio />}
                  label={item}
                  checked={(index + 1) === q1Value}
                  labelPlacement="start"
                />
              </div>
            ))}
          </div>
          <div className="flex flex-col mx-1 basis-5/12">
            {content.slice(2).map((item, index) => (
              <div key={index} className="last_step border p-2 rounded-md mt-1 w-full md:w-full">
                <FormControlLabel
                  value={`${index + 3}`}
                  control={<Radio />}
                  checked={(index + 3) === q1Value}
                  label={item}
                  labelPlacement="start"
                />
              </div>
            ))}
          </div>
        </RadioGroup>
      </FormControl>
    </div>
  )
}
