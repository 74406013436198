import Header from '../../atoms/Heading/Heading'
import { ReactComponent as QuizProfessionPageImg } from '../../../img/quiz-profession-page-img.svg'
import { Popover } from '@mui/material'
import { useState } from 'react'

interface Props {
  className: string

}

export default function QuizProfessionPageInfo ({ className }: Props) {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const [isOpen, setIsOpen] = useState(false)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setIsOpen(true)
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setIsOpen(false)
    setAnchorEl(null)
  }

  return (
    <div className={className}>
      <QuizProfessionPageImg />
      <Header className='text-xl md:text-2xl font-medium mt-10 mb-2' text="What is your profession?" />
      <p onClick={handleClick} className={'underline decoration-dotted text-sm cursor-help px-5 py-2 md:p-0 text-center mb-4 text-gray-500'}>Didn&apos;t find your exact profession?</p>

      <Popover
        id={'popover'}
        open={isOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        className={'p-3.5'}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        <div className={'p-3.5 text-sm md:text-base'}>
          <p>No worries! 😅</p>
          <p>The next step will allow you to further specify your professional expertise 🙂</p>
          <p>If it’s really off, please stop the process and <a className="underline text-blue-800" href="/contact-us?message=Can't find my profession">Contact Us!</a></p>
          <p>📣 Keep in mind 📣</p>
          <p>myBranda® is a <b>Personal Branding</b> platform. We revolutionize HUMAN Brands! <br /> If you have a business, this plan will help you promote it as the business owner individual. Trust us, it will be super effective 🎉</p>
        </div>
      </Popover>
    </div>
  )
}
