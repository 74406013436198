/* eslint-disable */
import { type ApiClient } from '../ApiClient'

export class Faq{
  private readonly api: ApiClient

  constructor (api: ApiClient) {
    this.api = api
  }
  
  async getSupport(supportType?: string): Promise<any> {
    const queryParam = supportType ? `?faq_type=${supportType}` : ''
    return await this.api.get<object>(`supports/${queryParam}`)
  }

  async create(data: object, credentials?: boolean) {
    return await this.api.post<object>('supports/', data, credentials)
  }

  async update (id: number, data: object, credentials?: boolean): Promise<any> {
    return await this.api.put<object>(`supports/${id}/`, data, credentials)
  }

  async delete (id: number, credentials?: boolean): Promise<any> {
    return await this.api.delete<object>(`supports/${id}/`, { id }, credentials)
  }
}  