/* eslint-disable @typescript-eslint/no-misused-promises */
import React, { useState, useEffect } from 'react'
import Input from '../../atoms/Input/Input'
import CustomLink from '../../atoms/Link/Link'
import { useNavigate, useParams } from 'react-router-dom'
import { auth } from '../../../app/services/Api'
import { toast } from 'react-toastify'

export default function ResetPasswordConfirm () {
  const [touched, setTouched] = useState({ password: false, confirmPassword: false })
  const [passwordMatch, setPasswordMatch] = useState(true)
  const [isDisabled, setIsDisabled] = useState(true)
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const { uid, token } = useParams()
  const navigate = useNavigate()

  useEffect(() => {
    setIsDisabled(password === '' || confirmPassword === '' || !passwordMatch)
  }, [password, confirmPassword, passwordMatch])

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    await auth.resetPasswordConfirm(uid, token, password, confirmPassword).then(response => {
      navigate('/sign-in')
      toast.success('Your password has been successfully changed!')
    })
      .catch(err => {
        console.log(err)
      })
  }
  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newPassword = e.target.value
    setTouched((prevTouched) => ({ ...prevTouched, password: true }))
    setPassword(newPassword)
    setPassword(newPassword)
    setPasswordMatch(newPassword === confirmPassword)
  }
  const handleConfirmPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newConfirmPassword = e.target.value
    setTouched((prevTouched) => ({ ...prevTouched, confirmPassword: true }))
    setConfirmPassword(newConfirmPassword)
    setConfirmPassword(newConfirmPassword)
    setPasswordMatch(password === newConfirmPassword)
  }
  return (
    <div className='sign_in_sign_up visible_1 min-h-screen'>
    <div className="flex flex-col items-center justify-center">
      <div className='flex flex-row w-full justify-between px-8'>
        <CustomLink className='my_branda_logo sm:ml-5 flex self-center font-medium text-2xl md:text-3xl pb-2 mt-5' linkTo='/' name='myBranda' tm={true} />
      </div>
      <form onSubmit={handleSubmit}>
        <div className="flex flex-col justify-center items-center ">
          <div className="font-medium text-xl md:text-3xl leading-8 text-gray-900 mt-24">
            <h1>Reset Your Password</h1>
          </div>
          <div className="font-normal font-weight-400 text-sm md:text-base text-gray-600 block md:mt-2">
            <h2>To reset your password, enter a new password below.</h2>
          </div>
          <div className='flex flex-col gap-3 mt-7 w-full'>
            <Input
              placeholder="Password" type="password" onChange={handlePasswordChange}
              className="px-2 py-4 h-13 border border-gray-300 rounded-xl bg-white blockw-96 focus:outline-none font-medium text-base text-gray-900"
              value={password}
            />
            <Input
              placeholder="Confirm Password" type="password" onChange={handleConfirmPasswordChange}
              className="px-2 py-4 h-13 border border-gray-300 rounded-xl bg-white block focus:outline-none font-medium text-base text-gray-900"
              value={confirmPassword}
            />
            {!passwordMatch && touched.password && touched.confirmPassword && (
                <p className="text-red-500 text-sm mt-8">Passwords do not match.</p>
            )}
            <button disabled={isDisabled} className='quiz_form_button text-white !w-full !h-11'>Reset Password</button>
          </div>
        </div>
      </form>
    </div>
  </div>
  )
}
