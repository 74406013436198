/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable no-sequences */
/* eslint-disable @typescript-eslint/no-confusing-void-expression */
/* eslint-disable @typescript-eslint/no-misused-promises */
import TaskAbandonedModal from 'components/organisms/AllWeeklyGoalsContent/Modals/TaskAbandonedModal'
import React, { useState } from 'react'

interface Props {
  isOpen: boolean
  onCloseIcon: () => void
  onClose: () => void
  onConfirm: () => Promise<void>
}

export default function DeleteConfirmationWeeklyGoals ({ isOpen, onCloseIcon, onClose, onConfirm }: Props) {
  const [showAbandonedReasonsModal, setShowAbandonedReasonsModal] = useState(false)

  const handleConfirm = async () => {
    await onConfirm()
    setShowAbandonedReasonsModal(true)
  }

  return (
    <>
    <div
      id="static-modal"
      data-modal-backdrop="static"
      tabIndex={-1}
      aria-hidden="true"
      className={`${
        isOpen ? '' : 'hidden'
      } flex fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-full`}
    >
      <div className="absolute w-full h-full bg-gray-800 opacity-50"></div>
      <div className="relative p-4">
        {/* <!-- Modal content --> */}
        <div className="relative p-4 bg-white rounded-3xl shadow dark:bg-gray-700">
          {/* <!-- Modal header --> */}
          <div className="flex items-center justify-between p-2 md:p-3 rounded-t dark:border-gray-600">
            <p className="font-medium  text-base  md:text-xl text-black p-0 mr-5">
              Are you sure you want to remove this task for this week?
            </p>
            <button
              onClick={onCloseIcon}
              type="button"
              className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
              data-modal-hide="static-modal"
            >
              <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
              </svg>
              <span className="sr-only">Close modal</span>
            </button>
          </div>
          {/* <!-- Modal body --> */}
          <div className="p-4 md:px-5 md:pt-10 md:pb-3 space-y-4">
            <div className="flex justify-end">
              <button className="mr-2 px-4 py-2 bg-blue-500 text-sm md:text-base  text-white rounded-lg" onClick={onClose}>Postpone for next week</button>
              <button className="px-4 py-2 bg-red-600 text-sm md:text-base text-white rounded-lg" onClick={handleConfirm}>Remove forever</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    {showAbandonedReasonsModal && (
        <TaskAbandonedModal
          isOpen={showAbandonedReasonsModal}
          onClose={() => { setShowAbandonedReasonsModal(false) }}
          onConfirm={() => { setShowAbandonedReasonsModal(true) }}
        />
    )}
    </>
  )
}
