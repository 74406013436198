/* eslint-disable @typescript-eslint/no-misused-promises */
import React, { useState } from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import { toast } from 'react-toastify'
import { professions } from 'app/services/Api'

interface DeleteProfessionProps {
  professionId: number
  onDeleteProfession: () => void
  onClose: () => void
}

export default function DeleteProfession ({ professionId, onDeleteProfession, onClose }: DeleteProfessionProps) {
  const [loading, setLoading] = useState(false)

  const handleDeleteProfession = async () => {
    try {
      setLoading(true)
      await professions.delete(professionId, true)
      toast.warning('Profession successfully deleted!')
      onDeleteProfession()
    } catch (error) {
      console.error('Error deleting Profession:', error)
    } finally {
      setLoading(false)
      onClose()
    }
  }

  return (
    <Dialog open={true} onClose={onClose}>
      <DialogTitle>Delete Profession</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to delete this Profession?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button disabled={loading} onClick={onClose} color="primary" variant="outlined">
          Cancel
        </Button>
        <Button disabled={loading} onClick={handleDeleteProfession} color="error" variant="outlined">
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  )
}
