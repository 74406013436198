import React from 'react'
import Icon from '../../atoms/Icon/Icon'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'

interface Props {
  currentPage: string
}

export default function Steps ({ currentPage }: Props) {
  const classNames1: string[] = []
  const classNames2: string[] = []
  const classNames3: string[] = []
  if (currentPage === 'pageStep1') {
    classNames1.push('step1')
    classNames1.push('active')
    classNames2.push('step2')
    classNames3.push('step3')
  } else if (currentPage === 'pageStep2') {
    classNames2.push('step2')
    classNames2.push('active')
    classNames1.push('done')
    classNames3.push('step3')
    classNames1.push('step1')
  } else if (currentPage === 'pageStep3') {
    classNames3.push('step3')
    classNames3.push('active')
    classNames1.push('done')
    classNames2.push('done')
    classNames2.push('step2')
    classNames1.push('step1')
  }
  return (
    <div className='quiz_steps flex flex-row justify-between items-center'>
      <div className='quiz_steps_step step1 flex flex-row justify-center items-center'>
        <h6 className={classNames1.join(' ')}>
          Step
        </h6>
        {
          currentPage === 'pageStep2' || currentPage === 'pageStep3'
            ? <Icon icon={solid('square-check')} style={{ color: '#1aa631' }} />
            : <span className={classNames1.join(' ')}>1</span>
        }
      </div>
      <div className='quiz_steps_step step2 flex flex-row justify-center items-center'>
        <h6 className={classNames2.join(' ')}>
          Step
        </h6>
        {
          currentPage === 'pageStep3'
            ? <Icon icon={solid('square-check')} style={{ color: '#1aa631' }} />
            : <span className={classNames2.join(' ')}>2</span>
        }
      </div>
      <div className='quiz_steps_step step3 flex flex-row justify-center items-center'>
        <h6 className={classNames3.join(' ')}>
          Step
        </h6>
        <span className={classNames3.join(' ')}>3</span>
      </div>
    </div>
  )
}
