import { ReactComponent as QuizBirthdatePageImg } from '../../../img/quiz-birthdate-page-img.svg'
import Heading from '../../atoms/Heading/Heading'

interface Props {
  className: string
}

export default function QuizBirthdatePageInfo ({ className }: Props) {
  return (
    <div className={className}>
      <QuizBirthdatePageImg />
      <Heading className='font-medium text-2xl text-center' text='When is your date of birth?' />
    </div>
  )
}
