/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import React from 'react'
import SidebarLinks from '../../molecules/SidebarLinks/SidebarLinks'
// import SidebarLinksAdmin from 'components/molecules/SidebarLinks/SidebarLinksAdmin'
// import { useAppSelector } from 'app/hooks'

interface Props {
  className: string
  currentPage: string
}

export default function Sidebar ({ className, currentPage }: Props) {
  // const currentUser = useAppSelector(state => state.quizReducer.currentUser)
  return (
    <div className={className}>
      <SidebarLinks className='flex flex-col justify-evenly items-start p-5 bg-white w-56 h-86 rounded-3xl' />
      {/* {currentUser && (
        <SidebarLinksAdmin
          className='flex flex-col justify-evenly items-start p-5 bg-white w-56 h-86 rounded-3xl'
        />
      )} */}
    </div>
  )
}
