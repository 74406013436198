import { ReactComponent as QuizEmailPageImg } from '../../../img/quiz-email-page-img.svg'
import Header from '../../atoms/Heading/Heading'

interface Props {
  className: string
}

export default function QuizPageOneInfo ({ className }: Props) {
  return (
    <div className={className}>
      <QuizEmailPageImg className='w-9/12 md:w-auto'/>
      <div className='flex flex-col justify-center items-center gap-3'>
        <Header className='text-2xl font-medium' text='Hello Brand' />
        <Header className='text-sm font-normal text-center md:text-left break-words w-64 md:w-auto' text="You're already one step closer to running your online brand like a PRO" />
      </div>
    </div>
  )
}
