import React, { useRef } from 'react'
import Footer from 'components/organisms/Footer/Footer'
import NavBar from 'components/organisms/NavBar/NavBar'
import HomeVideo from './HomeVideo'
import HeadersNonSignInUsers from 'components/molecules/Headers/HeadersNonSignInUsers'
import ScrollTopOnReload from 'app/services/ScrollTopOnReload'

interface Props {
  className: string
}

export default function HomeNonSignedInUsers ({ className }: Props) {
  const targetDivRef = useRef<HTMLDivElement | null>(null)

  const scrollToTargetDiv = () => {
    if (targetDivRef.current != null) {
      targetDivRef.current.scrollIntoView({
        behavior: 'smooth'
      })
    }
  }

  return (
    <div className={className}>
      <ScrollTopOnReload />
      <NavBar transparentBg={true} className='relative flex flex-row justify-between items-center p-5 pt-3 h-24 align-center -mb-5 sm:-mb-2' titleClassName='text-white'/>
      <HeadersNonSignInUsers className='flex justify-between md:justify-center flex-col align-center home-page' onScrollDown={scrollToTargetDiv} />
      <div ref={targetDivRef} className="rounded-t-3xl rounded-b-3xl overflow-hidden bg-purple-100 relative z-1 mt-[-25px] md:mt-[-50px] lg:mt-[-100px] xl:mt-[-180px]">
        <HomeVideo />
      </div>
      <Footer className='flex flex-col items-start md:items-center md:flex-row justify-between w-full min-h-20 p-3 pt-8 md:p-3 -mt-2 bg-white' />
    </div>
  )
}
