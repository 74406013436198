interface Props {
  className: string
  text: string
  disabled?: boolean
  title?: string
}

export default function Button ({ className, text, disabled, title }: Props) {
  return <button title={title} disabled={disabled} className={className}>{text}</button>
}
