/* eslint-disable @typescript-eslint/no-misused-promises */
import React, { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { setQuestion2 } from '../../../app/quiz/quizSlice'
import { FormControl, Radio, RadioGroup, FormControlLabel } from '@mui/material'
import { users } from 'app/services/Api'

interface Props {
  className: string
  section: string
}

export default function QuizThirdStepFormTwo ({ className, section }: Props) {
  const dispatch = useAppDispatch()
  const currentUser = useAppSelector(state => state.quizReducer.currentUser)
  const [q2Value, setQ2Value] = useState(0)

  useEffect(() => {
    if (currentUser?.quiz_step?.step_name === 'Step Three' && currentUser?.quiz_step.Q2 !== undefined) {
      dispatch(setQuestion2(currentUser?.quiz_step.Q2))
      setQ2Value(Number(currentUser?.quiz_step.Q2))
    }
  }, [currentUser])

  const handleCheckboxChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setQuestion2(event.target.value))
    setQ2Value(Number(event.target.value))
    await users.updateRegisteredUser(currentUser?.id ?? 0, { Q2: event.target.value, step_name: 'Step Three' }, true)
  }

  let content: string[] = []
  switch (section) {
    case 'E':
      content = ['Daring to use unorthodox approaches', 'Making reasonable decisions based on empiric measurements', 'Creating something original', 'Doing everything it takes to succeed']
      break
    case 'I':
      content = ['I‘m able to help others to get along', 'I can take risks and explore new things', 'I feel useful and can improve processes', 'I‘m asked to solve very complex problems']
      break
    case 'S':
      content = ['Be heard and noticed', 'Make sure all assignments are done perfectly', 'Spread good vibes around me', 'Be honorable and fair, and do what is expected']
      break
    case 'C':
      content = ['Vocally advocated against injustice', 'Provide instructions and oversee guidelines', 'Help  other people', 'Find original ways to do things better']
      break
    default:
      content = []
      break
  }

  return (
    <div className={className}>
      <FormControl component="fieldset" className='w-full md:w-[480px] lg:w-[520px]'>
        <RadioGroup
          aria-label="quiz"
          name="quiz-radio-group-2"
          onChange={handleCheckboxChange}
        >
          {content.map((label, index) => (
            <div className='border p-2 rounded-md m-1 w-auto' key={index}>
              <FormControlLabel
                key={label}
                value={(index + 1).toString()}
                control={<Radio />}
                label={label}
                checked={(index + 1) === q2Value}
                labelPlacement='start'
              />
            </div>
          ))}
        </RadioGroup>
      </FormControl>
    </div>
  )
}
