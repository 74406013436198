import Heading from '../../atoms/Heading/Heading'

interface Props {
  className: string
}

export default function QuizPageTwelveInfo2 ({ className }: Props) {
  return (
    <div className={className}>
      <Heading className='font-medium text-xl text-center mt-5' text="Thank you for taking the time" />
      <Heading className='page_twelve_text_invest font-medium text-xl text-center' text='To invest in YOU!' />
    </div>
  )
}
