import { createSlice, type PayloadAction } from '@reduxjs/toolkit'

export interface SocialMediaScore {
  count_video_reel_story?: number
  count_carousel?: number
  count_image?: number
  count_text?: number
  no_followers?: number
  platform?: string
  profile_type?: string
  user_id?: number
}

export interface UserSocialMediaState {
  facebook: boolean
  facebookpro: boolean
  instagram: boolean
  instagrampro: boolean
  linkedin: boolean
  socialMediaScores: SocialMediaScore [] | null
}

const initialState: UserSocialMediaState = {
  facebook: true,
  facebookpro: false,
  instagram: false,
  instagrampro: false,
  linkedin: false,
  socialMediaScores: null
}

export const userSocialMedia = createSlice({
  name: 'userSocialMedia',
  initialState,
  reducers: {
    setFacebook: (state, action: PayloadAction<boolean>) => {
      state.facebook = action.payload
    },
    setFacebookPro: (state, action: PayloadAction<boolean>) => {
      state.facebookpro = action.payload
    },
    setInstagram: (state, action: PayloadAction<boolean>) => {
      state.instagram = action.payload
    },
    setInstagramPro: (state, action: PayloadAction<boolean>) => {
      state.instagrampro = action.payload
    },
    setLinkedin: (state, action: PayloadAction<boolean>) => {
      state.linkedin = action.payload
    },
    setSocialMediaScores: (state, action: PayloadAction<SocialMediaScore[] | null>) => {
      state.socialMediaScores = action.payload
    }
  }
})

export const {
  setFacebook, setFacebookPro, setInstagram, setInstagramPro, setLinkedin, setSocialMediaScores
} = userSocialMedia.actions
export default userSocialMedia.reducer
