import { ReactComponent as BrandaThankYouPageImg } from '../../../img/branda-thank-you-page-image.svg'

interface Props {
  className: string
}

export default function QuizPageTwelveInfo1 ({ className }: Props) {
  return (
    <BrandaThankYouPageImg />
  )
}
