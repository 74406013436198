/* eslint-disable @typescript-eslint/no-misused-promises */

import { useAppDispatch } from '../../../app/hooks'
import { setSentEmail } from '../../../app/quiz/quizSlice'

interface Props {
  className: string
  handleShowPage: (page: string) => void
}

export default function BrandaResultForm ({ className, handleShowPage }: Props) {
  const dispatch = useAppDispatch()
  const handleClick = () => {
    handleShowPage('pageBrandaResult2')
    dispatch(setSentEmail(false))
  }
  return (
    <button onClick={() => { handleClick() }} className="p-4 w-44 h-14 bg-white rounded-lg text-base font-medium text-center text-black mt-5">
      Next
    </button>
  )
}
