import React from 'react'
import Heading from '../../atoms/Heading/Heading'
import { Link } from 'react-router-dom'
import Button from '../../atoms/Button/Button'

interface Props {
  className?: string
}

export default function StartYourPlan ({ className }: Props) {
  return (
    <div className='branda_score flex flex-col justify-between gap-1 mb-10 md:mb-24'>
      <Heading className='text-white font-manrope text-2xl md:text-4xl font-medium' text='Start posting' />
      <Heading className='text-gray-100 font-manrope text-lg font-normal mb-7' text="and improve your Brand Score" />
      <Link to="/all-weekly-goals">
        <Button
          text="Go to my weekly goals"
          className='bg-black w-42 flex p-3 justify-center items-center gap-3 rounded-lg text-white'
        />
      </Link>
    </div>
  )
}
