import FaqItemContent from 'components/organisms/FaqItemContent/FaqItemContent'
import SubscriptionPageInfo from '../../molecules/SubscriptionPageInfo/SubscriptionPageInfo'

export default function Subscription () {
  return (
    <div className="w-full justify-center relative items-center visible_1 basis-9/12">
      <SubscriptionPageInfo homeSubscription={true}/>
      <div className='mt-4'>
        <FaqItemContent filterData='Subscription'/>
      </div>
    </div>
  )
}
