/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { users } from '../../../app/services/Api'
import { setCurrentUser } from '../../../app/quiz/quizSlice'
import { useNavigate } from 'react-router-dom'
import DeleteWaitingListModal from './DeleteWaitingListModal'

interface Props {
  className: string
  handleShowPage: (page: string) => void
}

export default function BrandaResultPage2Form ({ className, handleShowPage }: Props) {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const email = useAppSelector(state => state.quizReducer.email)
  const password = useAppSelector(state => state.quizReducer.password)
  const branda = useAppSelector(state => state.quizReducer.Branda)
  const currentUser = useAppSelector(state => state.quizReducer.currentUser)
  const [isModalOpen, setIsModalOpen] = useState(false)

  const clearLocalStorage = () => {
    if (typeof localStorage !== 'undefined') {
      window.localStorage.clear()
    } else {
      console.log('localStorage is not available')
    }
  }

  useEffect(() => {
    handleCreateSubscriptionFromNotification()
  }, [currentUser])

  const handleCreateSubscriptionFromNotification = () => {
    const queryParams = new URLSearchParams(location.search)
    const createSubscriptionQueryString = queryParams.get('create-subscription')
    const userId = queryParams.get('user')

    if (createSubscriptionQueryString && userId) {
      clearLocalStorage()
      void users.updateRegisteredUser(parseInt(userId), { has_finished_steps: true }, true)
      navigate('/branda-score')
    }
  }

  const handleSignIn = async () => {
    if (!currentUser) {
      await users.signIn(email, password, true, branda).then((response) => {
        if (response.jwt) {
          dispatch(setCurrentUser(response.user))
          toast.success('You have been successfully registered.')
        } else {
          toast.error('Incorrect email or password!')
        }
      })
        .catch(err => {
          toast.error(err)
          console.log(err)
        })
    }
    toast.success('You have been successfully registered.')
    clearLocalStorage()
    await users.updateRegisteredUser(currentUser?.id ?? 0, { has_finished_steps: true }, true)
    navigate('/branda-score')
  }

  const handleRemindLater = async () => {
    if (!currentUser) {
      await users.signIn(email, password, true, branda).then((response) => {
        if (response.jwt) {
          dispatch(setCurrentUser(response.user))
        }
      })
        .catch(err => {
          toast.error(err)
          console.log(err)
        })
    }

    if (currentUser) {
      await users.remindMeLater(currentUser.email).then(() => {
        toast.success('You will be reminded soon.')
      }).finally(() => {
        clearLocalStorage()
        navigate('/dashboard?remind-later')
      })
    }
  }

  const openModal = () => {
    setIsModalOpen(true)
  }

  const closeModal = () => {
    setIsModalOpen(false)
  }

  return (
    <div className='flex flex-col justify-center items-center gap-3 mt-5'>
      <div className='flex flex-col md:flex-row gap-4 md:gap-6 mb-4'>
        <div className='flex flex-col'>
          <button onClick={handleSignIn} className='bg-white p-4 rounded-lg text-sm font-semibold w-48'>
            <span className='hover:text-purple-800 hover:text-base ease-in duration-150'>Start my free trial !</span>
          </button>
          <p className="text-xs text-white text-center mt-2 ">No credit card is needed!</p>
        </div>
        <div>
          <button onClick={handleRemindLater} className='bg-purple-900 p-4 rounded-lg text-sm text-white font-medium w-48  border border-gray-800'>
            <span className='hover:text-white hover:text-base ease-in duration-150'>Remind me later</span>
          </button>
        </div>
      </div>
      <div className="flex mb-5 mt-0">
        <button onClick={openModal} className='text-white text-xs underline md:text-sm ml-2'>Nope! I don’t want more clients</button><span className='ml-2'>&#128542;</span>
      </div>
      <DeleteWaitingListModal handleShowPage={handleShowPage} isOpen={isModalOpen} onClose={closeModal} onConfirm={openModal} />
    </div>
  )
}
