/*eslint-disable*/
import { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { users } from 'app/services/Api'
import { TextField } from '@mui/material'
import Heading from 'components/atoms/Heading/Heading'
import { setCurrentUser } from 'app/quiz/quizSlice'

export interface Socials {
  name: string
  image: string
  altText: string
  question: string
}

interface PopupProps {
  selectedNetwork: Socials | null
  closePopup: () => void
  onSubmit: (network: Socials, count: number) => void
  mode: 'add' | 'edit'
}

const Popup: React.FC<PopupProps> = ({ selectedNetwork, closePopup, onSubmit, mode }: any) => {
  const [count, setCount] = useState<string>('')
  const [isDisabled, setIsDisabled] = useState(true)
  const currentUser = useAppSelector(state => state.quizReducer.currentUser)
  const popupHeading = mode === 'add' ? `Let us know your influence on ${selectedNetwork?.name || ''}` : 'Edit your influence'
  const dispatch = useAppDispatch()

  useEffect(() => {
    setIsDisabled(count === '')
  }, [count])

  useEffect(() => {
    // Set the initial value when the popup is opened for editing
    if (selectedNetwork) {
      setCount(selectedNetwork.no_followers ?? '0')
    }
  }, [selectedNetwork])

  const handleSubmit = async () => {
    if (selectedNetwork && currentUser !== null) {
      try {
        const { name } = selectedNetwork

        const currentUserNetworkObject = {
          no_followers: Number(count),
          platform: name,
          user_id: currentUser.id
        }

        await users.updateUser(currentUser.id, currentUserNetworkObject).then((response) => {
          dispatch(setCurrentUser(response))
        }).catch((err) => {
          console.log('error')
        })

        onSubmit(selectedNetwork, count)
        closePopup()
      } catch (error) {
        console.log(error)
      }
    }
    const getUser = async () => {
      await users.getCurrentUser(true).then(response => {
        if (!response.response) {
          dispatch(setCurrentUser(response))
        } else {
          dispatch(setCurrentUser(null))
        }
      })
        .catch(err => {
          console.log(err)
        })
    }
    void getUser()
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const input = e.target.value
    setCount(input)

    // Validate if the input is a non-negative number
    const isValidInput = /^[+]?\d+([.]\d+)?$/.test(input)
    setIsDisabled(!isValidInput)
  }

  return (
    <div className="fixed inset-0 flex flex-col items-center justify-center z-10 bg-black bg-opacity-40">
      <div className="sign_in_sign_up p-5 gap-2 !w-10/12 md:!w-2/3 lg:!w-5/12 rounded-2xl flex flex-col">
        <div className="flex justify-end p-0">
          <button className="text-sm md:text-xl font-semibold text-gray-400 w-4" onClick={closePopup}>
            x
          </button>
        </div>
        <div className='flex justify-start items-start'>
          <Heading className="text-xl text-purple-700 font-medium font-manrope" text={popupHeading} />
        </div>
        <div className="flex flex-col justify-between">
          {selectedNetwork && (
            <>
              <div className="flex justify-between items-center gap-2">
                <span className="text-sm md:text-base text-gray-400">{selectedNetwork.question}</span>
                <TextField
                  type="number"
                  className="flex justify-center w-5/12 md:w-2/12 h-12"
                  variant="outlined"
                  inputProps={{ className: 'text-center !h-2' }}
                  label=""
                  value={count}
                  onChange={handleInputChange}
                />
              </div>
              <div />
              <div className='flex justify-center'>
                <button disabled={isDisabled} type="button" className="quiz_form_button capitalize text-white font-medium !rounded-md text-lg text-center md:mt-5 !h-10 !w-24 md:!h-12 md:!w-32" onClick={handleSubmit}>
                  <span>{mode === 'add' ? 'Save' : 'Update'}</span>
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default Popup
