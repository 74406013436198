/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import React, { useState } from 'react'

interface Props {
  isOpen: boolean
  onClose: () => void
  onConfirm: (reason: string) => void
}

export default function CancellationReasonsModal ({
  isOpen,
  onClose,
  onConfirm
}: Props) {
  const [selectedReason, setSelectedReason] = useState('')
  const [otherReason, setOtherReason] = useState('')

  const handleConfirmCancellation = () => {
    onConfirm(selectedReason)
    onClose()
  }

  const handleConfirm = () => {
    if (selectedReason) {
      onConfirm(selectedReason)
    }
  }

  return (
    <div
      className={`fixed z-40 top-0 left-0 w-full h-full flex items-center justify-center ${isOpen ? '' : 'hidden'
        }`}
    >
      <div className="absolute w-full h-full bg-gray-800 opacity-50"></div>
      <div className="bg-white mx-4 md:mx-0 z-50 p-4 md:p-6 shadow-md border-2 border-gray-700 rounded-md">
        <div className="flex items-center justify-between w-full mb-4">
          <span className="text-xl" role="img" aria-label="Bell icon">
            🔔
          </span>
          <button
            onClick={onClose}
            type="button"
            className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 inline-flex justify-center items-center"
            data-modal-hide="static-modal"
          >
            <svg
              className="w-3 h-3"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 14 14"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
              />
            </svg>
            <span className="sr-only">Close modal</span>
          </button>
        </div>
        <div className="items-start">
          <p className="font-medium text-base md:text-xl text-black  md:p-2  mb-4">
            Choose cancellation reasons:
          </p>
        </div>
        <div className="flex flex-col mb-4">
          <label className="inline-flex items-center mb-2 text-sm md:text-base">
            <input
              type="radio"
              className="form-radio h-4 w-4 md:h-5 md:w-5 text-gray-600"
              value="The product didn’t help me grow"
              checked={selectedReason === 'The product didn’t help me grow'}
              onChange={() => { setSelectedReason('The product didn’t help me grow') }
              }
            />
            <span className="ml-2">The product didn’t help me grow</span>
          </label>
          <label className="inline-flex items-center mb-2 text-sm md:text-base">
            <input
              type="radio"
              className="form-radio h-4 w-4 md:h-5 md:w-5 text-gray-600"
              value="Too expensive"
              checked={selectedReason === 'Too expensive'}
              onChange={() => { setSelectedReason('Too expensive') }}
            />
            <span className="ml-2">Too expensive</span>
          </label>
          <label className="inline-flex items-center mb-2 text-sm md:text-base">
            <input
              type="radio"
              className="form-radio h-4 w-4 md:h-5 md:w-5 text-gray-600"
              value="Don’t have the time to use"
              checked={selectedReason === 'Don’t have the time to use'}
              onChange={() => { setSelectedReason('Don’t have the time to use') }}
            />
            <span className="ml-2">Don’t have the time to use</span>
          </label>
          <label className="inline-flex items-center mb-2 text-sm md:text-base">
            <input
              type="radio"
              className="form-radio h-4 w-4 md:h-5 md:w-5 text-gray-600"
              value="Other"
              checked={selectedReason === 'Other'}
              onChange={() => { setSelectedReason('Other') }}
            />
            <span className="ml-2">Other</span>
          </label>
          {selectedReason === 'Other' && (
            <input
              type="text"
              value={otherReason}
              onChange={(e) => { setOtherReason(e.target.value) }}
              placeholder="Enter other reason"
              className="border border-gray-300 rounded px-3 py-1 mt-2"
            />
          )}
        </div>
        <div className="flex justify-center md:justify-end mb-4 ">
          <button
            className="mr-2 px-2 md:px-4 py-2 bg-green-600 text-xs md:text-base text-white rounded"
            onClick={handleConfirm}
          >
            Don’t cancel
          </button>
          <button
            className="px-2 md:px-4 py-2 bg-red-500 text-xs md:text-base text-white rounded"
            onClick={handleConfirmCancellation}
          >
            Cancel my plan
          </button>
        </div>
      </div>
    </div>
  )
}
