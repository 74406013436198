import React from 'react'
import AllWeeklyGoalsContent from 'components/organisms/AllWeeklyGoalsContent/AllWeeklyGoalsContent'
import FaqItemContent from 'components/organisms/FaqItemContent/FaqItemContent'

export default function AllWeeklyGoals () {
  return (
    <div className='flex md:basis-9/12'>
      <div className='flex flex-col gap-3 justify-center w-full p-5 md:p-16 mb-24 bg-white rounded-3xl'>
        <AllWeeklyGoalsContent />
      </div>
      <div className='mt-2'>
        <FaqItemContent filterData='Weekly Goals' />
      </div>
    </div>
  )
}
