import ProfileForm from '../../molecules/ProfileForm/ProfileForm'
import ProfileConnect from '../../molecules/ProfileConnect/ProfileConnect'
import ProfileLogOut from '../../molecules/ProfileLogOut/ProfileLogOut'
import { useAppSelector } from '../../../app/hooks'

export default function Profile () {
  const profileSettingPage = useAppSelector(state => state.quizReducer.profileSettingPage)

  return (
    <div className='flex flex-col justify-center w-11/12 md:w-full gap-8 mx-4 md:mx-0 md:basis-9/12 lg:basis-7/12 xl:basis-8/12 mb-24 rounded-3xl'>
      <div className='p-5 sm:p-10 bg-white lg:p-16 rounded-3xl'>
        <ProfileForm />
      </div>
      {profileSettingPage === 'init-profile' && (
        <>
          <div className='p-5 sm:p-10 md:p-15 bg-gray-200 rounded-3xl'>
            <ProfileConnect />
          </div>
          <div className='flex flex-col justify-center items-center md:justify-start md:items-start w-full gap-3 p-4 rounded-3xl bg-white cursor-pointer text-red-600'>
            <ProfileLogOut />
          </div>
        </>
      )}
    </div>
  )
}
