interface Props {
  className: string
  text: string
  number: number | null
  isActive: boolean
  id: number
  handleCardClick: (id: number) => void
}

export default function Card ({ className, text, number, isActive, id, handleCardClick }: Props) {
  return (
    <div onClick={() => { handleCardClick(id) }} className={`${isActive ? className + ' active' : className}`}>
      {isActive && number !== 0 && <div className="number">{number}</div>}
      <p>{text}</p>
    </div>
  )
}
